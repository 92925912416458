import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getCultures({commit}, payload) {
        return api.fetch(endpoints.CULTURES, payload, commit,(response) => {
          commit(types.SET_CULTURES, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getCultureGroups({commit}, payload) {
        return api.fetch(endpoints.CULTURE_GROUPS, payload, commit,(response) => {
            commit(types.SET_CULTURE_GROUPS, response.data);
        });
    },
    getCulture({commit}, payload) {
      return api.fetch(endpoints.CULTURES+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_CULTURE, response.data);
      });
    },
    createCulture({commit}, payload) {
      return api.post(endpoints.CULTURES, payload,  commit,(response) => {
        commit(types.SET_CULTURE, response.data);
      });
    },
    updateCulture({commit}, payload) {
      return api.put(endpoints.CULTURES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_CULTURE, response.data);
      })
    },
    deleteCulture({commit}, payload) {
      return api.remove(endpoints.CULTURES+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_CULTURE, response.data);
      })
    },

    //varieties
    getCultureVarieties({commit}, payload) {
      return api.fetch(endpoints.CULTURE_VARIETIES, payload, commit,(response) => {
        commit(types.SET_VARIETIES, response.data.data);
        commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    },

    getCultureVariety({commit}, payload) {
      return api.fetch(endpoints.CULTURES+'/'+payload.uuid+'/varieties/'+payload.vuuid, payload, commit,(response) => {
        commit(types.SET_VARIETY, response.data);
      });
    },
    createCultureVariety({commit}, payload) {
      return api.post(endpoints.CULTURE_VARIETIES, payload,  commit,(response) => {
        commit(types.SET_VARIETY, response.data);
      });
    },
    updateCultureVariety({commit}, payload) {
      return api.put(endpoints.CULTURE_VARIETIES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_VARIETY, response.data);
      })
    },
    deleteCultureVariety({commit}, payload) {
      return api.remove(endpoints.CULTURE_VARIETIES+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_VARIETY, response.data);
      })
    },

    //bbch
    getBbchList({commit}, payload) {
        return api.fetch(endpoints.BBCHS, payload, commit,(response) => {
            commit(types.SET_BBCHS, response.data.data);
            commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getBbch({commit}, payload) {
        return api.fetch(endpoints.BBCHS+'/'+payload.uuid, payload, commit,(response) => {
            commit(types.SET_BBCH, response.data);
        });
    },
    createBbch({commit}, payload) {
        return api.post(endpoints.BBCHS, payload,  commit,(response) => {
            commit(types.SET_BBCH, response.data);
        });
    },
    updateBbch({commit}, payload) {
        return api.put(endpoints.BBCHS+'/'+payload.uuid, payload, commit, (response) => {
            commit(types.SET_BBCH, response.data);
        })
    },
    deleteBbch({commit}, payload) {
        return api.remove(endpoints.BBCHS+'/'+payload.uuid, payload,  commit,(response) => {
            commit(types.SET_BBCH, response.data);
        })
    },
}

export default actions;

import * as api from '@/store/api/index'
import * as endpoints from '@/store/api/endpoints'
import * as types from "@/store/mutation-types";

const actions = {
  getSettingGroups({commit}, payload) {
    return api.fetch(endpoints.SETTING_GROUPS, payload, commit,(response) => {
      commit(types.SET_SETTING_GROUPS, response.data);
    });
  },
  getGroupSettings({commit}, payload) {
    return api.fetch(endpoints.replaceParams(endpoints.GROUP_SETTINGS, payload)
      , payload, commit,(response) => {
      commit(types.SET_GROUP_SETTINGS, response.data);
    });
  },
  getGroupByCode({commit}, payload) {
    return api.fetch(endpoints.replaceParams(endpoints.GROUP_BY_CODE, payload)
      , payload, commit,(response) => {
        commit(types.SET_GROUP_SETTINGS, response.data);
      });
  },
  getGroupSetting({commit}, payload) {
    return api.fetch(endpoints.GROUP_SETTING+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_GROUP_SETTING, response.data);
    });
  },
  updateGroupSettings({commit}, payload) {
    return api.put(endpoints.replaceParams(endpoints.GROUP_SETTINGS, payload)
      , payload, commit, (response) => {
      commit(types.SET_GROUP_SETTINGS, response.data);
    });
  },
  getSettingValuesByEndpoint({commit}, payload) {
    return api.fetch(payload.endpoint , payload, commit,(response) => {
      commit(types.SET_CUSTOM_SETTING_VALUES, response.data.data);
    });
  },
  getSettingByCode({commit}, payload) {
    return api.fetch(endpoints.replaceParams(endpoints.SETTING_BY_CODE, payload)
      , payload, commit,(response) => {
    });
  },
  getSettingsForGeneralUsage({commit}, payload) {
    const item_codes = [
      'STOCK_QUANTITY_DECIMALS',
      'FRE_GENERAL_DATE_FORMAT'
    ];

    return api.fetch(endpoints.SETTINGS_FOR_GENERAL_USAGE, {
      item_codes: item_codes
    }, commit,(response) => {
        commit(types.SET_GENERAL_SETTING_VALUES, response.data);
      });
  },
}

export default actions;

<template>
  <div class="agx-grid-work-machines">
    <div class="top_machine_border"></div>
    <div v-for="(culture, index) in registeredCultures" :key="culture.uuid">
      <div
          class="grid_custom_row grid_custom_row_machine"
          :class="[
          { selected_row: culture.selected },
          {bottom_radius: last_work && culture.expanded == false && registeredCultures.length == (index + 1)}
        ]"
      >
        <div class="left_side">
          <b-form-checkbox
              class="select_row_checkbox"
              size="sm"
              v-if="start_mode == true"
              v-model="culture.selected"
              @change="machine_selected_event($event, index, culture)"
          >
          </b-form-checkbox>
          <div
              class="machine_name"
              :id="'ms_' + culture.uuid"
              @click="machineNameClick"
          >
            {{
              templateData.is_dataller_work ? 'Zilieri' : (culture.name ?? 'Necunoscut')
            }}
          </div>
          <div class="machine_name_spacer"></div>
        </div>

        <div class="right_side">
          <div class="total_surface">{{ culture.total_surface_worked }} ha</div>
          <div class="parcel_count">
            <img src="@/assets/images/icons/parcel2.svg"/>
            <span>{{ culture.parcels_count }}</span>
          </div>
          <div>
            <img
                class="expand_button"
                v-if="culture.expanded"
                src="@/assets/images/icons/machine_expanded.svg"
                @click="expandGrid(index, culture)"
            />
            <img
                class="expand_button_down"
                v-else
                src="@/assets/images/icons/arrow_down.svg"
                @click="expandGrid(index, culture)"
            />
          </div>
        </div>
      </div>
      <div v-show="culture.expanded">
        <works-on-parcels
            :ref="'parcels_' + culture.uuid"
            :template-data="{ data: culture }"
            :work_uuid="templateData.work_uuid"
            :date_from="templateData.date"
            :date_to="templateData.date"
            :department_uuid="department_uuid"
            :start_mode="start_mode"
            :parent_expanded="culture.expanded"
            :parent_selected="culture.selected"
            @selectParcel="selectParcel"
            :last_machine="last_work && registeredCultures.length == (index + 1)"
            :workListFilters="workListFilters"
            @showMapForParcel="showMapForParcel"
            :usage_mode="usage_mode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import WorksOnParcels from "./worksOnParcels";

export default {
  name: "WorkWithMachines",
  props: {
    templateData: {
      type: Object,
      default: () => {
      },
    },
    workListFilters: {
      type: Object,
      default: () => {
      },
    },
    department_uuid: "",
    usage_mode: "",
    fill_grid: false,
    start_mode: false,
    parent_expanded: false,
    parent_selected: false,
    last_work: false,

  },
  data() {
    return {
      registeredCultures: [],
      tooltip: {
        visible: false,
        target: "",
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      spcMachines: "seasonOnParcelHasCulture/spcMachines",
      departments: "company/departments",
    }),
  },
  watch: {
    registeredCultures: {
      handler: function (newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    parent_expanded(newVal, oldValue) {
      if (newVal == true && this.registeredCultures.length <= 0) {
        this.getCultures();
      }
    },
  },
  components: {
    WorksOnParcels,
  },
  methods: {
    ...mapActions({
      getCultureGroups: "works-cultures/getCultureGroups",
    }),
    showMapForParcel(parcel_uuid, season_uuid) {
      this.$emit("showMapForParcel", parcel_uuid, season_uuid);
    },
    machineNameClick(target) {
      // this.tooltip.text = "some test";
      // this.tooltip.target = "#mns_" + target;
      //this.tooltip.visible = false;
      //this.$refs.machine_tooltip.show();
    },
    selectParcel(select, parcel, allParcelAreUnchecked) {
      this.$emit("selectParcel", select, parcel);
      this.selectMachineByParcel(select, parcel, allParcelAreUnchecked);
    },
    selectMachineByParcel(select, parcel, allParcelAreUnchecked) {
      if (select) {
        this.registeredCultures.forEach((m, index) => {
          if (m.uuid == parcel.machine_uuid) {
            m.selected = true;
            this.$set(this.registeredCultures, index, m);
          }
        });
      } else {
        if (allParcelAreUnchecked) {
          this.registeredCultures.forEach((m, index) => {
            if (m.uuid == parcel.machine_uuid) {
              m.selected = false;
              this.$set(this.registeredCultures, index, m);
            }
          });
        }
      }
      this.$emit("selectWorkByMachine", select, this.templateData);
    },
    machine_selected_event(selected, index, machine) {
      let checkObj = this.registeredCultures.find(
          (x) => x.uuid == machine.uuid
      );
      checkObj.selected = selected;
      this.$set(this.registeredCultures, index, checkObj);
      this.$emit(
          "selectMachine",
          selected,
          machine.uuid,
          this.department_uuid,
          this.templateData
      );

      if (this.$refs["parcels_" + machine.uuid]) {
        if (selected) {
          this.$refs["parcels_" + machine.uuid][0].selectAllParcels();
        } else {
          this.$refs["parcels_" + machine.uuid][0].deselectAllParcels();
        }
      }
    },
    selectAllMachines() {
      this.registeredCultures.forEach((m, index) => {
        m.selected = true;
        this.$set(this.registeredCultures, index, m);
        if (this.$refs["parcels_" + m.uuid]) {
          this.$refs["parcels_" + m.uuid][0].selectAllParcels();
        }
      });
    },
    deselectAllMachines() {
      this.registeredCultures.forEach((m, index) => {
        m.selected = false;
        this.$set(this.registeredCultures, index, m);
        if (this.$refs["parcels_" + m.uuid]) {
          this.$refs["parcels_" + m.uuid][0].deselectAllParcels();
        }
      });
    },
    expandGrid(index, culture) {
      let checkObj = this.registeredCultures.find(
          (x) => x.uuid == culture.uuid
      );
      checkObj.expanded = !checkObj.expanded;
      this.$set(this.registeredCultures, index, checkObj);
    },
    getCultures() {
      let localDepartmentFilter = []
      if (this.workListFilters && this.workListFilters.departments) {
        this.workListFilters.departments.forEach(d => {
          if (this.departments.find(dep => dep.uuid == d)) {
            localDepartmentFilter.push(d);
          }
        });
      }

      this.getCultureGroups({
        department_uuid: localDepartmentFilter.join(),
        date_from: this.templateData.date,
        date_to: this.templateData.date,
        work_uuid: this.templateData.work_uuid,
        parcel_uuid: this.workListFilters && this.workListFilters.parcels ? this.workListFilters.parcels.join() : null,
        product_uuid: this.workListFilters && this.workListFilters.products ? this.workListFilters.products.join() : null,
        season_uuid: this.workListFilters && this.workListFilters.seasons ? this.workListFilters.seasons.join() : null,
      }).then((payload) => {
        payload.forEach((culture) => {
          culture.selected = this.parent_selected;
          culture.expanded = false;
          this.registeredCultures.push(culture);
        });
      });
    },
  },

  mounted() {
    if (this.parent_expanded && this.registeredCultures.length == 0) {
      this.getCultures();
    }
  },
};
</script>

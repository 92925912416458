<template>
  <a v-on:click.prevent="onClick">
    <img src="~@/assets/images/desktop/icons/controls/pdf.svg" class="icon-svg danger-filter" />
    <div class="pl-3 fs10"> {{ header.cancelDocument ? "Anulare " + data.type_name : data.type_name }} <br />
      {{ header.cancelDocument ?  data.cancel_document?.document_header?.current_model : data.document.document_header.current_model }}
    </div>
  </a>
</template>

<script>
import { errorMessage } from "~/helpers/common";

export default {
  props: ['data', 'header'], //transaction
  name: 'PdfDownloadCol',
  computed: {
    name() {
      return this.data.type_name;
    },
  },
  methods: {
    async onClick() {
      try {
        await this.downloadPdf('application/json');
        await this.downloadPdf('blob');
      } catch (e) {
        this.handleError(e);
      }
    },
    async downloadPdf(responseType) {
      try {
        await this.$store.dispatch('smTransactions/downloadPdf', {
          type: this.header.cancelDocument ? this.data.cancel_document_uuid : this.data.document_uuid,
          url: this.header.cancelDocument ? this.data.cancel_download_url : this.data.download_url,
          filename: this.header.cancelDocument ? this.data.cancel_download_filename : this.data.download_filename,
          response_type: responseType,
        });
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(e) {
      console.log('Download error: ', e.response);
      if (e.response && (e.response.status === 400 || e.response.status === 500)) {
        const error = e.response.data.errors && e.response.data.errors.error
          ? e.response.data.errors.error
          : this.$t('error_alert_text');
        errorMessage(this, error);
      }
    }
  },
}
</script>

<template>
  <div class="work-group-details">
    <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-8 text-left">
          <label class="control-label">{{ $t("salary_calculator.work_group.name") }}</label>
          <input type="text" v-model="data.name" :placeholder='$t("common.name")'
                 :class="{ 'field-error' : this.$v.data.name.$error }"
                 class="form-control"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-2">
          <label class="control-label">{{$t("common.measure_unit")}}</label>
          <b-form-select
            v-model="data.measure_unit_uuid"
            class="form-control"
            :class="{ 'field-error' : this.$v.data.measure_unit_uuid.$error }"
            :options="measure_unit_list"></b-form-select>
        </div>
        <div class="form-group col-sm-12 col-lg-2 text-left">
          <label class="control-label">{{ $t('common.price') }} ({{ $currency.name | lowerCase }})</label>
          <input type="text" v-model="data.price" :placeholder='$t("common.price")'
                 :class="{ 'field-error' : this.$v.data.price.$error }"
                 v-on:keydown="onlyPrice"
                 class="form-control"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.shift.name') }}</label>
          <b-form-select
            v-model="data.shift_uuid"
            class="form-control"
            :options="shift_list"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <b-form-checkbox switch size="lg"
                           v-model="is_active_hours"
                           class="mr-3 cbc">
            <span v-if="is_active_hours">{{ $t('salary_calculator.work_group.is_active_hours') }}</span>
            <span v-if="!is_active_hours">{{ $t('salary_calculator.work_group.is_inactive_hours') }}</span>
          </b-form-checkbox>
        </div>
        <div class="form-group col-lg-4">
          <b-form-checkbox switch size="lg"
                           v-model="is_non_productive_hours"
                           class="mr-3 cbc">
            <span v-if="is_non_productive_hours">{{ $t('salary_calculator.work_group.is_non_productive_hours') }}</span>
            <span v-if="!is_non_productive_hours">{{ $t('salary_calculator.work_group.is_non_productive_hours') }}</span>
          </b-form-checkbox>
        </div>
        <div class="form-group col-lg-4">
          <b-form-checkbox switch size="lg"
                           v-model="is_clocking_hours"
                           class="mr-3 cbc">
            <span>{{ $t('salary_calculator.work_group.is_clocking_hours') }}</span>
          </b-form-checkbox>
        </div>
      </div>
      <agro-action-buttons
        :data="data"
        :can-edit="canEdit"
        :can-delete="canDelete"
        @onSave="save"
        @onSaveAndNew="saveAndNew"
        :save-and-new="true"
        @onDelete="confirmDelete"
        @onGotoList="gotoList"
      ></agro-action-buttons>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {buildDefaultDropdownList, only_price} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'SCWorkGroupDetails',
  data() {
    return {
        data: {
          backPath: undefined,
        },
      is_active_hours: true,
      is_clocking_hours: true,
      is_non_productive_hours: false,
    }
  },
  components: {
    AgroActionButtons,
    Forbidden
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      measureUnits: 'scMeasureUnit/scMeasureUnits',
      shifts: 'scShift/scShifts'
    }),
    name() {
      return this.$t('salary_calculator.work_group.title')
    },
    config() {
      return {
        permission: permissions.SC_EMPLOYEE,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-work-group',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    measure_unit_list() {
      return buildDefaultDropdownList(this.measureUnits);
    },
    shift_list() {
      return buildDefaultDropdownList(this.shifts, {
        value: 'uuid',
        text: 'full_name',
      });
    },
  },
  methods: {
    ...mapActions({
      getMeasureUnits: 'scMeasureUnit/getSCMeasureUnits',
      get: 'scWorkGroup/getSCWorkGroup',
      create: 'scWorkGroup/createSCWorkGroup',
      update: 'scWorkGroup/updateSCWorkGroup',
      delete: 'scWorkGroup/deleteSCWorkGroup',
      getShifts: 'scShift/getSCShifts'
    }),
    onlyPrice(event) {
      return only_price(event);
    },
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'shift_uuid', null);
        vm.$set(vm.data, 'measure_unit_uuid', null);
        vm.$set(vm, 'is_active_hours', data.is_active_hours === 1);
        vm.$set(vm, 'is_clocking_hours', data.is_clocking_hours === 1);
        vm.$set(vm, 'is_non_productive_hours', data.is_non_productive_hours === 1);
      }, (data) => {
        vm.$set(vm, 'is_active_hours', data.is_active_hours === 1);
        vm.$set(vm, 'is_clocking_hours', data.is_clocking_hours === 1);
        vm.$set(vm, 'is_non_productive_hours', data.is_non_productive_hours === 1);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    }
  },
  mounted() {
    this.getMeasureUnits();
    this.getShifts();
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    is_active_hours: function(newVal) {
      this.$set(this.data, 'is_active_hours', Boolean(newVal) ? 1 : 0);
    },
    is_clocking_hours: function(newVal) {
      this.$set(this.data, 'is_clocking_hours', Boolean(newVal) ? 1 : 0);
    },
    is_non_productive_hours: function(newVal) {
      this.$set(this.data, 'is_non_productive_hours', Boolean(newVal) ? 1 : 0);
    },
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
        measure_unit_uuid: {
          required,
        },
        price: {
          required,
        },
      }
    }
  }
}
</script>

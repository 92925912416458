<template>
  <td :class="[td.class, tdAlign, rowClass ]" :width="td.with ? td.width : 'auto'">
    <div id="#render"></div>
    <div  v-if="!header.colTemplate" v-html="td"></div>
    <div v-else>
      <component :is="component" v-bind="{data: cell, header: header}" v-if="component"></component>
    </div>
  </td>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";

export default {
  name: 'Row',
  props: ['data', 'headers', 'align', 'rowClass', 'header', 'cell'],
  data() {
    return {
      td: this.data ? this.data : [],
      templateRender: null,
      child_component: null,
    }
  },
  computed: {
    ...mapGetters({
      getDefaultDateFormat: 'settings/getDefaultDateFormat',
    }),
    tdAlign() {
      if(this.align) {
        switch(this.align) {
          case 'center':
            return 'text-center';
          case 'left':
            return 'text-left'
            case 'right':
              return 'text-right'
          default:
            return 'text-left';
        }
      }
    },
    component() {
      return this.header.colTemplate(this.cell) ?? null;
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        if(!this.header.colTemplate && (this.header?.colType == 'date' || this.header?.colType == 'dateTime')) {
            const date = moment(newVal);
            if (date.isValid()) {
              this.td = date.format(this.getDefaultDateFormat + (this.header?.colType == 'dateTime' ? ' HH:mm' : ''));
            } else {
              this.td = newVal;
            }
        } else {
          this.td = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

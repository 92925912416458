const state = () => {
  return {
    culture: {},
    cultures: [],
    culture_groups: [],
    varieties: [],
    variety: {},
    culture_filters: {},
    bbchs: [],
    bbch: {},
  }
}

export default state;

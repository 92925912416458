<template>
  <div>
    <div v-if="data.canceled_at" class="cancel-text">
      <div>{{ content('col') }}</div>
      <div>{{ content('secondRowCol') }}</div>
      <div>{{ content('thirdRowCol') }}</div>
    </div>
    <div v-else>
      <div>{{ content('col') }}</div>
      <div>{{ content('secondRowCol') }}</div>
      <div>{{ content('thirdRowCol') }}</div>
    </div>
    <div v-html="subtitle"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from "moment";

export default {
  components: {},
  props: ["data", "header"],
  name: "CanceledRow",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getDefaultDateFormat: 'settings/getDefaultDateFormat',
    }),
    subtitle() {
      let link = "";

      if (this.header.subtitle && this.header.subtitle instanceof Function) {
        let subtitleData = this.header.subtitle(this.data);

        if (subtitleData instanceof Object) {
          link =
            '<a href="' + subtitleData.link + '">' + subtitleData.title + "</a>";
        } else {
          link = subtitleData;
        }
      }

      return link;
    },
  },
  methods: {
    content(colName) {
      if (this.header[colName] && this.header[colName]) {
        const getNestedProperty = (obj, path) => {
          return path.split(".").reduce((acc, part) => acc && acc[part], obj);
        };

        let value = getNestedProperty(this.data, this.header[colName]);

        if(this.header?.colType == 'date' || this.header?.colType == 'dateTime') {
            const date = moment(value);
            if (date.isValid()) {
              value = date.format(this.getDefaultDateFormat + (this.header?.colType == 'dateTime' ? ' HH:mm' : ''));
            } else {
              value = newVal;
            }

            return value;
        }

        if (this.header.suffix) {
          return `${value} ${this.header.suffix}`;
        }

        if (value) {
          return value;
        }
      }

      return "";
    },
  },
};
</script>

<style scoped>
.cancel-text {
  color: #ff0015;
  border-radius: 5px;
}
</style>

import $ from 'jquery'
import store from '@/store';

function getFormattedQuantity(value, number_of_decimals = null) {
  if (number_of_decimals === null) {
    number_of_decimals = store.getters['settings/getQuantityDecimalPlaces'] ?? 4;
  }
  return parseFloat(parseFloat(value).toFixed(number_of_decimals));
}

function getLocalFormattedQuantity(value, number_of_decimals = null) {
  if (number_of_decimals === null) {
    number_of_decimals = store.getters['settings/getQuantityDecimalPlaces'] ?? 4;
  }

  return getFormattedQuantity(value, number_of_decimals).toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: number_of_decimals,
  });
}

function getQuantityFormatter(number_of_decimals = null) {
  if (number_of_decimals === null) {
    number_of_decimals = store.getters['settings/getQuantityDecimalPlaces'] ?? 4;
  }

  return `#0.${'#'.repeat(number_of_decimals)}`; // `#0.${'0'.repeat(number_of_decimals)}`
}


export {
  getFormattedQuantity,
  getLocalFormattedQuantity,
  getQuantityFormatter
};

<template>
  <div>
    <card title="Work list"></card>
    <div class="content-body pt-4">
      <div class="row">
        <div id="toast-msg"></div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <DxDataGrid
              :ref="workGridRefKey"
              :data-source="workDatasource"
              :show-borders="false"
              :remote-operations="gridRemoteOperations"
              :element-attr="gridAttributes"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :column-min-width="120"
              :on-row-prepared="rowMarker"
              :on-editor-preparing="onEditorPreparing"
              :on-editing-start="handleRowEdit"
              @rowRemoving="onRowRemoving"
          >
            <DxColumn
                data-field="work_started_at"
                data-type="date"
                caption="Data"
                format="yyyy-MM-dd"
            >
            </DxColumn>
            <DxColumn
                data-field="work.uuid"
                data-type="string"
                caption="Lucrare"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="workTypes" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="work_machine.uuid"
                data-type="string"
                caption="Tractor"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="workMachineList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="equipment.uuid"
                data-type="string"
                caption="Utilaj"
                :editor-options="columnEditorOptions"
            >
              <DxLookup :data-source="equipmentList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="season.uuid"
                data-type="string"
                caption="Season"
                :editor-options="columnEditorOptions"
                :set-cell-value="setParcelValue"
            >
              <DxLookup :data-source="seasonsList" value-expr="uuid" display-expr="name">
              </DxLookup>
            </DxColumn>
            <template #dropDownBoxEditor="{ data: cellInfo }">
              <ParcelComboBoxComponent
                :value="cellInfo.value"
                :on-value-changed="cellInfo.setValue"
              />
            </template>
            <DxColumn
                data-field="parcel.uuid"
                data-type="string"
                caption="Parcelă"
                :editor-options="columnEditorOptions"
                :set-cell-value="setSurfaceValue"
            >
              <DxLookup :data-source="getParcelList" value-expr="parcel_uuid" display-expr="parcel_name">
              </DxLookup>
            </DxColumn>
            <DxColumn
                data-field="parcel.surface"
                data-type="number"
                caption="Suprafață"
                :editor-options="columnEditorOptions"
                format="#,##0.## ha"
                :allow-editing="false"
            >
            </DxColumn>
            <DxColumn
                data-field="net_surface_worked"
                data-type="number"
                caption="Suprafață prelucrată"
                :editor-options="columnEditorOptions"
                format="#,##0.## ha"
            >
            </DxColumn>
            <DxColumn
                data-field="notes"
                data-type="string"
                caption="Comentarii"
                :editor-options="columnEditorOptions"
            >
            </DxColumn>
            <DxColumn
              type="buttons"
              :fixed="true"
              fixed-position="right"
            >
              <DxButton name="edit" />
              <DxButton name="delete" />
            </DxColumn>
            <DxPaging :total="works.lenght" :page-size="25"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="false"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                :allow-adding="false"
                :allow-updating="true"
                :allow-deleting="true"
                :use-icons="true"
                mode="popup"
            >
              <DxTexts
                  confirm-delete-message="Sigur vreți să ștergeți lucrarea?",
                  confirm-delete-title="Confirmare ștergere"
              />
              <DxPopup :wrapper-attr="popupAttributes"
              :show-title="true"
              :width="840"
              :height="425"
              title="Work details"
              ></DxPopup>
            </DxEditing>
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true" apply-filter="auto"/>
            <DxSelection mode="single" />
            <DxScrolling column-rendering-mode="virtual"/>
          </DxDataGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxLookup,
  DxPager,
  DxPaging,
  DxRequiredRule,
  DxSelection,
  DxStringLengthRule,
  DxTexts,
  DxScrolling,
  DxPopup,
  DxButton
} from 'devextreme-vue/data-grid';
import {canEdit} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import {
  ROW_BACKGROUND_COLOR
} from "@/helpers/dxGridHelper";
import ParcelComboBoxComponent from './ParcelComboBoxComponent.vue';
import notify from "devextreme/ui/notify";

export default {
  name: "WorkListDesktop",
  middleware: ['auth', 'check-company-admin'],
  data(){
    return {
      workTypes: [],
      workMachineList: [],
      equipmentList: [],
      seasonsList: [],
      parcelList: [],
      gridAttributes:{
        class: 'admin-grid'
      },
      popupAttributes: {
          class: 'work-input-edit-popup'
      },
      workGridRefKey: 'work-grid',
      gridRemoteOperations: {
        paging: true,
        filtering : true,
        sorting: true
      },
      pageSizes: [15, 25, 100],
      columnEditorOptions: { showClearButton: true },
      works: [],
      parcels: [],
      workDatasource: new CustomStore({
        key: 'uuid',
        load: this.load,
        insert: this.insert,
        update: this.update,
        remove: this.remove
      })
    };
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxEditing,
    DxTexts,
    DxLookup,
    DxRequiredRule,
    DxStringLengthRule,
    DxHeaderFilter,
    DxFilterRow,
    DxSelection,
    Forbidden,
    DxScrolling,
    DxPopup,
    ParcelComboBoxComponent,
    DxButton
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
    }),
    canEdit() {
      return canEdit(this);
    },
  },
  methods: {
    ...mapActions({
      getWorks: 'seasonOnParcelHasCulture/getWorkListForDesktop',
      getParcelsAndSeasons: 'seasonOnParcelHasCulture/getParcelsAndSeasons',
      changeWork: 'seasonOnParcelHasCulture/updateWork',
      deleteWork: 'seasonOnParcelHasCulture/deleteWork',
      getWorkTypes: "works-manager/getWorks",
      getWorkMachineList: 'works-work-machines/getWorkMachines',
      getWorkMachineEquipmentsList: 'works-work-machines/getWorkMachineEquipments',
      getSeasons: 'seasons/getSeasons'
    }),
    handleRowEdit(e) {
      // Check if the current row has batch_item
      if (e.data.batch_item || e.data.has_stock_transaction) {
        this.workNotify("This row cannot be edited as it has batch items.", "error");
        e.cancel = true;
      } else {
        e.cancel = false;
      }
    },
    getParcelList(options) {
      if(options?.data?.season?.uuid === undefined){
        return this.parcels;
      }
      else{
        return this.parcels.filter(parcel => parcel.season_uuid === options?.data?.season?.uuid);
      }
    },
    setSurfaceValue(newData, value) {
      const { parcel_surface: surface } = this.parcels.find(parcel => parcel.parcel_uuid === value) || {};

      newData.parcel = { uuid: value, surface };
    },
    setParcelValue(newData, value, currentRowData) {
      newData.parcel = {
        uuid: null,
        surface: null
      };
      newData.season = {
        uuid: value,
      };
    },
    rowMarker(e) {
      if (e.rowType === "data" && (e.data.batch_item || e.data.has_stock_transaction)) {
        e.rowElement.style.backgroundColor = ROW_BACKGROUND_COLOR;
      }
    },
    onEditorPreparing(e){
      if(e.parentType === 'dataRow' && e.dataField === 'parcel.uuid'){
        const isSeasonNotSet = e.row.data.season?.uuid === undefined || e.row.data.season?.uuid === null;
        e.editorOptions.disabled = isSeasonNotSet;
      }
    },
    onRowRemoving(e) {
      if (e.data.batch_item || e.data.has_stock_transaction) {
        this.workNotify("Work cannot be deleted, work has input .", "error");
        e.cancel = true;
      } else {
        e.cancel = false;
      }
    },
    async load(data){
      let pageLimit = data.take;
      let pageNumber = (data.skip + data.take)/pageLimit;

      let dataSource =  {
        data:[],
        totalCount:0
      };

      await this.getWorks({
        filter: data.filter,
        sort: data.sort,
        page: pageNumber
      }).then((resp) => {
        dataSource.data = resp.data;
        dataSource.totalCount = resp.pagination.total;
      });

      return dataSource;
    },
    async update(key, obj){
      const updatable = Object.assign(
          {},
          this.works.find(item => item.uuid === key),
          obj
      );

      updatable.work_uuid = updatable.work.uuid;
      updatable.machine_uuid = updatable.work_machine.uuid;
      updatable.equipment_uuid = updatable.equipment ? updatable.equipment.uuid : null;
      updatable.season_uuid = updatable.season.uuid;
      updatable.parcel_uuid = updatable.parcel.uuid;
      delete updatable.equipment;
      delete updatable.equipments;
      delete updatable.work;
      delete updatable.parcel;
      delete updatable.season;
      delete updatable.work_machine;

      // Call the update API
      await this.changeWork(updatable).then((response) => {
        this.workNotify("Work has been updated.", "success");
      }).catch((e) => {
        this.workNotify("Work update error.", "error");
      });

      // Reload the works after successful update
      const response = await this.getWorks({ limit: 1000 });
      this.works = response.data;

    },
    async remove(key, obj){
      await this.deleteWork({
        uuid: key
      }).then((response) => {
        this.workNotify("Work has been deleted.", "success");
      }).catch((e) => {
        this.workNotify("Error deleting work.", "error");
      });
    },
    workNotify(message, type){
      notify(
          {
            message: message,
            width: "auto",
            position: {
              at: "center",
              my: "start",
              of: "#toast-msg"
            }
          },
          type,
          3000
      );
    }
  },
  mounted(){
    this.getWorks({limit: 1000}).then(response => {
      this.works = response.data;
    });
    this.getParcelsAndSeasons({}).then(response => {
      this.parcels = response.data;
    });
    this.getWorkTypes().then(response => {
      this.workTypes = response.data;
    });
    this.getWorkMachineList().then(response => {
      this.workMachineList = response.data;
    });
    this.getWorkMachineEquipmentsList().then(response => {
      this.equipmentList = response;
    });
    this.getSeasons().then(response => {
      this.seasonsList = response.data;
    });
  }
}

</script>

<style lang="scss">
.work-input-edit-popup{
  .dx-popup-normal{
    border-radius: 0px;
  }
}
</style>

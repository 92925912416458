<template>
  <div v-if="canEdit || canDelete" v-cloak>
    <div v-show="showHistory" v-html="historika"></div>
    <div v-show="start_mode == false && !showCellLayerMapsDialog" class="artic-works title_wrapper input_wrapper">
      <div class="left_title">
        <div class="left_title_text" @click="showHistoryFunction">
          Lucrări
        </div>
        <b-button
          class="title_white_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="showFilters"
          ><img src="@/assets/images/icons/options.svg"
        /></b-button>
      </div>
      <div class="right_title">
        <b-button
          class="title_white_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="editArticle"
          ><img src="@/assets/images/icons/edit.svg"
        /></b-button>
        <b-button
          class="title_add_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="showAddActionSelector"
          ><img src="@/assets/images/icons/plus.svg"
        /></b-button>
      </div>
    </div>
    <div v-show="start_mode == true && !showCellLayerMapsDialog" class="title_wrapper input_wrapper">
      <div class="left_title">
        <b-button
          class="title_white_button title_text_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="selectAll"
          >Selectează tot</b-button
        >
      </div>
      <div class="right_title">
        <b-button
          class="title_white_button title_text_button"
          variant="outline-secondary"
          size="sm"
          pill
          @click="
            () => {
              start_mode = false;
              deselectAll();
            }
          "
          >Renunță</b-button
        >
      </div>
    </div>
    <div v-if="showFilterText && !showCellLayerMapsDialog" class="title_text">
      {{ filter_text }} <a href="#" @click="resetFilters">Resetează filtre</a>
    </div>
    <div class="digital-agenda-map-wrapper" v-if="showCellLayerMapsDialog">
      <google-map
        :showMap="showCellLayerMapsDialog"
        :selection_mode="map_selection_mode"
        :initMapInMount="true"
        :parcel_uuid="selected_parcel_uuid_for_map"
        @onParcelClicked="showParcelInfo"
        @showToastMessage="showToastMessage"
        @removeSelectedParcel="removeSelectedParcel"
        @addSelectedParcel="addSelectedParcel"
        :parcelInfoDialogVisibility="showCellInfo"
        @showParentModule="showWorks"
        testMessage="MAPP FROM AGENDA"></google-map>
    </div>
    <works
      ref="worksModule"
      v-show="!showCellLayerMapsDialog"
      @setStartMode="setStartMode"
      @showToastMessage="showToastMessage"
      @setParentShowAddArticle="setParentShowAddArticle"
      @showCellLayerMaps="showCellLayerMaps"
      @setShowFilterText="setShowFilterText"
      :showFilterText="showFilterText"
      :start_mode="start_mode"
      usage_mode="FULL_OPTION"
      ></works>
    <digital-agenda-dep-selector
      :showPopup="showDepSelectorDialog"
      :departments="departments"
      :company="company"
      @hideAndFillList="hideSelectorAndFillList"
    ></digital-agenda-dep-selector>
    <filters-dialog
      ref="filterDialog"
      @applyFilters="applyFilters"
      :showPopup="showFiltersDialog"
      @hidden="hideFilters"
    ></filters-dialog>
    <div class="continue_sticky_dialog" v-show="start_mode == true && addArticleShowing == false">
      <b-button
        class="continue_button"
        variant="outline-secondary"
        size="sm"
        pill
        @click="showAddArticleDialogFunction"
        >Continuă</b-button
      >
    </div>
    <mobile-footer :menuItems="footerMenuItems" v-if="!showCellLayerMapsDialog"></mobile-footer>
    <batch-product-list v-if="false"
      :showPopup="showBatchProductDialog"
      :templateData="parcelListTemplateData"
      @hidden="hideBatchProductDialog"
    ></batch-product-list>
    <parcel-info-dialog v-if="showCellInfo"
      :showPopup="showCellInfo"
      @hidden="hideParcellInfo"
      :filters="workListFilters"
      @closeAndSwitchToList="hideParcellInfoAndShowList"
      :season="currentSeason"
      :parcel_uuid="selected_parcel_uuid_for_map"
      :parcel_group_uuid="selected_parcel_group_uuid_for_map"
      :parcel_group_parcel_uuids="parcel_group_parcel_uuids"
      :parcel_name="selected_parcel_name_for_map"
      :parcel_surface="selected_parcel_surface_for_map"
      :season_on_parcel_uuid="selected_season_on_parcel_uuid"
      :layer="selected_layer"
      :selectedLayer="selectedLayer"></parcel-info-dialog>
    <manual-work-dialog
      :templateData="manualWorkTemplateData"
      :showPopup="showManualWork"

      @hidden="hideManualWork"
      @hideAndRefresh="hideManualWorkAndRefresh"
      @showToastMessage="showToastMessage">
    </manual-work-dialog>
    <add-action-selector
      :showPopup="addActionSelectorVisible"
      @hidden="hideAddActionSelector"
      @startAddProduct="addArticleFunction"
      @startAddWork="showManualWorkDialog"
    ></add-action-selector>

    <DxToast
      class="toastStyle"
      ref="daNotification"
      :visible="push_notification.is_visible"
      :close-on-outside-click="true"
      type=""
      position="top"
      :display-time="5000"
    >
      <template #content>
        <div
          :class="[
            { alert_message: true },
            { error_message: push_notification.type == 'error' },
            { info_message: push_notification.type == 'info' },
            { success_message: push_notification.type == 'success' },
          ]"
        >
          <div><img /></div>
          <div class="alert_message_text">{{ push_notification.message }}</div>
        </div>
      </template>
    </DxToast>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import FiltersDialog from "./dialogs/filters";
import DigitalAgendaDepSelector from "./dialogs/selectDepartment";
import BatchProductList from "./dialogs/batchProductList.vue";
import WorkWithMachines from "./partials/workWithMachines";
import { mapActions, mapGetters } from "vuex";
import ParcelInfoDialog from "./dialogs/parcelInfoDialog.vue";
import ManualWorkDialog from "./dialogs/manualWorkDialog.vue";
import AddActionSelector from "./dialogs/addActionSelector.vue";
import { DxToast } from "devextreme-vue/toast";
import DxSelectBox from "devextreme-vue/select-box";
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
import { getSubdomain } from "@/helpers/common";
import  MobileFooter  from "@/components/Mobile/Footer.vue"
import GoogleMap from "@/components/Map/GoogleMap.vue";
import Works from "./partials/works.vue"
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
Vue.use(infiniteScroll);

export default {
  layout(context) {
    return "noheader";
  },
  name: "ApplyArticles",
  data() {
    return {
      addActionSelectorVisible: false,
      map_selection_mode: false,
      footerMenuItems: [
        {
          code: "HOME",
          spacer: false,
          image: "home_icon.svg",
          selected_image: "home_icon.svg",
          text: "Acasă",
          event: this.showDashboard,
          selected: false
        },
        {
          spacer: true,
          event: ""
        },
        {
          code: "WORKS",
          spacer: false,
          image: "book-open.svg",
          selected_image: "products_icon.svg",
          text: "Lucrări",
          event: this.showWorks,
          selected: true
        },
        {
          spacer: true,
          event: ""
        },
        {
          code: "MAP",
          spacer: false,
          image: "pin_inactive.svg",
          selected_image: "pin.svg",
          text: "Harta",
          event: this.showCellLayerMaps,
          selected:false
        },
        {
          spacer: true,
          event: ""
        },
        {
          code: "SEARCH",
          spacer: false,
          image: "search.svg",
          text: "Căutare",
          event: this.showFilters,
          selected: false
        },

      ],
      addArticleShowing:false,
      showCellLayerMapsDialog: false,
      showCellInfo: false,
      showManualWork: false,
      showHistory: false,
      showHistoryCounter: 0,
      showDepSelectorDialog: false,
      infinite_scroll_busy: false,
      showBatchProductDialog: false,
      showFiltersDialog: false,
      start_mode: false,
      row_selected: false,
      works: [],
      workDatesDistinct: [],

      push_notification: {
        is_visible: false,
        message: "Selectează o lucrare!",
        type: "error",
      },

      manualWorkTemplateData: {
        selectedParcelUUIDs:[]
      },

      parcelListTemplateData: {
        parcels: "",
        parcel_count: 0,
        products: "",
        unique_products: [],
        total_surface_worked: 0,
      },
      form_mode: "",
      filter_text: "Listă filtrată după criteriile date.",
      spc_work_pagination: "",
      workListFilters: {
        departments: [],
        date_from: "",
        date_to: "",
        works: [],
        machines: [],
        parcels: [],
        products: [],
        season: [],
        src: null,
      },
      showFilterText: false,
      historika: "",
      selected_parcel_uuid_for_map: null,
      selected_parcel_group_uuid_for_map: null,
      selected_parcel_group_parcel_uuids: [],
      selected_parcel_name_for_map: null,
      selected_parcel_surface_for_map: null,
      selected_season_on_parcel_uuid: null,
      selected_layer: null,
      currentSeason:'',
      local_seasons: this.seasons ? this.seasons : [],
      selectedLayer: {},
      mapSelectedStartup:false
    };
  },
  computed: {
    ...mapGetters({
      company: "auth/domain",
      current_user: "auth/user",
      current_token: "auth/token",
      currentDepartment: "auth/currentDepartment",
      departments: "company/departments",
      spcWorks: "seasonOnParcelHasCulture/spcWorks",
      seasons: 'seasons/seasons',
    }),
    config() {
      return {
        permission: permissions.DIGITAL_AGENDA,
        permission_type: permissionTypes.module,
      }
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    dataUrl() {
      return window.location.href;
    },
    current_season_getter() {
      return this.currentSeason;
    },
    current_query_token(){
      if(this.$route.query.authToken !== undefined){
        return this.$route.query.authToken;
      }
      return null;
    }
  },
  watch: {
    works: {
      handler: function (newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    workDatesDistinct: {
      handler: function (newVal) {},
      deep: true,
    },
    current_token: function(newVal) {

    },
    seasons: {
      handler: function(newVal) {
        this.seasons_local = newVal;
        this.setCurrentSeason(newVal);

        if(newVal) {
          if(this.$route.query.showMapp !== undefined && this.$route.query.showMapp === 'true' && !this.mapSelectedStartup){
            this.mapSelectedStartup = true;
            if(this.$route.query.parcel !== undefined && this.$route.query.parcel.length > 0){
              this.selected_parcel_uuid_for_map = this.$route.query.parcel;
            }
            else{
              this.selected_parcel_uuid_for_map = null;
            }
            this.showCellLayerMaps(this.selected_parcel_uuid_for_map, this.current_season_getter);
          }
        }
      }
    },
    current_query_token: {
      handler: function () {
        this.getDepartments({company_uuid:this.company.uuid});
        this.listDepartments({company_uuid:this.company.uuid});
      },
      deep: true,

    },
    showCellLayerMapsDialog : {
      handler: function (newVal, oldValue) {
      },
      deep: true,
    }
  },
  components: {
    Forbidden,
    FiltersDialog,
    DigitalAgendaDepSelector,
    BatchProductList,
    WorkWithMachines,
    DxToast,
    ParcelInfoDialog,
    ManualWorkDialog,
    MobileFooter,
    GoogleMap,
    Works,
    DxSelectBox,
    AddActionSelector,
  },
  methods: {
    ...mapActions({
      checkDomain: "auth/checkDomain",
      getDepartments: "company/getDepartments",
      listDepartments: "department/listDepartments",
      getSpcWorkSummary: "seasonOnParcelHasCulture/getSpcWorkSummary",
      nsGetSpcMachines: "seasonOnParcelHasCulture/nsGetSpcMachines",
      nsGetSpcParcels: "seasonOnParcelHasCulture/nsGetSpcParcels",
      getBatches: "batch/getBatches",
      getUsedProducts: "batch/getUsedProducts",
      changeDepartment: "auth/changeDepartment",
      getLayers: "layers/getLayers",
      getSeasons: "seasons/getSeasons",
    }),
    showParcelInfo(layer, group_config){
      if(layer && layer.parcel_uuid) {
        this.selectedLayer = layer;
        this.selected_parcel_uuid_for_map = layer.parcel_uuid;
        this.selected_parcel_group_uuid_for_map = group_config.isParcelGroupModeActive ? layer.parcel_group_uuid : null;
        this.parcel_group_parcel_uuids = group_config.isParcelGroupModeActive ? layer.parcel_group_parcel_uuids : [];
        this.selected_parcel_name_for_map = group_config.isParcelGroupModeActive ? layer.parcel_group_name ?? layer.name : layer.name;
        this.selected_parcel_surface_for_map = group_config.isParcelGroupModeActive ? (layer?.parcel_group_surface ?? layer?.surface ?? 0).toString() : (layer?.surface ?? 0).toString();
        this.selected_season_on_parcel_uuid = layer.season_on_parcel_uuid;
        this.selected_layer = layer;
        this.workListFilters.src = 'map';
        this.showCellInfo = true;
      }
    },
    showManualWorkDialog(){
      this.showManualWork = true;
      // if(this.manualWorkTemplateData.selectedParcelUUIDs && this.manualWorkTemplateData.selectedParcelUUIDs.length > 0){
      //   this.showManualWork = true;
      // }
      // else{
      //   this.showToastMessage('error', 'Trebuie sa selectati macar o parcela!');
      // }
    },
    showAddActionSelector(){
      this.addActionSelectorVisible = true;
    },
    hideAddActionSelector(){
      this.addActionSelectorVisible = false;
    },
    removeSelectedParcel(parcel_uuid){
      this.manualWorkTemplateData.selectedParcelUUIDs.splice(this.manualWorkTemplateData.selectedParcelUUIDs.indexOf(parcel_uuid), 1);
    },
    addSelectedParcel(parcel_uuid){
      this.manualWorkTemplateData.selectedParcelUUIDs.push(parcel_uuid);
    },
    hideParcellInfo(){
      this.showCellInfo = false;
    },
    hideManualWork(){
      this.showManualWork = false;
    },
    async hideManualWorkAndRefresh(addProduct, response_data){
      this.showManualWork = false;
      if(addProduct){
        this.$refs.worksModule.fillWorkAndAddProductAfterInsert(response_data);
      }
      this.$refs.worksModule.fillWorkList(null);

       await this.$store.dispatch('layers/getLayers', {
                    department_uuid: this.$store.getters['auth/currentDepartment'].department_uuid,
                    season_uuid: this.currentSeason,
                }).catch((e) => {
                    console.error('Layer load error: ', e);
                });


    },
    hideParcellInfoAndShowList(){
      this.showCellInfo = false;
      this.showCellLayerMapsDialog = false;
      this.setActiveFooterMenu("WORKS");
    },
    setActiveFooterMenu(code){
      this.footerMenuItems.forEach((menu_item) => {
        if(menu_item.code == code){
          menu_item.selected = true;
        }
        else if(!menu_item.spacer){
          menu_item.selected = false;
        }
      });
    },
    showMapForParcel(parcel_uuid, season_uuid){
      this.showCellLayerMaps(parcel_uuid, season_uuid);
    },
    addArticleFunction() {
      this.start_mode = true;
      this.form_mode = "new";
    },
    editArticle() {
      this.showToastMessage(
        "info",
        "Funția este în curs de dezvoltare. Contactează administratorul de sistem!"
      );
    },
    showDashboard(){
      this.showToastMessage(
        "info",
        "Funția este în curs de dezvoltare. Contactează administratorul de sistem!"
      );
    },
    showWorks(){
      this.hideCellLayerMaps();
      this.setActiveFooterMenu("WORKS");
    },
    showFilters() {
      this.showFiltersDialog = true;
    },
    hideFilters() {
      this.showFiltersDialog = false;
    },
    showCellLayerMaps(parcel_uuid, season_uuid, selection_mode = false) {
      this.map_selection_mode = selection_mode;
      if(typeof parcel_uuid === 'object'){
        parcel_uuid = null;
      }
       this.selected_parcel_uuid_for_map = parcel_uuid;
       if(season_uuid != null || season_uuid != undefined){
        this.currentSeason = season_uuid;
       }
       else{
        this.setCurrentSeason(this.seasons);
       }
      this.$set(this, 'showCellLayerMapsDialog', true);
      this.setActiveFooterMenu("MAP");
    },
    hideCellLayerMaps() {
      this.showCellLayerMapsDialog = false;
      this.$set(this, 'showCellLayerMapsDialog', false);
      this.selected_parcel_uuid_for_map = null;
      this.selected_parcel_group_uuid_for_map = null;
    },
    applyFilters(filters) {
      this.$refs.worksModule.applyFilters(filters);
      this.hideFilters();
    },
    resetFilters() {
      this.$refs.filterDialog.setInitFilters({
        //departments: [this.currentDepartment.department_uuid],
        departments: this.departments.map(d => d.uuid),
        date_from: "",
        date_to: "",
        works: [],
        machines: [],
        parcels: [],
        products: [],
        seasons: [],
        group_by: 'work'
      });
      this.applyFilters({
        //departments: [this.currentDepartment.department_uuid],
        departments: this.departments.map(d => d.uuid),
        date_from: "",
        date_to: "",
        works: [],
      });
    },
    selectAll() {
      this.$refs.worksModule.selectAll();
    },
    deselectAll() {
      this.$refs.worksModule.deselectAll();
    },
    showToastMessage(type, message) {
      this.push_notification.type = type;
      this.push_notification.message = message;
      this.$refs.daNotification.instance.show();
    },
    showAddArticleDialogFunction() {
      this.$refs.worksModule.showAddArticleDialogFunction();
    },
    hideBatchProductDialog() {
      this.showBatchProductDialog = false;
    },
    checkDefaultDepartment() {
      this.showDepSelectorDialog = true;
    },
    hideSelectorAndFillList() {
      this.showDepSelectorDialog = false;
      this.resetFilters();
    },
    selectFirstDepartment(departments) {
      if (departments.length > 0) {
        this.changeDepartment({
          company_uuid: this.company.uuid,
          company_name: this.company.company,
          department_uuid: departments[0].uuid,
          department_name: departments[0].name,
        }).then((resp) => {
          if (
            resp.default_department &&
            resp.default_department.department_uuid === departments[0].uuid
          ) {
            this.$refs.worksModule.resetFilters();
            this.resetFilters();
            this.getSeasons();
          } else {
            errorMessage(vm, vm.$t("error_changing_department"));
          }
        });
      } else {
        this.showToastMessage(
          "error",
          "Nu sunt configurate departamentele! Contactați administratorul de sistem!"
        );
      }
    },
    showHistoryFunction() {
      this.showHistoryCounter++;
      if (this.showHistoryCounter == 5) {
        this.showHistoryCounter = 0;
        this.showHistory = !this.showHistory;
      }
    },
    async onIdle() {
      //console.log("user is IDLE");
    },
    async onActive() {
      //console.log("user is ACTIVE");
    },
    setStartMode(newVal){
      this.start_mode = newVal;
    },
    setParentShowAddArticle(newVal){
      this.addArticleShowing = newVal;
    },
    setShowFilterText(newVal){
      this.showFilterText = newVal;
    },
    setCurrentSeason(seasons){
      if(seasons.length === 0) return;

      this.currentSeason = seasons[0].uuid;
      seasons.forEach((obj) => {
        if(new Date(obj.created_at) > new Date(this.currentSeason.created_at)){
          this.$set(this, 'currentSeason', obj.uuid);
        }
      });
    }
  },
  mounted() {
    this.historika += "hostika = " + window.location.host + "<br>";
    this.historika += "authToken = " + this.$route.query.authToken + "<br>";
    this.historika += "current token = " + this.current_token + "<br>";
    this.historika +=
      "current user = " +
      (this.current_user ? this.current_user.uuid : null) +
      "<br>";
    this.historika += "domain = " + getSubdomain() + "<br>";

    this.checkDomain({ domain: getSubdomain() })
      .catch((err) => {
        this.historika += "ckd_err = " + err + "<br>";
      })
      .then((data) => {
        this.historika += "then respi = " + data + "<br>";
        this.historika += "c_uuid = " + data.uuid + "<br>";
        this.getDepartments({ company_uuid: this.company.uuid }).then(
          (response) => {
            if (!response.find(obj => obj.uuid === this.currentDepartment?.department_uuid)) {
              this.selectFirstDepartment(response);
            } else {
              if(this.$refs.worksModule) {
                this.$refs.worksModule.resetFilters();
              }

              this.resetFilters();
              this.getSeasons();
            }
          }
        );
      });
  },
};
</script>

<style lang="scss">
body {
  background-color: #f5f5f5;
}
.toastStyle{
  z-index: 6010;
}

.artic-works {
  padding-top: 20px;
}
@import "@/assets/scss/digitalAgenda/daGrid.scss";
</style>

const getters = {
  settingGroups: state => state.setting_groups,
  groupSettings: state => state.group_settings,
  groupSetting: state => state.group_setting,
  customSettingValues: state => state.custom_setting_values,
  generalSettings: state => state.general_settings,
  getDefaultDateFormat: state => {
    return state.general_settings.find(setting => setting.code === 'FRE_GENERAL_DATE_FORMAT')?.value ?? 'YYYY-MM-DD';
  },
  getQuantityDecimalPlaces: state => {
    return parseInt(state.general_settings.find(setting => setting.code === 'STOCK_QUANTITY_DECIMALS')?.value ?? 4);
  }
}

export default getters;

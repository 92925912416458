<template>
  <transition name="modal" id="product-list-dialog">
    <DxPopup class="vertical-center" ref="productSelector" id="dx-dialog" width="100vw" height="100vh" title="Filtre"
      :close-on-outside-click="false" :show-close-button="true" :visible="showPopup" :resize-enabled="true"
      :darg-enabled="false" @hidden="buttonCloseEvent">
      <template #title>
        <div class="filter_title_template">
          <div class="left_side">
            <img src="@/assets/images/icons/arrow-back.svg" @click="goBackOneStep" />
            <span @click="goBackOneStep">Înapoi</span>
          </div>
          <div class="right_side">
            <img class="close_button" src="@/assets/images/icons/dialog-close.svg" @click="buttonCloseEvent" />
          </div>
        </div>
      </template>
      <template #content>
        <div class="filters_tabs">
          <DxTabPanel :data-source="filterSteps" :loop="false" :selected-index="currentStep" :animation-enabled="true"
            :swipe-enabled="false" :show-nav-buttons="false" @selection-changed="tabSelectionChanged($event)">
            <template #item="{ data: step }">
              <div>
                <div v-if="step == 1" class="filter_container" :use-native="true">
                  <ProductList ref="productGridWithAdd" @selectionChanged="productSelected"
                    :action-method="selectFromStock ? null : getProducts" :dataColumns="productComboColumns"
                    :localDataset="stockProductList" :hasError="false" :allowAddNew="allowAddNew"
                    placeholder="Alegeți produs"></ProductList>
                </div>
                <div v-if="step == 2" class="filter_container" :use-native="true">
                  <div class="filter_header">{{ selectedProduct?.name ?? "" }}</div>
                  <ProductData ref="productDataEdit" :selectedProductList="selectedProducts"
                    :selectedProduct="selectedProduct" @goForward="goForward" :selectFromStock="selectFromStock">
                  </ProductData>
                </div>
                <div v-if="step == 3" class="filter_container" :use-native="true">
                  <div class="filter_header_container">
                    <div class="filter_header">Lista Produse</div>
                    <div class="new_item_add_button" @click="goToStep(0)">
                      <i class="fas fa-plus"></i>
                    </div>
                  </div>
                  <SelectedProductList :selectedProductList="selectedProducts" @closeProductSelector="buttonCloseEvent"
                    @editProductData="editProductData" :selectFromStock="selectFromStock">
                  </SelectedProductList>
                </div>
              </div>
            </template>
          </DxTabPanel>
          <toast-message ref="toastMessage"></toast-message>
        </div>
      </template>
    </DxPopup>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import { DxPopup } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import ToastMessage from "@/components/Mobile/toastMessage.vue";
import ProductList from "./ProductList.vue";
import ProductData from "./ProductData.vue";
import SelectedProductList from "./SelectedProductList.vue";

export default {
  name: "ProductSelector",
  props: {
    showPopup: {
      type: Boolean,
      default: true,
    },
    selectedProducts: {
      type: Array,
      default: () => [],
    },
    selectFromStock: {
      type: Boolean,
      default: false,
    },
    allowAddNew: {
      type: Boolean,
      default: true,
    },
    groupByWarehouse: {
      type: Boolean,
      default: false,
    },
    filterData:{
      type: Object,
      default: null
    },
  },
  data() {
    return {
      filterSteps: [1, 2, 3],
      currentStep: 0,
      selectedProduct: {},
      productComboColumns: this.groupByWarehouse ? [
        { dataField: "code", caption: "Cod", width: "25%" },
        { dataField: "name", caption: "Denumire" },
        { dataField: "warehouse_name", caption: "Depozit", groupIndex: "0" },
        { dataField: "warehouse_quantity_m", caption: "Cantitate", width: "25%" },
      ] : [
        { dataField: "code", caption: "Cod", width: "25%" },
        { dataField: "name", caption: "Denumire" },
      ],
      stockProductList: [],
    };
  },
  components: {
    DxPopup,
    DxTabPanel,
    ToastMessage,
    ProductList,
    ProductData,
    SelectedProductList,
  },
  computed: {},
  watch: {
    showPopup(newVal) {
      if (newVal) {
        if (this.selectedProducts && this.selectedProducts.length == 1 && (this.selectedProducts[0].quantity ?? 0) == 0) {
          this.selectedProduct = this.selectedProducts[0];
          this.currentStep = 1;          
        } else if (this.selectedProducts && this.selectedProducts.length > 0) {
          this.currentStep = 2;
        } else {
          this.currentStep = 0;
        }
        this.setTitle();
      }
    },
    'filterData.department_legal_entity_uuid': {
      handler: function (newVal, oldVal) {
          if(newVal != null){
              this.loadStockProducts();
          }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getProducts: "smProducts/getSmProducts",
      getSmProductsFull: 'smProducts/getSmProductsFull',
    }),
    tabSelectionChanged(e) {
      this.currentItem = e.addedItems[0];
      this.currentStep = this.filterSteps.indexOf(this.currentItem);
    },
    setTitle() {
      if (this.currentStep == 0) {
        this.$emit("setTitle", "Recepție produs", true);
      } else if (this.currentStep == 1) {
        this.$emit("setTitle", "Listă produse", false);
      }
    },
    goForward() {
      this.currentStep = this.currentStep + 1;
      this.setTitle();
    },
    goBackOneStep() {
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
        this.setTitle();
      } else {
        this.buttonCloseEvent();
      }
    },
    goToStep(step) {
      this.currentStep = step;
      this.setTitle();
    },
    buttonCloseEvent() {
      this.$emit("hidden");
    },
    editProductData(productUuid) {
      const product = this.selectedProducts.find(product => product.uuid === productUuid);
      if (product) {
        this.selectedProduct = product;
        this.goToStep(1);
      }
    },
    productSelected(selectedProduct) {
      if (selectedProduct && selectedProduct.uuid) {
        const existingProduct = this.selectedProducts.find(product => (product.uuid === selectedProduct.uuid || (
          product.uuid.toString().startsWith("NEW_") && selectedProduct.uuid.toString().startsWith("NEW_") && product.name === selectedProduct.name
        )));

        if (existingProduct) {
          this.$refs.toastMessage.showError("Produsul este deja adăugat.");
          return;
        }

        selectedProduct.measure_unit_id = selectedProduct?.measure_unit?.id ?? null;
        this.selectedProduct = { ...selectedProduct };

        this.goForward();
      }
    },
    loadStockProducts(){
      let filter = {
        limit: 9999,
        has_stock: 1,
        department_legal_entity_uuid: this.filterData?.department_legal_entity_uuid ?? null,
      };
      this.stockProductList = [];

      this.getSmProductsFull(filter).then((response) => {
        response.data.data.forEach((stock) => {
          if (this.groupByWarehouse) {
            stock.stock_list.forEach((stock_data) => {
              let stock_data_item = {
                ...stock,
                warehouse_uuid: stock_data.warehouse_uuid,
                warehouse_name: stock_data.warehouse_name,
                warehouse_quantity: parseFloat(stock_data.quantity),
                warehouse_quantity_m: parseFloat(stock_data.quantity) + ' ' + stock.measure_unit.symbol,
                warehouse_department_legal_entity_uuid: stock_data.legal_entity_uuid,
                warehouse: {
                  uuid: stock_data.warehouse_uuid,
                  name: stock_data.warehouse_name,
                  quantity: parseFloat(stock_data.quantity),
                  quantity_m: parseFloat(stock_data.quantity) + ' ' + stock.measure_unit.symbol,
                  warehouse_department_legal_entity_uuid: stock_data.legal_entity_uuid,
                }
              };
              this.stockProductList.push(stock_data_item);
            });
          } else {
            this.stockProductList.push({
              ...stock,
              warehouse_uuid: null,
              warehouse_name: null,
              warehouse_quantity: 0,
              warehouse_department_legal_entity_uuid: null,
            });
          }
        });
      });
    }
  },
  mounted() {
    if (this.selectFromStock) {
      this.loadStockProducts();
    }
  },
};
</script>

import Vue from 'vue'
import { getFormattedQuantity, getLocalFormattedQuantity } from '~/helpers/stockHelper';
//import {convertDecimalHoursToGMT} from "~/helpers/common";

Vue.filter('getFormattedQuantity', getFormattedQuantity);
Vue.filter('getLocalFormattedQuantity', getLocalFormattedQuantity);

/*Vue.filter('defaultVal', function (value, default_value) {
  if (!value) return default_value;
  return value.toString();
});

Vue.filter('lowerCase', function (value, default_value) {
  if (!value) return default_value;
  return value.toString().toLowerCase();
});

Vue.filter('lineToUnderscore', function (value, default_value) {
  if (!value) return default_value;
  return value.toString().toLowerCase().replaceAll('-', '_').trim();
});

Vue.filter('toGMT', function (value, default_value) {
  if (!value) return default_value;
  return convertDecimalHoursToGMT(value);
});

Vue.filter('decimal', function (value, default_value) {
  if (!value) return default_value;
  return parseFloat(value).toFixed(2);
});

Vue.filter('getFormattedQuantity', function (value, number_of_decimals) {
  if (!value) return 0;
  return parseFloat(parseFloat(value).toFixed(number_of_decimals));
});*/

<template>
  <DxScrollView :use-native="true" class="reception_tabs">
    <div class="reception_input_holder" v-show="showCodeForProduct"
      :class="[{ input_holder_error: $v.productData.code.$error }]">
      <div class="input_label">Internal product code</div>
      <div class="input_value">
        <DxTextBox v-model="productData.code" placeholder="Introduceți codul intern al produsului"
          class="global_dx_number_input" />
      </div>
    </div>
    <div class="reception_input_holder" v-show="showMeasureUnitForProduct"
      :class="[{ input_holder_error: $v.productData.measure_unit_id.$error }]">
      <div class="input_label">Unitate de măsură</div>
      <div class="input_value">
        <DxSelectBox v-model="productData.measure_unit_id" :search-enabled="true" search-mode="contains"
          search-expr="name" class="input_value dx_input_value" placeholder="Alegeți unitate de măsură"
          :data-source="measureUnits" display-expr="name" value-expr="id" :show-clear-button="false"
          drop-down-button-template="imageIcon" @selection-changed="muChanged">
          <template #imageIcon>
            <div>
              <div class="dx_combo_open_button"
                v-show="!productData.measure_unit_id || productData.measure_unit_id == ''">
                <img src="@/assets/images/icons/plus_green.svg" class="custom-selector-icon" />
              </div>
              <div class="dx_combo_open_button"
                v-show="productData.measure_unit_id && productData.measure_unit_id != ''">
                <img src="@/assets/images/icons/edit.svg" class="custom-selector-icon" />
              </div>
            </div>
          </template>
          <template #item="{ data }">
            <div class="dx_custom_combo_item">{{ data.name }}</div>
          </template>
        </DxSelectBox>
      </div>
    </div>
    <div class="reception_input_holder" v-show="showProductTypeForProduct" :class="[
      {
        input_holder_error: $v.productData.product_type_uuid.$error,
      },
    ]">
      <div class="input_label">Tip de produs</div>
      <div class="input_value">
        <DxSelectBox v-model="productData.product_type_uuid" :search-enabled="true" search-mode="contains"
          search-expr="name" class="input_value dx_input_value" placeholder="Alegeți tip de produs"
          :data-source="productTypes" display-expr="name" value-expr="uuid" :show-clear-button="false"
          drop-down-button-template="imageIcon" @selection-changed="productTypeChanged">
          <template #imageIcon>
            <div>
              <div class="dx_combo_open_button" v-show="!productData.product_type_uuid ||
                productData.product_type_uuid == ''
                ">
                <img src="@/assets/images/icons/plus_green.svg" class="custom-selector-icon" />
              </div>
              <div class="dx_combo_open_button" v-show="productData.product_type_uuid &&
                productData.product_type_uuid != ''
                ">
                <img src="@/assets/images/icons/edit.svg" class="custom-selector-icon" />
              </div>
            </div>
          </template>
          <template #item="{ data }">
            <div class="dx_custom_combo_item">{{ data.name }}</div>
          </template>
        </DxSelectBox>
      </div>
    </div>
    <div class="reception_input_holder" :class="[{ input_holder_error: $v.productData.warehouse_uuid.$error }]" v-show="selectFromStock">
      <div class="input_label">Depozit</div>
      <div class="input_value">
        <DxSelectBox v-model="productData.warehouse_uuid" :search-enabled="true" search-mode="contains"
          search-expr="name" class="input_value dx_input_value" placeholder="Alegeți depozit"
          :data-source="getStockWarehouseList()" display-expr="name" value-expr="uuid" :show-clear-button="false"
          drop-down-button-template="imageIcon" @selection-changed="warehouseChanged">
          <template #imageIcon>
            <div>
              <div class="dx_combo_open_button" v-show="!productData.warehouse_uuid">
                <img src="@/assets/images/icons/plus_green.svg" class="custom-selector-icon" />
              </div>
              <div class="dx_combo_open_button" v-show="productData.warehouse_uuid">
                <img src="@/assets/images/icons/edit.svg" class="custom-selector-icon" />
              </div>
            </div>
          </template>
          <template #item="{ data }">
            <div class="dx_custom_combo_item">
              {{ data?.name }} ({{ data.quantity | getFormattedQuantity }} {{ productData?.measure_unit?.symbol }})
            </div>
          </template>
        </DxSelectBox>
      </div>
    </div>
    <div class="reception_input_holder" :class="[{ input_holder_error: $v.productData.quantity.$error }]">
      <div class="input_label">Cantitate</div>
      <div class="input_value">
        <DxNumberBox placeholder="Introduceți cantitatea" :min="0"
          :format="quantityFormatter + ' ' + (productData?.measure_unit?.symbol ?? '')" v-model="productData.quantity"
          value-change-event="keyup" class="global_dx_number_input" :show-clear-button="false"
          @value-changed="quantityChanged($event)" />
      </div>
      <div class="consum_tabs">
        <div v-show="selectFromStock" class="consum_input_second_value">Cost produs: {{ parseFloat((stockUnitPrice * (productData.quantity ?? 0))).toFixed(2) }} RON</div>
      </div>
    </div>
    <div class="reception_input_holder" :class="[{ input_holder_error: $v.productData.unit_price.$error }]"
      v-show="!selectFromStock">
      <div class="input_label">Preț unitar net</div>
      <div class="input_value">
        <DxNumberBox :min="0" placeholder="Introduceți prețul unitar net" format="#,##0.00 RON"
          v-model="productData.unit_price" class="global_dx_number_input" :show-clear-button="false" />
      </div>
    </div>
    <div class="reception_input_holder">
      <div class="input_label">Observații</div>
      <div class="input_value">
        <DxTextArea v-model="productData.notes" class="global_dx_number_input" />
      </div>
    </div>
    <div style="margin-bottom: 50px; margin-top: 25px;" class="sticky_action_button" @click="applyProductData">
      Continuă
    </div>
    <toast-message ref="toastMessage"></toast-message>
  </DxScrollView>
</template>

<script>
import { mapActions } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { required, helpers } from "vuelidate/src/validators";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import { getQuantityFormatter } from "~/helpers/stockHelper";
import ToastMessage from "@/components/Mobile/toastMessage.vue";

const greaterThanZero = (value) => {
  return helpers.req(value) && value > 0;
};

const greaterOrEqualsThanZero = (param) => (value) => {
  if (param) {
    return helpers.req(value) && value >= 0;
  }
  return true;
};

const requiredByParam = (param) => (value) => {
  if (param) {
    return helpers.req(value) && value.length > 0;
  }
  return true;
};

const requiredByParamGreaterThanZero = (param) => (value) => {
  if (param) {
    return helpers.req(value) && value > 0;
  }
  return true;
};

const requiredByParamAvaliableQuantity = (param) => (value) => {
  return helpers.req(value) && value <= param && value > 0;
};

export default {
  name: "ProductData",
  props: {
    selectedProductList: {
      type: Array,
      default: () => [],
    },
    selectedProduct: {
      type: Object,
      default: () => null,
    },
    selectFromStock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productData: this.selectedProduct ?? {
        uuid: "",
        name: "",
        code: "",
        quantity: 0,
        unit_price: 0,
        notes: "",
        measure_unit_id: null,
        measure_unit: null,
        product_type_uuid: null,
        product_type: null,
        warehouse_uuid: null,
        warehouse_name: null,
        warehouse_quantity: 0,
        stock_list: [],
      },
      showMeasureUnitForProduct: this.selectedProduct?.uuid?.toString()?.startsWith("NEW_") ?? false,
      showProductTypeForProduct: this.selectedProduct?.uuid?.toString()?.startsWith("NEW_") ?? false,
      showCodeForProduct: this.selectedProduct?.uuid?.toString()?.startsWith("NEW_") ?? false,
      use_default_0_unit_price_on_reception: false,
      measureUnits: [],
      productTypes: [],
      quantityChangeTimer: null, // Timer for debounce
      stockUnitPrice: 0,
    };
  },
  components: {
    DxScrollView,
    DxSelectBox,
    DxTextArea,
    DxNumberBox,
    DxTextBox,
    ToastMessage,
  },
  validations() {
    return {
      productData: {
        uuid: { required },
        measure_unit_id: {
          requiredByParam: requiredByParamGreaterThanZero(
            this.showMeasureUnitForProduct
          ),
        },
        product_type_uuid: {
          requiredByParam: requiredByParam(this.showProductTypeForProduct),
        },
        code: { requiredByParam: requiredByParam(this.showCodeForProduct) },
        quantity: { requiredByParam: (this.selectFromStock ? requiredByParamAvaliableQuantity(this.productData?.warehouse_quantity ?? 0) : greaterThanZero) },
        unit_price: { requiredByParam: greaterOrEqualsThanZero(!this.selectFromStock) },
        warehouse_uuid: { requiredByParam: requiredByParam(this.selectFromStock) },
      },
    };
  },
  computed: {
    quantityFormatter() {
      return getQuantityFormatter();
    },
  },
  watch: {
    "selectedProduct": function (newVal) {
      this.setProduct(newVal);
    },
  },
  methods: {
    ...mapActions({
      getProductTypes: "smProductTypes/searchSmProductTypes",
      getMeasureUnits: "measureUnits/getMeasureUnits",
      getGroupByCode: "settings/getGroupByCode",
      getStockProductInfo: 'smStocks/getSmStockProductInfo',
    }),
    setProduct(product) {
      if (product?.uuid === this.productData?.uuid) {
        return;
      }

      this.productData = product;
      const show_new_product_fields = this.productData.uuid.toString().startsWith("NEW_");
      this.showMeasureUnitForProduct = show_new_product_fields;
      this.showProductTypeForProduct = show_new_product_fields;
      this.showCodeForProduct = show_new_product_fields;
      this.stockUnitPrice = 0;

    },
    muChanged(e) {
      if (!this.selectFromStock) {
        if (e.selectedItem) {
          if (e.selectedItem.id !== this.productData.measure_unit_id) {
            this.productData.measure_unit = e.selectedItem;
            this.productData.measure_unit_id = e.selectedItem.id;
          }
        } else {
          this.productData.measure_unit = null;
          this.productData.measure_unit_id = null;
        }
      }
    },
    productTypeChanged(e) {
      if (!this.selectFromStock) {
        if (e.selectedItem && e.selectedItem.uuid !== this.productData.product_type_uuid) {
          this.productData.product_type_uuid = e.selectedItem.uuid;
          this.productData.product_type = e.selectedItem;
        }
      }
    },
    warehouseChanged(e) {
      if (this.selectFromStock && e.selectedItem) {
        this.productData.warehouse_name = e.selectedItem.name;
        this.productData.warehouse_quantity = e.selectedItem.quantity;
        this.productData.warehouse_department_legal_entity_uuid = e.selectedItem.legal_entity_uuid;

        if (e.selectedItem && e.selectedItem.uuid !== this.productData.warehouse_uuid) {
          this.productData.warehouse_uuid = e.selectedItem.uuid;
        }
      }
    },
    quantityChanged(e) {
      // quantity change get the stock unit price
      if (this.selectFromStock && this.productData.quantity > 0 && this.productData.warehouse_uuid) {
        clearTimeout(this.quantityChangeTimer); // Clear the existing timer
        this.stockUnitPrice = 0;
        this.$set(this, 'stockUnitPrice', 0);

        this.quantityChangeTimer = setTimeout(() => {
          if (this.productData.quantity != null) {
            let getStockInfodata = {
              product_uuid: this.productData.uuid,
              warehouse_uuid: this.productData.warehouse_uuid,
              quantity: this.productData.quantity
            };
            this.getStockProductInfo(getStockInfodata).then(response => {
              this.stockUnitPrice = response.price_net;
              this.$set(this, 'stockUnitPrice', response.price_net);
            });
          }
        }, 800); // Set the timer for 800 ms
      }
    },
    applyProductData() {

      this.$v.$touch();
      if (this.$v.$invalid) {

        // Show error message for invalid fields based on the validation rules
        if (this.selectFromStock) {
          if (this.$v.productData.warehouse_uuid.$invalid) {
            this.$refs.toastMessage.showError("Depozitul este obligatoriu!");
          } else if (this.$v.productData.quantity.$invalid) {
            this.$refs.toastMessage.showError("Cantitatea trebuie să fie înre 1 și " + (parseFloat(this.productData?.warehouse_quantity ?? 0)).toString() + "!");
          }
        }

        return;
      }

      const existingProduct = this.selectedProductList.find(
        (product) => product.uuid === this.productData.uuid
      );
      if (!existingProduct) {
        this.selectedProductList.push(this.productData);
      }

      this.$emit("goForward");
    },
    getStockWarehouseList() {
      if (!this.selectFromStock) {
        return [];
      }
      return this.productData.stock_list?.map(stock => ({
        uuid: stock.warehouse_uuid,
        name: stock.warehouse_name,
        quantity: stock.quantity,
        legal_entity_uuid: stock.legal_entity_uuid,
      })) || [];
    },
  },
  mounted() {
    // Get the product types and measure units for the product if not select from stock
    if (!this.selectFromStock) {
      this.getGroupByCode({
        code: "STOCK_MANAGEMENT",
      }).then((response) => {
        this.use_default_0_unit_price_on_reception =
          response.items.find(
            (obj) => obj.code == "USE_DEFAULT_0_UNIT_PRICE_ON_RECEPTION"
          )?.value == "1";
        if (this.use_default_0_unit_price_on_reception) {
          this.productData.unit_price = 0;
        }
      });

      this.getMeasureUnits().then((response) => {
        this.measureUnits = response.data;
      });
      this.getProductTypes({}).then((response) => {
        this.productTypes = response;
      });
    }
    //-----------------------------------------------------------------------------------
  },
};
</script>

<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div>
      <DxPopup
        height="100vh"
        width="100vw"
        :close-on-outside-click="false"
        :show-close-button="true"
        :visible="showPopup"
        @hiding="resetForm"
        @showing="resetForm"
        @hidden="$emit('hidden')"
      >
        <template #title>
          <div class="add_article_popup_title">
            <div class="left_side">
              <b-button
                v-if="currentStep > 0"
                class="left_title_button"
                variant="outline-secondary"
                size="sm"
                pill
                @click="headerButtonEvent"
                ><img src="@/assets/images/icons/arrow-back.svg" />
                Inapoi</b-button
              >

            </div>
            <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCancelEvent"
            />
          </div>
            <div class="right_side" v-if="false">inainte</div>
          </div>
        </template>
        <template #content>
          <div
            class="add-article-tabs"
            style="width: 100%; display: flex; flex-direction: column"
          >
            <DxTabPanel
              :data-source="applySteps"
              :loop="false"
              :selected-item="currentItem"
              :selected-index="currentStep"
              :animation-enabled="true"
              :swipe-enabled="allowSwipeForm"
              :show-nav-buttons="false"
              @selection-changed="tabSelectionChanged($event)"
            >
              <template #title="{ data: step }">
                <div>{{ step }}</div>
              </template>
              <template #item="{ data: step }">
                <div>
                  <div v-if="step == 1" class="step_1">
                    <div class="step_1_container">
                      <div class="step_title">Adaugă produs</div>
                      <div class="step_subtitle">{{totalSurfaceText}}

                      </div>
                      <DxRadioGroup
                        :items="articleTypes"
                        item-template="item"
                        class="article_type_selector"
                        v-model="selectedArticleType"
                      >
                        <template #item="{ data }">
                          <div
                            :class="[
                              'article_item', 'article_item_selected',
                              {
                                article_item_selected:
                                  selectedArticleType &&
                                  selectedArticleType.code == data.code,
                              },
                            ]"
                          >

                            {{ data.name }}
                          </div>
                        </template>
                      </DxRadioGroup>
                    </div>
                  </div>
                  <div v-if="step == 2" class="step_1">
                    <div class="step_1_container">
                      <div class="step_title align-left">{{ selectArticleTypeName }}</div>
                      <div class="step_subtitle align-left">
                        {{totalSurfaceText}}
                      </div>
                      <DxScrollView class="input-container-scroll" :use-native="true">
                      <div class="input-container">

                           <b-button
                           v-show="false"
                              class="title_add_button add_new_product"
                              variant="outline-secondary"
                              size="sm"
                              pill

                              ><img src="@/assets/images/icons/plus.svg"
                            /></b-button>

                        <div class="step-control-text">Produs</div>
                        <DxDropDownBox
                          ref="productSelectBox"
                          :value="gridBoxValue"
                          :opened="isGridBoxOpened"
                          :defer-rendering="false"
                          :display-expr="gridBoxDisplayExpr"
                          :show-clear-button="false"
                          :data-source="articleDataset"
                          :remote-operations="false"
                          :cache-enabled="true"
                          :class="['step_control', {'product_control' : isGridBoxOpened} ,{
                            step_control_error: $v.batch.product_uuid.$error,
                          }]"
                          @opened="isGridBoxOpened = true"
                          @closed="isGridBoxOpened = false"
                          drop-down-button-template="imageIcon"
                        >
                        <template #imageIcon>
                          <img
                            src="@/assets/images/icons/arrow_down.svg"
                            class="custom-selector-icon"
                          >
                        </template>
                          <template #content>
                            <DxDataGrid
                              ref="productGrid"
                              class="product_grid"
                              no-data-text="🛈 Produsul nu a fost găsit apasă ➕ pentru a adăuga la produsele mele"
                              :data-source="articleDataset"

                              :show-column-headers="false"
                              :hover-state-enabled="true"
                              :repaint-changes-only="true"
                              :selected-row-keys="gridBoxValue"
                              @selection-changed="onGridSelectionChanged($event)"
                              @content-ready="onGridContentReady($event)"
                              height="100%"
                              @init-new-row="initNewProduct($event)"
                              @row-inserting="productInserting($event)"
                            >
                             <DxToolbar>
                              <DxItem
                                location="before"
                                template="collapseTemplate"
                              />
                             </DxToolbar>
                              <template #collapseTemplate>
                                <b-button
                                v-if="allowGridAddNewProduct"
                                  class="add_new_product_button"
                                  size="sm"
                                  pill
                                  @click="addCustomProductInstant"
                                  ><img src="@/assets/images/icons/plus.svg"
                                /></b-button>
                              </template>
                              <DxPopupGrid :show-title="true" :height="200"></DxPopupGrid>
                              <DxColumn data-field="name" caption="Denumire">
                                <DxRequiredRule message="Denumire este obligatorie!"/>
                              </DxColumn>
                              <DxSelection mode="single" />
                              <DxFilterRow :visible="true" />
                              <DxScrolling mode="infinite" />
                              <DxEditing :allow-adding="true" mode="popup" >
                                <DxTexts
                                      save-row-changes="Salvează produs"
                                      cancel-row-changes="Închide"
                                  />
                              </DxEditing>
                            </DxDataGrid>
                          </template>
                        </DxDropDownBox>

                        <div class="step-control-text">Unitate de măsură</div>
                        <DxSelectBox
                          v-model="batch.measure_unit_uuid"
                          :search-enabled="true"
                          search-mode="contains"
                          search-expr="name"
                          class="step_control"
                          :class="{
                            step_control_error:
                              $v.batch.measure_unit_uuid.$error,
                          }"
                          :data-source="measureUnits"
                          display-expr="name"
                          value-expr="uuid"
                          placeholder=""

                          :show-clear-button="false"
                          drop-down-button-template="imageIcon"
                        >
                        <template #imageIcon>
                          <img
                            src="@/assets/images/icons/arrow_down.svg"
                            class="custom-selector-icon"
                          >
                        </template>
                        </DxSelectBox>
                        <div class="step-control-text">Doza</div>
                        <DxNumberBox
                          v-model="batch.dose_per_ha"
                          class="step_control"
                          :class="{
                            step_control_error: $v.batch.dose_per_ha.$error,
                          }"
                          :format="articleDoseFormat"
                          :show-clear-button="false"
                        />
                        <div class="step-control-text">Cantitate</div>
                        <DxNumberBox
                          v-model="batch.quantity"
                          class="step_control"
                          :class="{
                            step_control_error: $v.batch.quantity.$error,
                          }"
                          :format="articleQuantityFormat"
                          :show-clear-button="false"

                        />
                        <div class="step-control-text">Total cost</div>
                        <DxNumberBox
                          v-model="batch.unit_price"
                          class="step_control"
                          :class="{
                            step_control_error: $v.batch.unit_price.$error,
                          }"
                          :show-clear-button="false"
                          format="#,##0.## RON"
                        />
                        <div class="step-control-text">Preț unitar</div>
                        <DxNumberBox
                          v-model="batch.unit_price_per_ha"
                          class="step_control"
                          :class="{
                            step_control_error: $v.batch.unit_price_per_ha.$error,
                          }"
                          :show-clear-button="false"
                          :format="unitPriceFormat"
                        />
                      </div>
                      </DxScrollView>
                    </div>
                  </div>
                </div>
              </template>
            </DxTabPanel>

            <div class="dialog_button_container_sticky" v-if="currentStep > 0">
              <DxButton v-if="false"
                class="dialog_button_products cancel_button"
                :text="cancelButtonText"
                styling-mode="contained"
                @click="buttonCancelEvent"
              />
              <DxButton
                v-show="showSaveAndNewButton"
                class="dialog_button_products action_button save-and-new-button"
                text="Adaugă produs nou"
                styling-mode="contained"
                @click="buttonDoneEvent(false)"
              />
              <DxButton
                v-show="showDoneButton"
                class="dialog_button_products second_action_button"
                :text="doneButtonText"
                styling-mode="contained"
                @click="buttonDoneEvent(true)"
              />
            </div>
            <div>
              <DxScrollView class="articles_container" :use-native="true">
                <div>
                  <div
                    :class="[
                      'article_container',
                      { article_container_alt: index % 2 == 0 },
                    ]"
                    v-for="(header, index) in batchTemplateData.headers.filter((obj) => checkIfBatchIsInSession(obj.batch_uuid))"
                    :key="index"
                  >
                    <div class="article_date">{{header.created_at}}</div>
                    <div class="article_row">
                      <div class="article_prop_name">Produs</div>
                      <div class="article_prop_val">
                        {{ header.product.name }}
                      </div>
                    </div>
                    <div class="article_row" v-if="false">
                      <div class="article_prop_name">Data</div>
                      <div class="article_prop_val">
                        {{ header.created_at }}
                      </div>
                    </div>
                    <div class="article_row" v-if="false">
                      <div class="article_prop_name">Suprafață</div>
                      <div class="article_prop_val">
                        {{ header.total_surface }} ha
                      </div>
                    </div>
                    <div class="article_row">
                      <div class="article_prop_name">Cantitate</div>
                      <div class="article_prop_val">
                        {{ (header.quantity * 1).toFixed(2) }}
                        {{ header.measure_unit.name }}
                      </div>
                    </div>
                    <div class="article_row">
                      <div class="article_prop_name">Total cost / ha</div>
                      <div class="article_prop_val">
                        {{ header.unit_price }} RON
                      </div>
                    </div>
                    <div class="article_row">
                      <div class="article_prop_name">Doză</div>
                      <div class="article_prop_val">
                        {{
                          (header.quantity / header.total_surface).toFixed(2)
                        }}
                        {{ header.measure_unit.name }}/ha
                      </div>
                    </div>
                  </div>
                </div>
              </DxScrollView>
            </div>

          </div>
        </template>
      </DxPopup>
      <DxToast
        ref="addArticleNotification"
        :visible="push_notification.is_visible"
        :message="push_notification.message"
        :type="push_notification.type"
        position="top"
      />
    </div>
  </transition>
</template>

<script>
//import Vue from 'vue'
import { mapActions, mapGetters } from "vuex";
import { v4 } from 'uuid';
import DxPopup from "devextreme-vue/popup";
import { DxBox, DxItem } from "devextreme-vue/box";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxSelectBox from "devextreme-vue/select-box";
import DxNumberBox from "devextreme-vue/number-box";
import DxButton from "devextreme-vue/button";
import DxTabPanel from "devextreme-vue/tab-panel";
import required from "vuelidate/src/validators/required";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import { DxScrollView } from "devextreme-vue/scroll-view";

import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxEditing,
  DxTexts,
  DxForm,
  DxPopup as DxPopupGrid,
  DxRequiredRule,
  DxToolbar
} from "devextreme-vue/data-grid";
import { DxToast } from "devextreme-vue/toast";


export default {
  name: "AddArticle",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
    batchTemplateData: {
      type: Object,
      default: () => {},
    },
    department_uuid: "",
    form_mode: "",
  },
  data() {
    return {
      isGridBoxOpened: false,
      gridBoxValue: [],

      applySteps: [1, 2],
      currentStep: 1,
      currentItem: 1,
      allowSwipe: false,
      articleTypes: [
        { id: 1, name: "Fito", code: "FITO" },
        { id: 2, name: "Fertilizare", code: "FERTILITY" },
        { id: 3, name: "Produsele mele", code: "CUSTOM_PRODUCT" },
      ],
      selectedArticleType: null,
      fitoList: [],
      fertilityList: [],
      measureUnits: [],
      selectedMeasureUnit: null,
      calculate_dose: false,
      calculate_quantity: false,
      calculate_cost: false,
      calculate_unit_price_per_ha: false,
      batch: {
        department_uuid: "",
        company_uuid: "",
        product_type: "",
        product_uuid: "",
        quantity: null,
        dose_per_ha: null,
        measure_unit_uuid: "",
        unit_price: null,
        unit_price_per_ha: null,
        note: "",
        dispersion_type: "amount",
        items: [],
      },
      batch_item: {
        season_on_parcel_has_culture_uuid: "",
        quantity: null,
        measure_unit_uuid: "",
        surface: null,
        is_manual: false,
        note: "",
      },
      push_notification: {
        is_visible: false,
        message: "Selectează tipul de articol!",
        type: "error",
      },
      hasProductsOnFilter: true,
      allowGridAddNewProduct: false,
      fitoProductsLight: [],
      fertilityProducts: [],
      customProducts: [],
      batchSessionUUIDS:[]
    };
  },
  validations() {
    return {
      batch: {
        product_uuid: {
          required,
        },
        measure_unit_uuid: {
          required,
        },
        quantity: {
          required,
        },
        dose_per_ha: {
          required,
        },
        unit_price: {
          required,
        },
        unit_price_per_ha: {
          required,
        }
      },
    };
  },
  watch: {
    "batch.quantity"(newVal) {
      if (this.calculate_quantity == false) {
        this.calculate_dose = true;
        if (newVal > 0) {
          this.batch.dose_per_ha = newVal / this.totalSurface;
        } else {
          this.batch.dose_per_ha = 0;
        }
        this.calculate_dose = false;

        this.calculate_unit_price_per_ha = true;
        this.calculate_cost = true;

        if(newVal > 0){
          this.batch.unit_price_per_ha = this.batch.unit_price / this.batch.quantity;
        }
        else{
          this.batch.unit_price_per_ha = 0;
        }

        this.calculate_unit_price_per_ha = false;
        this.calculate_cost = false;

      }
    },
    "batch.dose_per_ha"(newVal) {
      if (this.calculate_dose == false) {
        this.calculate_quantity = true;
        this.batch.quantity = newVal * this.totalSurface;
        this.calculate_quantity = false;

        this.calculate_unit_price_per_ha = true;
        this.calculate_cost = true;

        if(newVal > 0){
          this.batch.unit_price_per_ha = this.batch.unit_price / this.batch.quantity;
        }
        else{
          this.batch.unit_price_per_ha = 0;
        }

        this.calculate_unit_price_per_ha = false;
        this.calculate_cost = false;

      }
    },
    "batch.unit_price"(newVal) {
      if (this.calculate_cost == false) {
        this.calculate_unit_price_per_ha = true;
        if (newVal > 0) {
          this.batch.unit_price_per_ha = newVal / this.batch.quantity;
        } else {
          this.batch.unit_price_per_ha = 0;
        }
        this.calculate_unit_price_per_ha = false;
      }
    },
    "batch.unit_price_per_ha"(newVal) {
      if (this.calculate_unit_price_per_ha == false) {
        this.calculate_cost = true;
        if (newVal > 0) {
          this.batch.unit_price = newVal * this.batch.quantity;
        } else {
          this.batch.unit_price = 0;
        }
        this.calculate_cost = false;
      }
    },
    "batch.measure_unit_uuid"(newVal){
      if(newVal != null){
        let tmpMU = this.measureUnits.find((obj) => obj.uuid == newVal);
        if(tmpMU != null){
          this.selectedMeasureUnit = tmpMU.name;
        }
      }
      else{
        this.selectedMeasureUnit = null;
      }
    },
    showPopup(newVal) {
      if (newVal == true) {
        this.resetForm();
        this.batchSessionUUIDS = [];
      }
    },
    selectedArticleType(newVal){
      if(newVal != null){
        this.buttonDoneEvent(false);
      }
    },
    isGridBoxOpened(newVal){
      this.isGridBoxOpened = newVal;
      if(this.isGridBoxOpened){
        this.$refs.productGrid.instance.clearFilter();
      }
    },
    hasProductsOnFilter(newVal){
      this.hasProductsOnFilter = newVal;
    }
  },
  computed: {
    ...mapGetters({
      company: "company/company",
      customProductStatuses: "customProductStatus/customProductStatuses"
    }),
    showDoneButton() {
      //return this.currentStep > 0;
      return true;
    },
    showSaveAndNewButton() {
      return this.currentStep == 1;
    },
    allowSwipeForm() {
      return this.selectedArticleType != null;
      //return true;
    },
    selectArticleTypeName() {
      if (this.selectedArticleType && this.selectedArticleType.name) {
        return "Adaugă " + this.selectedArticleType.name;
      }
      return "";
    },
    articlePlaceholder() {
      return "Selectează articol";
    },
    articleDataset() {
      if (this.selectedArticleType && this.selectedArticleType.code == "FITO") {
        return this.fitoProductsLight;
      }
      if (this.selectedArticleType && this.selectedArticleType.code == "FERTILITY") {
        return this.fertilityProducts;
      }
      if (this.selectedArticleType && this.selectedArticleType.code == "CUSTOM_PRODUCT") {
        return this.customProducts;
      }
      return null;
    },
    articleDoseFormat() {
      if (this.selectedMeasureUnit) {
         return "#0.## " + this.selectedMeasureUnit + "/ha";
        //return "#0.##";
      }
      return "#0.##";
    },
    unitPriceFormat(){
      if (this.selectedMeasureUnit) {
         return "#,##0.## RON/" + this.selectedMeasureUnit;
        //return "#0.##";
      }
      return "#,##0.## RON";
    },
    articleQuantityFormat() {
      if (this.selectedMeasureUnit) {
        return "#0.## " + this.selectedMeasureUnit;
      }
      return "#0.##";
    },
    doneButtonText() {
      if (this.currentStep == 0) {
        return "Înainte";
      }
      return "Salvează";
    },
    cancelButtonText() {
      return "Renunță";
    },
    totalSurface() {
      if (
        this.templateData &&
        this.templateData.selected_parcels &&
        this.templateData.selected_parcels.length > 0
      ) {
        let sumSurface = 0;
        this.templateData.selected_parcels.forEach((item) => {
          sumSurface += item.surface_worked;
        });
        return sumSurface % 1 > 0 ? sumSurface.toFixed(2) : sumSurface;
      }
      else if (
        this.templateData &&
        this.templateData.works_after_insert &&
        this.templateData.works_after_insert.length > 0
      ) {
        let sumSurface = 0;
        this.templateData.works_after_insert.forEach((item) => {
          sumSurface += parseFloat(item.net_surface_worked);
        });
        return sumSurface % 1 > 0 ? sumSurface.toFixed(2) : sumSurface;
      }
      return 0;
    },
    totalSurfaceText(){
      return this.totalSurface + (this.totalSurface <= 1 ? " hectar selectat" : " hectare selectate");
    }
  },
  components: {
    DxPopup,
    DxBox,
    DxItem,
    DxRadioGroup,
    DxSelectBox,
    DxNumberBox,
    DxButton,
    DxTabPanel,
    DxDropDownBox,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxToast,
    DxScrollView,
    DxEditing,
    DxColumn,
    DxTexts,
    DxForm,
    DxPopupGrid,
    DxRequiredRule,
    DxToolbar,
  },
  methods: {
    ...mapActions({
      getFitoProducts: "fitoProduct/getFitoProductsLight",
      getFertilityProducts: "fertilityProduct/getFertilityProductsLight",
      createBatch: "batch/createBatch",
      getBatchMeasureUnits: "batch/getBatchMeasureUnits",
      addCustomProduct: "customProduct/addCustomProduct",
      getCustomProducts: "customProduct/getCustomProducts",
      getCustomProductStatuses: "customProductStatus/getCustomProductStatuses",
    }),
    onGridSelectionChanged(e) {
      this.gridBoxValue = e.currentSelectedRowKeys;
      this.isGridBoxOpened = false;
    },
    onGridContentReady(e){
      if(e.component.getVisibleRows().length <= 0){
        this.allowGridAddNewProduct = true;
      }
      else{
        this.allowGridAddNewProduct = false;
      }

    },
    initNewProduct(e){
      let gridFilter = e.component.getCombinedFilter();
      e.data.name = gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";

    },
    productInserting(e){
      // let reviewStatus = this.customProductStatuses.find((obj) => obj.code == "REVIEW");
      // let reviewStatusUUID = reviewStatus ? reviewStatus.uuid : null;
      // this.addCustomProduct({
      //   name: e.data.name,
      //   source_type: this.selectedArticleType.code,
      //   /*status_type_uuid: reviewStatusUUID*/})
      //   .then((resp) => {
      //     this.batch.product_uuid = resp.data.uuid;
      //   }).catch((err) => {
      //     e.cancel = true;
      //   });

    },
    addCustomProductInstant(){
      let gridFilter = this.$refs.productGrid.instance.getCombinedFilter();
      let prodName  = gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";

      let newProd = {uuid:"CP_" + v4(), name: prodName};
      if(this.selectedArticleType.code == "FITO"){
        this.fitoProductsLight.push(newProd)
      }
      if(this.selectedArticleType.code == "FERTILITY"){
        this.fertilityProducts.push(newProd)
      }
      if(this.selectedArticleType.code == "CUSTOM_PRODUCT"){
        this.customProducts.push(newProd)
      }

      this.$refs.productSelectBox.instance.close();
      this.gridBoxValue = [];
      this.gridBoxValue.push(newProd);
      this.batch.product_uuid = newProd.uuid;
      this.batch.product_name = newProd.name;

      let reviewStatus = this.customProductStatuses.find((obj) => obj.code == "REVIEW");
      let reviewStatusUUID = reviewStatus ? reviewStatus.uuid : null;
      this.batch.product_status_type_uuid = reviewStatusUUID;

    },
    onProductContentReady(e){

    },
    gridBoxDisplayExpr(item) {
      if (item) {
        this.batch.product_uuid = item.uuid;
      }
      return item && item.name;
    },

    tabSelectionChanged(e) {
      this.currentItem = e.addedItems[0];
      this.currentStep = this.applySteps.indexOf(this.currentItem);
    },
    checkIfBatchIsInSession(batch_uuid){
      return this.batchSessionUUIDS.find(obj => obj == batch_uuid)
    },
    resetForm() {
      this.$v.$reset();

      this.selectedArticleType = null;
      if (this.form_mode == "new") {
        this.currentStep = 0;
      }
      if (this.form_mode == "edit") {
        this.currentStep = 0;
      }

      this.batch.product_type = "";
      this.batch.product_uuid = "";
      this.batch.quantity = null;
      this.batch.dose_per_ha = null;
      this.batch.measure_unit_uuid = "";
      this.batch.unit_price = null;
      this.batch.unit_price_per_ha = null;
      this.batch.note = "";
      this.batch.dispersion_type = "amount";
      this.batch.items = [];
    },
    changeArticle(e) {
      this.selectedArticle = e;
    },
    refreshBatches() {
      this.resetForm();
      this.$emit("collectBatchHeaders");
    },
    buttonDoneEvent(closeDialog) {
      if (this.selectedArticleType == null) {
        this.$refs.addArticleNotification.instance.show();
        return null;
      }
      if (
        this.selectedArticleType &&
        this.selectedArticleType.id > 0 &&
        this.currentStep == 0 &&
        this.form_mode == "new"
      ) {
        this.currentStep = 1;
      } else {
        this.$v.batch.$touch();
        if (!this.$v.batch.$invalid) {
          this.batch.company_uuid = this.company.uuid;
          this.batch.department_uuid = this.department_uuid;
          this.batch.items = [];
          this.batch.product_type = this.selectedArticleType.code;
          if(this.batch.product_uuid.indexOf("CP_") == 0){
            this.batch.product_uuid = null;
          }
          if (
            this.templateData &&
            this.templateData.selected_parcels &&
            this.templateData.selected_parcels.length > 0
          ) {
            this.templateData.selected_parcels.forEach((parcel) => {
              if (parcel.spc_items_info && parcel.spc_items_info.length > 0) {
                parcel.spc_items_info.forEach((spc) => {
                  this.batch.items.push({
                    season_on_parcel_has_culture_uuid: spc.uuid,
                    quantity: 0,
                    measure_unit_uuid: this.batch.measure_unit_uuid,
                    surface: spc.net_surface_worked,
                    is_manual: 0,
                    note: "",
                  });
                });
              }
            });
          }
          //works_after_insert
          if (
            this.templateData &&
            this.templateData.works_after_insert &&
            this.templateData.works_after_insert.length > 0
          ) {
            this.templateData.works_after_insert.forEach(w => {
              this.batch.items.push({
                    season_on_parcel_has_culture_uuid: w.uuid,
                    quantity: 0,
                    measure_unit_uuid: this.batch.measure_unit_uuid,
                    surface: w.net_surface_worked,
                    is_manual: 0,
                    note: "",
                  });
            });
          }

          this.createBatch(this.batch).then((response) => {
            if (closeDialog == true) {
              this.$emit("hideAfterSave", response.data.works);
            } else {
              this.batchSessionUUIDS.push(response.data.batch_uuid);
              this.resetForm();
              this.$emit("collectBatchHeaders");
            }
          });
        }
      }
    },
    headerButtonEvent() {
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
        this.selectedArticleType = null;
        if(this.currentStep == 0){
          this.resetForm();
        }
      }
    },
    buttonCancelEvent() {
      this.$emit("hidden");
    },
  },
  mounted() {
    this.getCustomProductStatuses({department_uuid: this.department_uuid})
    this.getFitoProducts({ pagination: 0, resource_type: "light" }).then((response) => {
      this.fitoProductsLight = response;
    });
    this.getFertilityProducts({ pagination: 0, resource_type: "light" }).then((response) => {
      this.fertilityProducts = response;
    });
    this.getCustomProducts({ }).then((response) => {
      this.customProducts = response;
    });

    this.getBatchMeasureUnits({ department_uuid: this.department_uuid }).then(
      (result) => {
        this.measureUnits = result;
      }
    );
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/digitalAgenda/daAddArticle.scss";
</style>

<template>
    <DxTabPanel :element-attr="tabPanelAttributes" :data-source="receptionSteps" :loop="false"
        :selected-index="currentStep" :animation-enabled="true" :swipe-enabled="false" :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)">
        <template #item="{ data: step }">
            <div>
                <div v-if="step == 1" class="filter_container" :use-native="true">
                    <DxScrollView ref="receptionInputScroll" v-show="step == 1" class="reception-input-scroll"
                        :use-native="true">
                        <div class="reception_input_holder" :class="[
                            { input_holder_error: $v.receptionData.reception_date.$error },
                        ]">
                            <div @click="showCalendarSelector">
                                <div class="input_label">Data</div>
                                <div class="input_value">
                                    <span>{{ receptionData.reception_date }}</span>
                                </div>
                            </div>
                            <div v-if="is_date_selector_visible">
                                <FunctionalCalendar :date-format="getDefaultDateFormat.toLowerCase()"
                                    :is-date-range="false" v-model="calendarData" :change-month-function="true"
                                    :change-year-function="true" :is-date-picker="true" @choseDay="calendarDayChosen"
                                    @input="onDateSelected"></FunctionalCalendar>
                            </div>
                        </div>
                        <div class="reception_input_holder consum_tabs" @click="showProductSelector()" 
                        :class="[{ input_holder_error: $v.receptionData.products.$error }]">
                            <div class="reception_parcel_select_edit">
                                <div class="reception_parcel_info">
                                    <div class="input_label">Produs</div>
                                    <div v-if="receptionData.products.length > 0" class="input_value">{{ getSelectedProduse() }}</div>
                                    <div v-else class="input_value_placeholder" style="color: #989696 !important;">Alegeți produs</div>
                                    <div class="reception_input_second_value">{{ receptionData.products.length > 2 ? '+' + (receptionData.products.length - 2) + ' produse' : '' }}</div>
                                </div>
                                <div class="reception_parcel_edit_icon">
                                    <i class="fa-regular fa-edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="reception_input_holder" :class="[
                            { input_holder_error: $v.receptionData.partner.uuid.$error },
                        ]">
                            <div class="input_label">Furnizor</div>
                            <div>
                                <dx-combo-with-add ref="partnerComboWithAdd" @addNewItemInstant="addNewPartnerInstant"
                                    @selectionChanged="partnerSelected" addNewText="Furnizorul nu există"
                                    :comboDataset="partners" placeholder="Alegeți furnizor"
                                    :isComboDisabled="false">
                                </dx-combo-with-add>
                            </div>
                        </div>
                        <div class="reception_input_holder" v-show="showCUIForPartner" :class="[
                            { input_holder_error: $v.receptionData.partner.cui.$error },
                        ]">
                            <div class="input_label">Cod unic de înregistrare</div>
                            <div class="input_value">
                                <DxTextBox placeholder="Introduceți CUI" v-model="receptionData.partner.cui"
                                    class="global_dx_texbox" />
                            </div>
                        </div>
                        <div class="reception_input_holder" :class="[
                            { input_holder_error: $v.receptionData.warehouse.uuid.$error },
                        ]">
                            <div class="input_label">Depozit</div>
                            <div class="input_value">
                                <DxSelectBox v-model="receptionData.warehouse.uuid" :search-enabled="false"
                                    search-mode="contains" search-expr="name" class="input_value dx_input_value"
                                    placeholder="Alegeți depozit" :data-source="warehouses" display-expr="name"
                                    value-expr="uuid" :show-clear-button="false" drop-down-button-template="imageIcon"
                                    :disabled="false">
                                    <template #imageIcon>
                                        <div>
                                            <div class="dx_combo_open_button" v-show="!receptionData.warehouse.uuid ||
                                                receptionData.warehouse.uuid == ''
                                                ">
                                                <img src="@/assets/images/icons/plus_green.svg"
                                                    class="custom-selector-icon" />
                                            </div>
                                            <div class="dx_combo_open_button" v-show="receptionData.warehouse.uuid &&
                                                receptionData.warehouse.uuid.length > 0
                                                ">
                                                <img src="@/assets/images/icons/edit.svg"
                                                    class="custom-selector-icon" />
                                            </div>
                                        </div>
                                    </template>
                                    <template #item="{ data }">
                                        <div class="dx_custom_combo_item">{{ data.name }}</div>
                                    </template>
                                </DxSelectBox>
                            </div>
                        </div>
                        <div class="reception_input_holder">
                            <div class="input_label">Nr. aviz de însoţire a mărfii</div>
                            <div class="input_value">
                                <DxTextBox
                                    v-model="receptionData.shipping_note_number"
                                    placeholder="Introduceți nr. aviz de însoţire a mărfii" class="global_dx_texbox" />
                            </div>
                        </div>
                        <div class="input-bottom-spacer"></div>
                    </DxScrollView>
                </div>
                <div v-if="false" v-show="step == 2" class="scanner_area">
                    <div>Funcția este în curs de dezvoltare!</div>
                    <br />
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader><br />
                    <div>Codul scanat:</div>
                    <b></b>
                    <div>{{ scannedCode }}</div>
                </div>
                <div class="reception_bottom_sticky_two_line" v-show="step == 1 && showSaveButtons">
                    <div style="display: flex; flex-direction: row; width: 100%">
                        <div class="sticky_action_button" style="margin-right: 6px" @click="saveReception(true, false)">
                            Salvează și recepție nouă
                        </div>
                        <div class="sticky_action_button" style="margin-left: 6px" @click="saveReception(false, true)">
                            Salvează și consum
                        </div>
                    </div>
                    <div class="sticky_action_button_second" @click="saveReception(false, false)">
                        Salvează
                    </div>
                </div>
                <ProductSelector ref="productSelector" :showPopup="productSelectorVisibility"
                    @hidden="hideProductSelector" :selectedProducts="receptionData.products">
                </ProductSelector>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>
    </DxTabPanel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DxTabPanel from "devextreme-vue/tab-panel";
import { DetailCodes, InputDetailCodes } from "./reception.js";
import { required, helpers } from "vuelidate/src/validators";
import DxTextBox from "devextreme-vue/text-box";
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import { v4 } from "uuid";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxSelectBox from "devextreme-vue/select-box";
import ToastMessage from "@/components/Mobile/toastMessage.vue";
import DxTextArea from "devextreme-vue/text-area";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";
import ProductSelector from "../Product/ProductSelector.vue";

const requiredByParam = (param) => (value) => {
    if (param) {
        return helpers.req(value) && value.length > 0;
    }
    return true;
};

export default {
    name: "Reception",
    data() {
        return {
            preselectDate: true,
            calendarData: {},
            receptionSteps: [1, 2, 3],
            currentStep: 0,
            currentItem: 1,
            tabPanelAttributes: {
                class: "reception_tabs",
            },
            detailStepCode: "",
            detailInputStepCode: "",
            showCUIForPartner: false,
            newPartner: {
                uuid: "",
                name: "",
                cui: "",
                partner_type: "",
            },
            receptionData: {
                products: [],
                partner: {
                    uuid: "",
                    partner_type: "",
                    name: "",
                    cui: "",
                },
                warehouse: {
                    uuid: "",
                    name: "",
                },
                reception_date: null,
                shipping_note_number: null,
            },
            partners: [],
            warehouses: [],
            scannedCode: null,
            is_date_selector_visible: false,
            productSelectorVisibility: false,
        };
    },
    computed: {
        ...mapGetters({
            getDefaultDateFormat: "settings/getDefaultDateFormat",
        }),
        showSaveButtons() {
            return (
                this.receptionData.products.length > 0 &&
                (this.receptionData.partner.uuid &&
                    this.receptionData.partner.uuid.length > 0) &&
                (this.receptionData.warehouse.uuid &&
                    this.receptionData.warehouse.uuid.length > 0)
            );
        },
        detailCodes() {
            return DetailCodes;
        },
        inputDetailCodes() {
            return InputDetailCodes;
        },
    },
    validations() {
        return {
            receptionData: {
                reception_date: { required },
                products: { required },
                partner: {
                    uuid: { required },
                    cui: { requiredByParam: requiredByParam(this.showCUIForPartner) },
                },
                warehouse: {
                    uuid: { required },
                },
                reception_date: { required },
            },
        };
    },
    components: {
        DxTabPanel,
        DxTextBox,
        DxComboWithAdd,
        DxScrollView,
        DxSelectBox,
        ToastMessage,
        DxTextArea,
        StreamBarcodeReader,
        FunctionalCalendar,
        ProductSelector,
    },
    methods: {
        ...mapActions({
            getPartners: "smPartners/getSmPartners",
            getPartnerTypes: "smPartners/getSmPartnerTypes",
            getWarehouses: "smWarehouses/getSmWarehouses",
            createSmProduct: "smProducts/createSmProduct",
            createReception: "smTransactions/createReceptionTransaction",
            getGroupByCode: "settings/getGroupByCode",
            checkReceptionShippingNumberAlreadyExists:
                "smTransactions/checkReceptionShippingNumberAlreadyExists",
        }),

        onLoaded() { },
        onDecode(result) {
            this.scannedCode = result;
        },
        tabSelectionChanged(e) {
            this.currentItem = e.addedItems[0];
            this.currentStep = this.receptionSteps.indexOf(this.currentItem);
            this.changeAddButtonVisibility();
        },
        goBack() {
            if (this.currentStep > 0) {
                this.currentStep = this.currentStep - 1;
                this.setTitle();
            } else {
                this.$emit("goBackParent");
            }
        },
        setTitle() {
            if (this.currentStep == 0) {
                this.$emit("setTitle", "Recepție produs", true);
            } else if (this.currentStep == 1) {
                this.$emit("setTitle", "Listă produse", false);
            }
        },
        changeAddButtonVisibility() {
            //this.$emit('changeAddButtonVisibility', this.currentStep == 1 && this.detailStepCode != this.detailCodes.Warehouse);
            this.$emit("changeAddButtonVisibility", false);
        },
        getSelectedProduse() {
            if(this.receptionData.products.length > 0){
                if (this.receptionData.products.length === 1) {
                    return this.receptionData.products[0].name;
                } else if (this.receptionData.products.length > 1) {
                    return this.receptionData.products[0].name + ', ' + this.receptionData.products[1].name;
                }
            } else {
                return null;
            }
        },
        saveReception(saveAndNew, startConsum) {
            this.checkShippingNumberAlreadyExist(() => {
                let saveWithNewPartner = false;
                this.$v.receptionData.$touch();
                if (!this.$v.receptionData.$invalid) {
                    let receptionForSave = {
                        department_uuid: this.getWarehouseDepartmentByWarehouseUuid(
                            this.receptionData.warehouse.uuid
                        ),
                        warehouse_uuid: this.receptionData.warehouse.uuid,
                        products: this.receptionData.products,
                        reception_date: this.receptionData.reception_date,
                        shipping_note_number: this.receptionData.shipping_note_number,
                    };

                    // remove NEW_ prefix from product uuids for new products
                    receptionForSave.products.forEach(product => {
                        if (product.uuid.startsWith("NEW_")) {
                            product.uuid = "";
                        }
                        product.stock_list = undefined;
                        product.updated_by = undefined;
                        product.partner = undefined;
                        product.created_by = undefined;
                        product.product_type = undefined;
                        product.measure_unit = undefined;
                    });

                    //--------------------------------------------------------------------------------
                    let partnerForSave = {
                        uuid: "",
                        partner_type: "SRL",
                        name: this.receptionData.partner.name,
                        id_number: this.receptionData.partner.cui,
                    };
                    if (!this.receptionData.partner.uuid.startsWith("NEW_")) {
                        partnerForSave.uuid = this.receptionData.partner.uuid;
                        partnerForSave.partner_type = this.receptionData.partner.partner_type;
                    } else {
                        saveWithNewPartner = true;
                    }
                    receptionForSave.partner = partnerForSave;
                    //--------------------------------------------------------------------------------

                    this.createReception(receptionForSave)
                        .then((response) => {
                            this.$refs.toastMessage.showSuccess(
                                "Recepția a fost salvată cu succes!"
                            );
                            if (saveAndNew && response.data.length > 0) {
                                if (saveWithNewPartner) {
                                    this.partners.forEach((prt) => {
                                        if (prt.uuid == this.receptionData.partner.uuid) {
                                            prt.uuid = response.data[0].product.partner.uuid;
                                            prt.name = response.data[0].product.partner.name;
                                            prt.id_number = response.data[0].product.partner.id_number;
                                            prt.partner_type = response.data[0].product.partner.partner_type;

                                            this.$refs.partnerComboWithAdd.updateWithNewItem(prt);
                                        }
                                    });
                                }

                                this.resetForm(true);
                            } else if (startConsum) {
                                let dataForConsum = {
                                    ...this.receptionData
                                }

                                const responseDataMap = response.data.reduce((map, item) => {
                                map[item.product.uuid] = item.product;
                                return map;
                                }, {});

                                dataForConsum.products = this.receptionData.products.map((product) => {
                                    const productData = responseDataMap[product.uuid] || {};
                                    return {
                                        uuid: product.uuid,
                                        name: product.name,
                                        quantity: product.quantity,
                                        measure_unit: productData.measure_unit,
                                        product_type: productData.product_type,
                                        stock_list: productData.stock_list,
                                    };
                                });

                                dataForConsum.warehouse_department_legal_entity_uuid = this.getWarehouseDepartmentByWarehouseUuid(
                                    this.receptionData.warehouse.uuid, true
                                );

                                this.$emit("startConsumFromRecpetion", dataForConsum);
                            } else {
                                this.closeTransactions("Recepția a fost salvată cu succes!");
                            }
                        })
                        .catch((e) => {
                            console.log("reception error ", e);
                            this.$refs.toastMessage.showError(
                                "A apărut o erroare la salvarea recepției. Contactați administratorul de sistem!"
                            );
                        });
                } else {
                    this.$refs.toastMessage.showError(this.createErrormMssages());
                }
            });
        },
        checkShippingNumberAlreadyExist(callback) {
            if (
                this.receptionData.shipping_note_number &&
                this.receptionData.shipping_note_number.length > 0
            ) {
                this.checkReceptionShippingNumberAlreadyExists({
                    shipping_note_number: this.receptionData.shipping_note_number,
                }).then((response) => {
                    if (
                        response.is_exists /*&&
                        response.batch_uuid != this.receptionData.batch_uuid*/
                    ) {
                        this.$dialog
                            .confirm(
                                "Numărul avizului de însoțire a mărfii există deja. Doriți să continuați?",
                                {
                                    okText: "Da",
                                    cancelText: "Nu",
                                }
                            )
                            .then((dialog) => {
                                dialog.close();
                                /*
                                const responseDate = new Date(response.transaction_date);
                                const receptionDate = new Date(
                                    this.receptionData?.reception_date
                                );
                                if (
                                    response.batch_uuid &&
                                    response.batch_uuid.length > 0 &&
                                    responseDate.toDateString() ===
                                    receptionDate.toDateString() &&
                                    response.partner_uuid == this.receptionData?.partner?.uuid &&
                                    response.warehouse_uuid == this.receptionData?.warehouse?.uuid
                                ) {
                                    this.receptionData.batch_uuid = response.batch_uuid;
                                }
                                */
                                callback();
                            })
                            .catch(() => {
                                // Handle cancel
                            });
                    } else {
                        callback();
                    }
                });
            } else {
                callback();
            }
        },
        createErrormMssages() {
            let errorMessages = [];

            if (this.receptionData.products.length == 0) {
                errorMessages.push("Produsul este obligatoriu!");
            }

            this.receptionData.products.forEach((product, index) => {
                if (!product.measure_unit_id) {
                    errorMessages.push(`Unitatea de măsură este obligatorie (${product.name})!`);
                }
                if (!product.product_type_uuid) {
                    errorMessages.push(`Tipul de produs este obligatoriu (${product.name})!`);
                }
                /*if (!product.code) {
                    errorMessages.push(`Codul intern al produsului este obligatoriu (${product.name})!`);
                }*/
                if ((product.quantity ?? 0) <= 0) {
                    errorMessages.push(`Cantitatea ${product.name} trebuie să fie mai mare de 0!`);
                }
                if (!((product.unit_price ?? -1) >= 0)) {
                    errorMessages.push(`Prețul unitar ${product.name} trebuie să fie egal sau mai mare de 0!`);
                }
            });

            if (errorMessages.length > 0) {
                return errorMessages;
            }

            if (this.$v.receptionData.reception_date.$invalid) {
                errorMessages.push("Data recepției este obligatorie!");
            }
            if (this.$v.receptionData.partner.uuid.$invalid) {
                errorMessages.push("Furnizorul este obligatoriu!");
            }
            if (this.$v.receptionData.partner.cui.$invalid) {
                errorMessages.push("Codul unic de înregisrare este obligatoriu!");
            }
            if (this.$v.receptionData.warehouse.uuid.$invalid) {
                errorMessages.push("Depozitul este obligatoriu!");
            }
            return errorMessages;
        },
        goForward() {
            this.currentStep = this.currentStep + 1;
            this.setTitle();
        },
        showProductSelector() {
            this.productSelectorVisibility = true;
        },
        hideProductSelector() {
            this.productSelectorVisibility = false;
        },
        addNewPartnerInstant(partnerName) {
            let _newPartner = { uuid: "NEW_" + v4(), name: partnerName };
            this.partners.push(_newPartner);
            this.$refs.partnerComboWithAdd.updateWithNewItem(_newPartner);
        },
        partnerSelected(selectedPartner) {
            if (selectedPartner && selectedPartner.length > 0 && selectedPartner[0]) {
                this.showCUIForPartner = selectedPartner[0].uuid.startsWith("NEW_");
                this.receptionData.partner.uuid = selectedPartner[0].uuid;
                this.receptionData.partner.name = selectedPartner[0].name;
                this.receptionData.partner.cui = selectedPartner[0].id_number;
                this.receptionData.partner.partner_type =
                    selectedPartner[0].partner_type;
            }
        },
        resetForm(fullreset) {
            this.$v.$reset();
            this.receptionData = {
                products: [],
                partner: {
                    uuid: "",
                    partner_type: "",
                    name: "",
                    cui: "",
                },
                warehouse: {
                    uuid: "",
                    name: "",
                },
                reception_date: this.receptionData.reception_date,
                shipping_note_number: null,
            };

            if (fullreset) {
                this.showCUIForPartner = false;
            }

            this.$refs.partnerComboWithAdd.resetControl();

            this.$refs["receptionInputScroll"].instance.scrollTo(0);
        },
        selectWarehouse() {
            if (this.warehouses.length == 1) {
                this.receptionData.warehouse.uuid = this.warehouses[0].uuid;
                this.receptionData.warehouse.name = this.warehouses[0].name;
            }
        },
        closeTransactions(message) {
            this.$emit("closeTransactions", message);
        },
        showCalendarSelector() {
            /*if (
                this.receptionData.batch_uuid &&
                !!this.receptionData.reception_date
            ) {
                this.is_date_selector_visible = false;
                return;
            }*/
            this.is_date_selector_visible = !this.is_date_selector_visible;
        },
        calendarDayChosen(data) {
            this.is_date_selector_visible = false;
        },
        onDateSelected(date) {
            this.receptionData.reception_date = date.selectedDate;
        },
        getWarehouseDepartmentByWarehouseUuid(warehouseUuid, search_for_legal_entity_uuid = false) {
            let warehouse = this.warehouses.find((w) => w.uuid == warehouseUuid);
            if (warehouse) {
                return search_for_legal_entity_uuid ? warehouse.legal_entity_uuid : warehouse.department_uuid;
            }
            return null;
        },
    },
    mounted() {
        this.getGroupByCode({
            code: "STOCK_MANAGEMENT",
        }).then((response) => {
            let _setting = response.items.find(
                (obj) => obj.code == "USE_CURRENT_DATE_ON_CREATION"
            );
            this.preselectDate = _setting?.value == "1";
            if (this.preselectDate) {
                this.receptionData.reception_date = moment().format(
                    this.getDefaultDateFormat
                );
            }
        });

        this.setTitle();
        this.getWarehouses({}).then((response) => {
            this.warehouses = response.data;
            this.selectWarehouse();
        });
        this.getPartners({ enablePagination: false }).then((response) => {
            this.partners = response.data;
        });
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/stockMobile/reception.scss";
</style>

<template>
  <div>
  <div>
  <img v-if="header.cancelDocument && !data.canceled_at"
    :src="require('@/assets/images/desktop/icons/controls/delete.svg')"
    :class="['edit', 'secondary-filter']"
    :title="'Cancel ' + data.type_name.toLowerCase()"
    class="action-icon"
    @click="confirmCancel"
    width="25"
    height="25"
    style="cursor: pointer;" />
    <div v-else-if="exported_data">
      <pdf-download-col :data="exported_data" :header="header" ></pdf-download-col>
    </div>

    <div v-else-if="(!header.cancelDocument && !!data.export_url) || (header.cancelDocument && !!data.document && !!data.cancel_export_url)">
      <img
          :src="require('@/assets/images/icons/transfer.svg')"
          :class="['edit', 'primary-filter']"
          :title="name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()"
          class="action-icon"
          @click="onClick"
          width="25"
          height="25"
          style="cursor: pointer;" />
    </div>

    <div v-else style="width: 25px;"></div>
  </div>
    <DeleteConfirmationDialog
      :show="showDeleteConfirmationModal"
      @close="showDeleteConfirmationModal = false"
      @confirm="onClickCancelTransaction"
      :title="$t('stock_management.title')"
      :message="$t('stock_management.transactions.delete_question')"
    ></DeleteConfirmationDialog>
  </div>
</template>

<script>
import { errorMessage } from "~/helpers/common";
import PdfDownloadCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfDownloadCol.vue";
import {mapActions, mapGetters} from 'vuex'
import { errorMessageList } from "~/helpers/common";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";

export default {
  components: { PdfDownloadCol, DeleteConfirmationDialog },
  props: ['data', 'header'], //transaction
  name: 'PdfExportCol',
  data() {
    return {
      exported_data: null,
      showDeleteConfirmationModal: false,
    }
  },
  computed: {
    ...mapGetters({
        transactions: 'smTransactions/smTransactions',
    }),
    name() {
      return this.header.cancelDocument ? this.$t('stock_management.transactions.generate_cancel_pdf', { type: this.data.type_name }) : this.$t('stock_management.transactions.generate_pdf', { type: this.data.type_name });
    },
  },
  methods: {
    ...mapActions({
      cancelSmTransaction: 'smTransactions/cancelSmTransaction',
    }),
    //export and info
    onClick() {
      let vm = this;
      this.$store.dispatch('smTransactions/getExportInfo', {
        type: this.data.transaction_type,
        url: this.header.cancelDocument ? this.data.cancel_export_url : this.data.export_url,
        response_type: 'application/json'
      }).then((resp) => {
        vm.exported_data = resp.data.transaction;

        this.updateBatchItems(resp.data.transaction, this.header.cancelDocument);

        this.$store.dispatch('smTransactions/downloadPdf', {
          type: resp.data.transaction_type,
          url: this.header.cancelDocument ? resp.data.transaction.cancel_download_url : resp.data.download_url,
          filename: this.header.cancelDocument ? resp.data.transaction.cancel_download_filename : resp.data.download_filename,
          response_type: 'blob'
        });
      }).catch((e) => {
        console.log('check export error: ', e);
        if (e.response.status === 400 || e.response.status === 500) {
          if (e.response.data.errors && e.response.data.errors.error) {
            errorMessage(vm, e.response.data.errors.error);
          } else {
            errorMessage(vm, vm.$t('error_alert_text'));
          }
        }
      }); //after?
    },
    // documentum generálás után frissíteni kell az összes azonos batch uuid-val rendelkező tranzakciót
    updateBatchItems(transaction, is_cancel = false) {
      const vm = this;

      if(is_cancel) {
        // ha stornó dokumentumot hozunk létre, akkor csak az adott tranzakcióra vonatkozik más batc uuid-val rendelkező tranzakciókat nem érinti
        let updated_res = vm.transactions.filter(t => t.uuid === transaction.uuid);
        if(updated_res && updated_res.length > 0) {
          let updated = updated_res[0];
          updated.cancel_document = transaction.cancel_document;
          updated.cancel_document_uuid = transaction.cancel_document_uuid;
          updated.canceled_at = transaction.canceled_at;
          updated.cancel_export_url = transaction.cancel_export_url;
          updated.cancel_download_url = transaction.cancel_download_url;
          updated.cancel_download_filename = transaction.cancel_download_filename;

          vm.transactions.splice(
                vm.transactions.indexOf(
                    vm.transactions.find(it => it.uuid === updated.uuid)
                ), updated
            );

          vm.$store.commit('smTransactions/SET_SM_TRANSACTIONS', vm.transactions);
        }
      } else if (transaction.batch_uuid) {
        // ha nem stornó új dokumentumot generálunk, akkor az összes azonos batch uuid-val rendelkező tranzakciót frissíteni kell ami nem lett még törölve
        let batchTransactions = vm.transactions.filter(t => t.batch_uuid === transaction.batch_uuid  && !t.canceled_at);
        if (batchTransactions) {
          batchTransactions.forEach((item) => {
            let updated = item;

            updated.document = transaction.document;
            updated.document_block = transaction.document_block;
            updated.document_block_uuid = transaction.document_block_uuid;
            updated.document_id = transaction.document_id;
            updated.download_filename = transaction.download_filename;
            updated.download_url = transaction.download_url;
            updated.export_url = transaction.export_url;

            vm.transactions.splice(
                vm.transactions.indexOf(
                    vm.transactions.find(it => it.uuid === item.uuid)
                ), updated
            );

            vm.$store.commit('smTransactions/SET_SM_TRANSACTIONS', vm.transactions);
          })
        }
      }
    },
    deleteDocumentByBatch(transaction) {
      // ha olyat töröltünk aminek volt documentje és batch uuid-ja, akkor az összes többi tranzakcióótól kiszedjük a documentet és a document_uuid-t ahol azonos a batch_uuid
      // mert azok már nem érvényesek és tudjon újat generálni
      const vm = this;
      if (transaction.batch_uuid && transaction.document) {
        let batchTransactions = vm.transactions.filter(t => (t.batch_uuid === transaction.batch_uuid && t.uuid != transaction.uuid && !t.canceled_at));
        if (batchTransactions) {
          batchTransactions.forEach((update_item) => {
            update_item.document = null;
            update_item.document_uuid = null;
            vm.transactions.splice(
                vm.transactions.indexOf(
                    vm.transactions.find(it => it.uuid === update_item.uuid)
                ), update_item
            );
            vm.$store.commit('smTransactions/SET_SM_TRANSACTIONS', vm.transactions);
          })
        }
      }
    },
    async confirmCancel() {
      if (this.header.cancelDocument && !this.data.canceled_at) {
        this.showDeleteConfirmationModal = true;
      }
    },
    //cancel - generate export document (only export, no download)
    onClickCancelTransaction() {
      this.showDeleteConfirmationModal = false;
      let vm = this;
      if (this.header.cancelDocument && !this.data.canceled_at) {
        this.cancelSmTransaction({
          uuid: this.data.uuid,
        }).then((response) => {
          // mindig adott transaction-re vonatkozik a törlés, nem batch-re jelenleg
          this.data.canceled_at = response.data.canceled_at;
          this.data.cancel_export_url = response.data.cancel_export_url;
          this.deleteDocumentByBatch(this.data);
        }).catch((e) => {
          console.log('onClickCancelTransaction exception', e);
          if (e?.response?.status === 400 || e?.response?.status === 500) {
            if (e.response.data.errors && e.response.data.errors.error) {
              errorMessage(vm, e.response.data.errors.error);
            } else {
              errorMessageList(vm, e.errors);
            }
          }
        });
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="filter_header_container">
      <div class="filter_header">Produse</div>
      <div class="new_item_add_button" v-show="allowGridAddNewProduct" @click="addNewItemInstant">
        <i class="fas fa-plus"></i>
      </div>
    </div>
    <DxScrollView :use-native="true" class="stock_wrapper">
      <DxDataGrid :element-attr="gridAttributes" ref="productGrid" class="product_grid" :data-source="dataSource"
        :no-data-text="addNewItemInfoText" :show-column-headers="false" :hover-state-enabled="true"
        :remote-operations="isRemoteOperation" :repaint-changes-only="true" :selected-row-keys="gridBoxValue"
        @selection-changed="onGridSelectionChanged($event)" @content-ready="onGridContentReady($event)" height="100%"
        :columns="dataColumns">
        <DxSelection mode="single" />
        <DxFilterRow :visible="true" />
        <DxScrolling mode="infinite" />
        <DxGrouping :autoExpandAll="true" />
        <DxGroupPanel :visible="false" />
      </DxDataGrid>
    </DxScrollView>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxFilterRow,
  DxScrolling,
  DxGrouping,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { v4 } from "uuid";

export default {
  name: "ProductList",
  props: {
    addNewText: {
      type: String,
      default: () => "Produsul nu există",
    },
    allowAddNew: {
      type: Boolean,
      default: () => true,
    },
    localDataset: {
      type: Array,
      default: () => [],
    },
    dataColumns: {
      type: Array,
      default: () => [{ dataField: "name", caption: "Denumire" }],
    },
    hasError: false,
    actionMethod: Function | null,
  },
  data() {
    return {
      gridBoxValue: [],
      allowGridAddNewProduct: false,
      gridAttributes: {
        class: "grid_drop_down_with_add",
      },
      selectedItem: null,
    };
  },
  components: {
    DxScrollView,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxFilterRow,
    DxScrolling,
    DxGrouping,
    DxGroupPanel,
  },
  computed: {
    hasValueSelected() {
      return this.gridBoxValue && this.gridBoxValue.length > 0;
    },
    addNewItemInfoText() {
      if (this.allowAddNew) {
        return this.addNewText + ", apasă butonul de adăugare!";
      } else {
        return this.addNewText;
      }
    },
    isRemoteOperation() {
      return this.actionMethod instanceof Function;
    },
    dataSource() {
      if (this.isRemoteOperation) {
        return this.customStore;
      }

      return this.localDataset;
    },
    customStore() {
      return new DataSource({
        store: new CustomStore({
          key: "uuid",
          load: this.actionMethod,
          byKey: async function (key) {
            return new Promise((resolve) => resolve("success"));
          },
        }),
      });
    },
  },
  methods: {
    gridBoxDisplayExpr(item) {
      return this.selectedItem.name;
    },
    onGridSelectionChanged(e) {
      this.gridBoxValue = e.currentSelectedRowKeys;
      this.selectedItem = e.selectedRowsData[0];

      this.$emit("selectionChanged", this.selectedItem);
      /*if (this.isRemoteOperation) {
        this.$emit("selectionChanged", this.selectedItem);
      } else {
        this.$emit("selectionChanged", this.gridBoxValue);
      }*/
    },
    onGridContentReady(e) {
      if (e.component.getVisibleRows().length <= 0 && this.allowAddNew) {
        this.allowGridAddNewProduct = true;
      } else {
        this.allowGridAddNewProduct = false;
      }
    },
    initNewItem(data) {
      this.selectedItem = data;
      this.gridBoxValue = [data.uuid];
      this.$emit("selectionChanged", data);
    },
    addNewItemInstant() {
      let gridFilter = this.$refs.productGrid.instance.getCombinedFilter();
      let productName = gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";
      if (productName) {
        this.initNewItem({
          uuid: "NEW_" + v4(),
          name: productName,
          code: "",
          measure_unit: null,
          product_type_uuid: null,
          measure_unit_id: null,
          quantity: 0,
          unit_price: 0,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.stock_wrapper {
  height: calc(100vh - 100px); // Adjust the height as needed
  overflow: hidden;
}
</style>

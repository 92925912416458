const state = () => {
  return {
    setting_groups: [],
    group_settings: [],
    group_setting: {},
    custom_setting_values: [],
    general_settings: [],
  }
}

export default state;

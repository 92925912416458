<template>
  <DxScrollView :use-native="true" class="stock_wrapper">
    <div class="stock_list_holder">
      <div v-for="(product, r_idx) in selectedProductList" :key="r_idx">
        <div class="stock_item">
          <div class="stock_main_info">
            <div class="product_name">
              {{
                product.code
                  ? `${product.name} (${product.code})`
                  : product.name
              }}
            </div>
            <div class="product_type">
              {{ product.product_type ? product.product_type.name : "Altele" }}
            </div>
          </div>
          <div class="stock_quantity">
            {{ (product.quantity ?? 0) | getFormattedQuantity }}
            {{ product?.measure_unit?.symbol }}
          </div>
          <div class="expand_icon_holder">
            <div :class="product.expanded ? 'expand_icon_expanded' : 'expand_icon'"
              @click="expandProduct(product, r_idx)">
              <img v-show="product.expanded" src="@/assets/images/icons/arrow_up_green.svg" />
              <img v-show="!product.expanded" src="@/assets/images/icons/arrow_down.svg" />
            </div>
          </div>
        </div>
        <div class="stock_details_holder" v-show="product.expanded">
          <div class="stock_details_spacer"></div>
          <div class="info_line" v-show="selectFromStock">
            <div class="info_value">Depozit:</div>
            <div class="info_quantity">
              {{ product.warehouse_name }}
            </div>
          </div>
          <div class="info_line">
            <div class="info_value">Cantitate:</div>
            <div class="info_quantity">
              {{ (product.quantity ?? 0) | getFormattedQuantity }}
            </div>
          </div>
          <div class="info_line">
            <div class="info_value">Unitate de măsura:</div>
            <div class="info_quantity">
              {{ product?.measure_unit?.symbol }}
            </div>
          </div>
          <div class="info_line" v-show="!selectFromStock">
            <div class="info_value">Preț unitar:</div>
            <div class="info_quantity">
              {{ product?.unit_price }}
            </div>
          </div>
          <div class="info_line">
            <div class="info_value">Observații:</div>
            <div class="info_quantity">
              {{ product?.notes }}
            </div>
          </div>
          <div class="info_line">
            <div class="info_value"><i class="fa fa-edit selected_product_action_icon"
                @click="editProductData(product.uuid)"></i></div>
            <div class="info_quantity"></div>
            <i class="fa fa-trash selected_product_action_icon" @click="removeFromSelectedList(r_idx)"></i>
          </div>
        </div>
      </div>
      <div style="height: 40px"></div>
    </div>
    <div style="margin-bottom: 50px;" class="sticky_action_button" @click="closeSelector">
      Continuă
    </div>
  </DxScrollView>
</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "SelectedProductList",
  props: {
    selectedProductList: {
      type: Array,
      default: () => [],
    },
    selectFromStock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    DxScrollView,
  },
  methods: {
    expandProduct(product, idx) {
      product.expanded = !product.expanded;
      this.$set(this.selectedProductList, idx, product);
    },
    closeSelector() {
      this.$emit("closeProductSelector");
    },
    editProductData(productUuid) {
      this.$emit("editProductData", productUuid);
    },
    removeFromSelectedList(r_idx) {
      this.selectedProductList.splice(r_idx, 1);
    },
  },
};
</script>

<style>
.fa-trash {
  color: red;
}

.selected_product_action_icon {
  font-size: 18px;
  padding: 10px;
}
</style>
<template type="x/template">
    <transition name="modal">
        <div>
            <DxPopup
                id="dx-dialog"
                width="100vw"
                height="100vh"
                :close-on-outside-click="false"
                :show-close-button="true"
                :visible="showPopup"
                :resize-enabled="false"
                :darg-enabled="false"
                @hidden="$emit('hidden')"
                :wrapper-attr="popupAttributes"
                >

                <template #title>
                    <div class="create-scouting-header">
                    <div class="left_side">
                        <div v-if="currentStep != 0" class="back_button" @click="backButtonEvent" >
                            <img src="@/assets/images/icons/arrow-back.svg" />
                            <div class="back_button_text">Inapoi</div>
                        </div>
                        <div v-if="currentStep == 0"  class="left-side-title">Notificare nouă</div>
                    </div>

                    <div class="right_side">
                        <img class="close_button"
                        src="@/assets/images/icons/dialog-close.svg"
                        @click="buttonCloseEvent"
                        />
                    </div>
                    </div>
                </template>

                <template #content>
                    <div>
                        <DxTabPanel
                            :data-source="itemSteps"
                            :loop="false"
                            :selected-item="currentItem"
                            :selected-index="currentStep"
                            :animation-enabled="true"
                            :swipe-enabled="false"
                            :show-nav-buttons="false"
                            @selection-changed="tabSelectionChanged($event)"
                            :element-attr="tabPanelAttributes"
                        >

                        <template #item="{ data: step }">
                            <div>
                                <DxScrollView v-if="step == 0" ref="createScoutingScroll_1" class="create-scouting-scroll" :use-native="true">
                                    <div class="create-scouting-content">
                                        <div class="csc-input-holder">
                                            <div class="input_label_holder" @click="detailStepClick('SCOUTING_DATE')">
                                                <div class="input_label">Data</div>
                                                <img class="scouting_details_img" src="@/assets/images/icons/arrow_right.svg"/>
                                            </div>
                                            <div @click="detailStepClick('SCOUTING_DATE')">
                                                {{ newScoutingData.scouting_date }}
                                            </div>
                                        </div>
                                        <div class="csc-input-holder">
                                            <div class="input_label_holder" @click="detailStepClick('SCOUTING_BBCH')">
                                                <div class="input_label">Stadiu BBCH</div>
                                                <img class="scouting_details_img" src="@/assets/images/icons/arrow_right.svg"/>
                                            </div>
                                            <div>
                                                {{ bbch_concatenated }}
                                            </div>
                                        </div>
                                        <div class="csc-input-holder">
                                            <div class="input_label_holder" @click="detailStepClick('SCOUTING_DISEASE')">
                                                <div class="input_label">Dăunători</div>
                                                <img class="scouting_details_img" src="@/assets/images/icons/arrow_right.svg"/>
                                            </div>
                                            <div>
                                                <div class="disease-line" v-for="(disease, index) in newScoutingData.diseases">
                                                    <div>{{ disease.name }}</div>
                                                    <img class="scouting_details_img" src="@/assets/images/icons/dialog-close-white.svg" @click="deleteDisease(disease)"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="csc-input-holder">
                                            <div class="input_label_holder">
                                                <div class="input_label">Fotografii</div>
                                            </div>
                                            <div class="pf-holder">
                                              <photo-factory :data="cameraImages" :maxCameraImages="maxScoutingCameraImages" ref="photoFactory"/>
                                            </div>
                                        </div>
                                        <div class="csc-input-holder" v-show="newScoutingData.diseases.length > 0">
                                            <div class="input_label_holder">
                                                <div class="input_label">Urgență</div>
                                            </div>
                                            <div class="scouting-priority">
                                                <div :class="['scouting-priority-item', 'left-button', {'button-selected' : newScoutingData.priority == 1}]" @click="selectPriority(1)">Scăzut</div>
                                                <div :class="['scouting-priority-item', {'button-selected' : newScoutingData.priority == 2}]" @click="selectPriority(2)">Mediu</div>
                                                <div :class="['scouting-priority-item', 'right-button', {'button-selected' : newScoutingData.priority == 3}]" @click="selectPriority(3)">Înalt</div>
                                            </div>
                                        </div>
                                        <div class="csc-input-holder">
                                            <div class="input_label">Observație</div>
                                            <div class="input_value">
                                                <DxTextArea class="global_dx_number_input" v-model="newScoutingData.notes" />
                                            </div>
                                        </div>
                                        <div class="new-scouting-bottom-spacer"></div>
                                    </div>
                                </DxScrollView>
                                <div v-if="step == 0" class="create-scouting_step-0-sticky-dialog">
                                    <b-button  v-if="!saveInProgress"
                                        class="continue_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="createScouting(true)"
                                        >Salvează și adaugă nou</b-button
                                    >
                                    <b-button v-if="!saveInProgress"
                                        class="second_action_scouting second_action_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="createScouting(false)"
                                        >Salvează</b-button>
                                    <loader v-if="saveInProgress" :loading="true" size="sm"></loader>
                                    <loader v-if="saveInProgress" :loading="true" size="sm"></loader>
                                </div>
                                <DxScrollView v-if="step == 1 && detailStepCode == 'SCOUTING_DATE'" ref="createScoutingScroll_2" class="create-scouting-scroll" :use-native="true">
                                    <div>
                                        <functional-calendar v-if="showPopup"
                                            :date-format="'yyyy-mm-dd'"
                                            :is-date-range="false"
                                            :is-date-picker="true"
                                            v-model="calendar"
                                            :change-month-function="true"
                                            :change-year-function="true"
                                        ></functional-calendar>
                                    </div>
                                </DxScrollView>
                                <DxScrollView v-show="step == 1 && detailStepCode == 'SCOUTING_BBCH'" ref="createScoutingScroll_3" class="create-scouting-scroll" :use-native="true">
                                    <div>
                                        <SelectBBCH
                                            ref="selectBBCHControl"
                                            @setBBCH="setBBCH"
                                            :culture="selectedLayer?.culture ? selectedLayer.culture : ''">
                                        </SelectBBCH>
                                    </div>
                                </DxScrollView>
                                <DxScrollView v-show="step == 1 && detailStepCode == 'SCOUTING_DISEASE'" ref="createScoutingScroll_4" class="create-scouting-scroll" :use-native="true">
                                    <div>
                                        <AddDisease ref="addDiseaseControl"></AddDisease>
                                    </div>
                                </DxScrollView>
                                <div v-if="step == 1" class="create-scouting_step-1-sticky-dialog">
                                    <b-button
                                        class="continue_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="applyStep1()"
                                        >Aplică</b-button
                                    >
                                </div>
                            </div>
                        </template>

                        </DxTabPanel>
                    </div>
                </template>

            </DxPopup>

            <toast-message ref="toastMessage"></toast-message>
            <DeleteDiseaseConfirm
            :showPopup="diseaseConfirmDialogVisibility"
            :disease_uuid="deleteDiseaseUUID"
            @hidden="hideDeleteDisease"
            @persistDeleteDisease="persistDeleteDisease"
           ></DeleteDiseaseConfirm>
        </div>
    </transition>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTextArea from 'devextreme-vue/text-area';
import DxTabPanel from "devextreme-vue/tab-panel";
import { FunctionalCalendar } from "vue-functional-calendar";
import AddDisease from "../partials/add-disease.vue"
import SelectBBCH from "../partials/select-bbch.vue"
import ToastMessage from "../../../components/Mobile/toastMessage.vue"
import PhotoFactory from "~/components/Camera/PhotoFactory.vue";
import DeleteDiseaseConfirm from "./delete-disease-confirm.vue"
import Loader from "@/components/Loader.vue";
import moment from "moment";
import {settings} from "@/helpers/settings";


export default{
    name: "CreateScouting",
    props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
        filterData: {
            type: Object,
            default: null
        },
        parcel_uuid: {
          type: String,
          default: '',
        },
        selectedLayer: {
            type: Object,
            default: () => {},
        },
        season_uuid: {
          type: String,
          default: '',
        },
        season_on_parcel_uuid: {
          type: String,
          default: '',
        },
    },
    data(){
        return {
            popupAttributes: {
                class: 'create_scouting_dialog_wrapper'
            },
            tabPanelAttributes: {
                class: 'create_scouting_tab_panel'
            },
            itemSteps: [0, 1],
            currentStep: 0,
            currentItem: 0,
            newScoutingData: {
                scouting_date: '',
                min_bbch: null,
                max_bbch: null,
                notes: '',
                diseases: [],
                priority: 0
            },
            calendar: {},
            detailStepCode: '',
            maxScoutingCameraImages: 5,
            diseaseConfirmDialogVisibility: false,
            deleteDiseaseUUID: '',
            cameraImages: [],
            saveInProgress: false
        };
    },
    components: {
      Loader,
      PhotoFactory,
        DxPopup,
        DxPosition,
        DxScrollView,
        DxTextArea,
        DxTabPanel,
        FunctionalCalendar,
        AddDisease,
        SelectBBCH,
        ToastMessage,
        DeleteDiseaseConfirm
    },
    computed: {
      ...mapGetters({
          groupSettings: 'settings/groupSettings',
        }),
        bbch_concatenated(){
            if(this.newScoutingData.min_bbch != null && this.newScoutingData.max_bbch != null){
                return " BBCH " + this.newScoutingData.min_bbch.code + " - BBCH " + this.newScoutingData.max_bbch.code;
            }
            if(this.newScoutingData.min_bbch != null){
                return this.newScoutingData.min_bbch.code;
            }
            return "";
        },
    },
    watch: {
         showPopup: {
             handler: function (newVal, oldVal) {
                if(newVal){
                    this.resetForm();
                }
            }
        },
        calendar: {
            handler(newVal) {
                if(newVal?.selectedDate){
                    this.newScoutingData.scouting_date = newVal.selectedDate;
                }
            },
                deep: true,
            },
    },
    methods: {
        ...mapActions({
            saveScouting: "scouting/saveScouting",
            getGroupByCode: 'settings/getGroupByCode',
        }),
        deleteDisease(disease){
            this.deleteDiseaseUUID = disease.uuid;
            this.diseaseConfirmDialogVisibility = true;
        },
        hideDeleteDisease(){
            this.diseaseConfirmDialogVisibility = false;
        },
        persistDeleteDisease(diseaseUUID){
            this.newScoutingData.diseases = this.newScoutingData.diseases.filter(obj => obj.uuid != diseaseUUID);
        },
        resetForm(){
            this.saveInProgress = false;
            this.currentStep = 0;
            this.currentItem = 0;
            this.newScoutingData = {
                scouting_date: moment().format("YYYY-MM-DD"),
                min_bbch: null,
                max_bbch: null,
                notes: '',
                diseases: [],
                priority: 0,

            };
            if(this.$refs.selectBBCHControl){
                this.$refs.selectBBCHControl.resetForm();
            }
            this.cameraImages = [];
        },
        buttonCloseEvent(){
            this.$emit("hidden");
        },
        tabSelectionChanged(e) {
            this.currentItem = e.addedItems[0];
            this.currentStep = this.itemSteps.indexOf(this.currentItem);
        },
        detailStepClick(step_code) {
            this.detailStepCode = step_code;
            if(step_code == 'SCOUTING_DISEASE'){
                this.$refs.addDiseaseControl.resetForm();
            }
            this.currentStep = this.currentStep + 1;
        },
        applyStep1(){
            let isValid = false;
            if(this.detailStepCode == 'SCOUTING_DATE'){
                if(this.newScoutingData.scouting_date == ''){
                    this.$refs.toastMessage.showError('Selectați data înregistrării!');
                }
                else{
                    isValid = true;
                }
            }
            if(this.detailStepCode == 'SCOUTING_BBCH'){
                if(this.newScoutingData.min_bbch == null){
                    this.$refs.toastMessage.showError('Selectați stadiu BBCH!');
                }
                else{
                    isValid = true;
                }
            }
            if(this.detailStepCode == 'SCOUTING_DISEASE'){
                isValid = this.$refs.addDiseaseControl.checkIfDataIsValid();
                if(isValid){
                    let diseases = this.$refs.addDiseaseControl.getDataAtApply();
                    diseases.diseases.forEach(element => {
                        let checkObj = this.newScoutingData.diseases.find(obj => obj.uuid == element.uuid)
                        if(checkObj == undefined){
                            this.newScoutingData.diseases.push({
                                uuid: element.uuid,
                                name: element.name,
                                type: element.type,
                                infection_level: diseases.infection_level
                            });
                        }
                    });
                }
            }
            if(isValid){
                this.detailStepCode = '';
                this.currentStep--;
            }
        },
        backButtonEvent(){
            this.detailStepCode = '';
            this.currentStep--;
        },
        setBBCH(min_bbch, max_bbch){
            this.newScoutingData.min_bbch = min_bbch;
            this.newScoutingData.max_bbch = max_bbch;
        },
        validateScouting(){

          const isBBCHRequired = this.groupSettings?.items.find(s => s.code === settings.SCOUTING.BBCH_LIST_REQUIRED)?.value == 1;
          const isDiseaseTypeRequired = this.groupSettings?.items.find(s => s.code === settings.SCOUTING.DISEASES_REQUIRED)?.value == 1;
          const isPhotoUploadRequired = this.groupSettings?.items.find(s => s.code === settings.SCOUTING.PHOTO_UPLOAD_REQUIRED)?.value == 1;

            let valid = false;
            if(this.newScoutingData.scouting_date == ''){
                this.$refs.toastMessage.showError('Selectați data înregistrării!');
            } else if(this.newScoutingData.min_bbch == null && isBBCHRequired){
                this.$refs.toastMessage.showError('Selectați stadiu BBCH!');
            } else if (this.newScoutingData.diseases.length <= 0 && isDiseaseTypeRequired) {
                this.$refs.toastMessage.showError('Selectați dăunători!');
            } else if ((this.newScoutingData.diseases.length > 0 && this.newScoutingData.priority <= 0)) {
              this.$refs.toastMessage.showError('Selectați gradul de urgență!');
            } else if (this.$refs.photoFactory.getCameraImages() <= 0 && isPhotoUploadRequired) {
              this.$refs.toastMessage.showError('Selectați/încărcați cel puțin o fotografie!');
            }
            else{
                valid = true;
            }
            return valid;
        },
        createScouting(saveAndNew){
            this.saveInProgress = true;
            let valid = this.validateScouting();

            if(valid){
                let scoutingDataForSave = {
                    show_general_error: false,
                    reported_at: this.newScoutingData.scouting_date + " 12:00:00",
                    bbch_value_from_uuid: this.newScoutingData.min_bbch?.uuid,
                    bbch_value_to_uuid: this.newScoutingData.max_bbch?.uuid,
                    priority: this.newScoutingData.priority ?? 0,
                    notes: this.newScoutingData.notes,
                    season_on_parcel_uuid: this.season_on_parcel_uuid,
                    diseases: [],
                    images: this.$refs.photoFactory.getCameraImages(),
                };

                this.newScoutingData.diseases.forEach(d => {
                    scoutingDataForSave.diseases.push({
                        disease_uuid: d.uuid,
                        infection_level: d.infection_level
                    });
                });

                if(scoutingDataForSave.diseases.length == 0){
                    scoutingDataForSave.diseases.priority = 0;
                }

                this.saveScouting(scoutingDataForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Notificarea a fost creată cu succes!');
                    this.$emit("refresh");
                    if(saveAndNew){
                        this.resetForm();
                    }
                    else{
                        this.$emit("hidden");
                    }
                    this.saveInProgress = false;
                }).catch((e) => {
                    this.saveInProgress = false;
                    this.$refs.toastMessage.showError('A apărut o erroare la salvare! \nVă rugăm încercați încă o dată! \nDacă erroarea persistă vă rugăm adresați administratorul sistemului!');
                })
                ;
            }
            else{
                this.saveInProgress = false;
            }
        },
        selectPriority(prio){
            this.newScoutingData.priority = prio;
        },
        getSettings() {
          this.getGroupByCode({
            code: 'SCOUTING'
          });
        },
    },
    mounted() {
        this.getSettings();
    }
}

</script>

<style lang="scss">
  @import "@/assets/scss/scoutingMobile/createScouting.scss";
</style>

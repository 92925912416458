import * as types from "@/store/mutation-types";

const mutations = {
  [types.SET_SETTING_GROUPS]: (state, payload) => {
    state.setting_groups = payload;
  },
  [types.SET_GROUP_SETTINGS]: (state, payload) => {
    state.group_settings = payload;
  },
  [types.SET_GROUP_SETTING]: (state, payload) => {
    state.group_setting = payload;
  },
  [types.SET_CUSTOM_SETTING_VALUES]: (state, payload) => {
    state.custom_setting_values = payload;
  },
  [types.SET_GENERAL_SETTING_VALUES]: (state, payload) => {
    state.general_settings = payload;
  },
}

export default mutations;

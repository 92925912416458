<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="work-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new", { name: title })'>
        <s-c-work-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12 text-right">
            <router-link :to="'/salary-calculator/sc-work-group/add?backPath='+$route.fullPath">
              <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"/> {{ $t('salary_calculator.work_group.create_new') }}
            </router-link>
        </div>
      </div>
      <div class="row">
        <div class="work-groups col-lg-12">
          <card v-for="group in items" :key="group.uuid" class="mt-5">
            <div class="row work-group">
                <div class="col-lg-11 light-bold"
                     data-toggle="collapse" :data-target="'#collapseWorkGroup_'+group.uuid"
                     :aria-expanded="false" :aria-controls="'#collapseWorkGroup_'+group.uuid">
                  {{ group.full_name }} ({{ group.works ? group.works.length : 0 }})
                </div>
                <div class="work-group-actions col-lg-1 text-right">
                  <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg text-secondary c-pointer"
                       :title="$t('salary_calculator.work.add_to_group')"
                       @click="newObject(group.uuid)"/>
                </div>
            </div>
            <div class="row collapse show" :id="'#collapseWorkGroup_'+group.uuid" v-if="group.works && group.works.length > 0">
                <div class="col-lg-12">
                  <div class="row thead">
                    <div class="col-lg-3">
                      {{ $t('salary_calculator.work.name') }}
                    </div>
                    <div class="col-lg-2">
                      {{ $t('salary_calculator.shift.title') }}
                    </div>
                    <div class="col-lg">
                      {{ $t('common.price') }}
                    </div>
                    <div class="col-lg">
                      {{ $t('common.mu') }}
                    </div>
                    <div class="col-lg">
                      {{ $t('salary_calculator.bonus.title') }}
                    </div>
                    <div class="col-lg">
                      {{ $t('common.actions') }}
                    </div>
                  </div>
                    <div class="row p-2" v-for="work in group.works" :key="work.uuid">
                        <div class="col-lg-3">
                          {{ work.name }}
                        </div>
                        <div class="col-lg-2" v-if="work.shift">
                          <router-link :to="'/salary-calculator/sc-shift/'+work.shift.uuid">
                            {{ work.shift.name }} ({{ work.shift.start_hour }} - {{ work.shift.end_hour }})
                          </router-link>
                        </div>
                      <div class="col-lg" v-if="work.shift">
                        {{ work.assigned_price }} {{ $currency.name }}
                      </div>
                      <div class="col-lg">
                        <router-link :to="'/salary-calculator/sc-measure-unit/'+work.measure_unit.uuid" v-if="work.measure_unit">
                          {{ work.measure_unit.name }}
                        </router-link>
                        <label v-else>-</label>
                      </div>
                      <div class="col-lg">
                        <router-link :to="'/salary-calculator/sc-bonus/'+work.bonus.uuid" v-if="work.bonus">
                          {{ work.bonus.name }}
                        </router-link>
                        <label v-else>-</label>
                      </div>
                      <div class="col-lg">
                        <action-controls
                          :permission="config"
                          :data="work"
                          v-if="config"></action-controls>
                      </div>
                    </div>
                </div>
            </div>
          </card>

        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import SCWorkFilters from "@/pages/salary-calculator/sc-work/partials/SCWorkFilters";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import ActionControls from "@/components/AgroGrid/TableBody/ActionControls";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";
import {errorMessage} from "@/helpers/common";

export default {
  name: 'SCWorkList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scWorkFilters ? this.scWorkFilters : {},
      items: this.scWorkHierarchy ? this.scWorkHierarchy : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    SCWorkFilters,
    ActionControls,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scWorkFilters: 'scWork/scWorkFilters',
      scWorkHierarchy: 'scWorkPivot/scWorkHierarchy',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    title() {
        return this.$t('salary_calculator.work.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK,
        permission_type: permissionTypes.module,
        edit: {
          url: '/salary-calculator/sc-work/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteWorkPivot,
          callback: this.onItemDeleted,
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      getWorkHierarchy: 'scWorkPivot/getSCWorkHierarchy',
      deleteWorkPivot: 'scWorkPivot/deleteSCWorkPivot',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scWorkPivot/"+type.SET_SC_WORK_HIERARCHY_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject(work_group_uuid = '') {
      const group = this.scWorkHierarchy.find(wg => wg.uuid === work_group_uuid);

      if (group && group.is_non_productive_hours == 1) {
        errorMessage(this, this.$t('salary_calculator.work.cannot_add_more_non_prod'));
        return;
      }

      this.$router.push({
        path: 'sc-work/add',
        query: {
          work_group_uuid: work_group_uuid,
        }
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      this.items.map((group) => {
        let work = Object.assign([], group.works).find(w => w.uuid === item.uuid);
        if(work) {
          group.works.splice(
            group.works.indexOf(work), 1
          );
        }
      });
    },
    onFiltersApplied(filters) {
      this.loading = true;
    }
  },
  watch: {
    scWorkHierarchy: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.name")
        }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.products.search_by_name')"
          :dispatch="getProducts"
          search-key="name"
          ref="productName"
          :required="true"
          :data="productName"
          @onSelected="onProductNameSelected"
          @onChange="onProductNameChanged"
        ></autocomplete-search>
      </div>
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.code")
        }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.products.search_by_code')"
          :dispatch="getProducts"
          search-key="code"
          ref="productCode"
          :class="{ 'field-error': $v.data.product.code.$error }"
          :required="true"
          :data="productCode"
          @onSelected="onProductCodeSelected"
          @onChange="onProductCodeChanged"
        ></autocomplete-search>
      </div>
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.barcode")
        }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.products.search_by_barcode')"
          :dispatch="getProducts"
          search-key="barcode"
          ref="productBarcode"
          :required="true"
          :data="productBarcode"
          @onSelected="onProductBarcodeSelected"
          @onChange="onProductBarcodeChanged"
        ></autocomplete-search>
      </div>
    </div>

    <div class="row" v-if="!data.product.uuid && !!data.product.name">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.type")
        }}</label>
        <b-form-select
          v-model="data.product.product_type_uuid"
          :class="{ 'field-error': $v.data.product.product_type_uuid.$error }"
          class="form-control"
          :options="product_type_list"
          :disabled="!!data.product.uuid"
        ></b-form-select>
      </div>
      <div class="col-sm-4 col-lg-3 form-group">
        <label class="control-label">{{
          $t("stock_management.products.select_mu")
        }}</label>
        <v-select
          v-model="data.product.measure_unit"
          :reduce="(option) => option"
          :class="{ 'field-error': $v.data.product.measure_unit_id.$error }"
          :placeholder="$t('stock_management.products.select_mu')"
          label="name"
          autocomplete="off"
          :options="measure_unit_list"
          @input="onMeasureUnitSelected"
          class="custom-select-dropdown-height"
          :disabled="!!data.product.uuid"
        ></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.reception.date")
        }}</label>
        <date-picker2
          v-model="data.reception_date"
          :value-type="reception_date_input"
          :format="getDefaultDateFormat"
          type="date"
          :input-class="['date-picker-style']"
          :class="{ 'field-error': this.$v.data.reception_date.$error }"
          @change="onReceptionDateChanged"
          ref="reception_date"
          :disabled="(!!data.batch_uuid && !!data.reception_date)"
        ></date-picker2>
      </div>
      <div class="col-sm-4 col-lg-3 form-group">
        <label class="control-label">{{
          $t("stock_management.products.partner")
        }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.partners.search')"
          :dispatch="getPartners"
          search-key="name"
          ref="partner"
          :required="true"
          :data="partnerName"
          @onSelected="onPartnerSelected"
          @onChange="onPartnerChanged"
        ></autocomplete-search>
        <img
          src="~@/assets/images/desktop/icons/controls/plus.svg"
          class="icon-svg float-right text-success c-pointer"
          @click="showPartnerDialog"
          v-if="addNewPartner === 'add'"
        />
        <img
          src="~@/assets/images/desktop/icons/controls/edit.svg"
          class="icon-svg float-right text-success c-pointer"
          @click="showPartnerDialog"
          v-if="addNewPartner === 'edit'"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.unit_price_net")
        }}</label>
        <input
          v-model="data.product.unit_price"
          class="form-control upper"
          :class="{ 'field-error': $v.data.product.unit_price.$error }"
          v-on:keydown="onlyPrice"
          placeholder="0.00"
        />
      </div>
      <div class="col-sm-12 col-lg-3 form-group">
        <label class="control-label">{{
          $t("stock_management.products.vat_code")
        }}</label>
        <b-form-select
          v-model="data.vat_code"
          class="form-control"
          :options="vat_code_list"
        ></b-form-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.products.quantity")
        }}</label>
        <DxNumberBox
          v-model="data.product.quantity"
          :min="0"
          :showSpinButtons="false"
          class="dx-form-control"
          :class="{ 'field-error': $v.data.product.quantity.$error }"
          :format="quantityFormatter"
          placeholder="0.00"
        />
      </div>
      <div class="col-sm-4 col-lg-3 form-group">
        <label class="control-label">{{ $t("common.warehouse") }}</label>
        <b-form-select
          v-model="data.warehouse_uuid"
          class="form-control"
          :class="{ 'field-error': $v.data.warehouse_uuid.$error }"
          :options="warehouse_list"
          :disabled="!!data.batch_uuid"
        ></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t("stock_management.shipping_number") }}</label>
        <input
          v-model="data.shipping_note_number"
          class="form-control"
          :maxlength="185"
          :disabled="!!data.batch_uuid"
        ></input>
      </div>
      <div class="form-group col-sm-12 col-lg-5 text-left">
        <label class="control-label">{{ $t("stock_management.notes") }}</label>
        <textarea
          v-model="data.notes"
          class="form-control textarea-2-lines"
          :maxlength="185"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="control-label font-weight-bold">{{
          $t("common.total")
        }}</label>
        <div>{{ total + " " + $currency.name }}</div>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

    <CreatePartnerDialog
      :show="showPartnersModal"
      @close="showPartnersModal = false"
      :data="partnersModalData"
      @onOK="onPartnerDialogClosed"
    ></CreatePartnerDialog>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  buildDefaultDropdownList,
  only_price,
  errorMessage,
} from "@/helpers/common";
import required from "vuelidate/src/validators/required";
import {
  canDelete,
  canEdit,
  permissions,
  permissionTypes,
} from "@/helpers/Permissions";
import * as actions from "@/helpers/generalActions";
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import AutocompleteSearch from "~/components/Common/AutocompleteSearch/Index.vue";
import CreatePartnerDialog from "~/pages/stock-management/sm-stocks/partials/CreatePartnerDialog.vue";
import DatePicker2 from "vue2-datepicker";
import moment from 'moment'
import DxNumberBox from "devextreme-vue/number-box";
import { getQuantityFormatter } from '~/helpers/stockHelper';

export default {
  name: "SMProductsDetails",
  components: {
    CreatePartnerDialog,
    AutocompleteSearch,
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead,
    DatePicker2,
    DxNumberBox,
  },
  data() {
    return {
      data: {
        product: {
          name: null 
        },
        reception_date: null,
        batch_uuid: null,
        shipping_note_number: null,
      },
      product_types: this.productTypes ? this.productTypes : [],
      vat_code_list: [
        { value: null, text: "-" },
        { value: 5, text: "5%" },
        { value: 9, text: "9%" },
        { value: 19, text: "19%" },
      ],
      addNewPartner: false,
      showPartnersModal: false,
      partnersModalData: {},
      productCode: null,
      productBarcode: null,
      productName: null,
      partnerName: null,
      reception_date_input: null,
      use_default_0_unit_price: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      users: "user/users",
      domain: "auth/domain",
      products: "smProducts/smProducts",
      productTypes: "smProductTypes/smProductTypes",
      measureUnits: "measureUnits/measureUnits",
      warehouses: "smWarehouses/smWarehouses",
      partners: "smPartners/smPartners",
      getDefaultDateFormat: 'settings/getDefaultDateFormat',
    }),
    quantityFormatter(){
      return getQuantityFormatter();
    },
    name() {
      return this.$t("stock_management.reception.title");
    },
    config() {
      return {
        permission: permissions.SM_STOCK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: "/stock-management/sm-stocks",
        confirm_delete: true,
      };
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return this.name;
    },
    product_type_list() {
      return buildDefaultDropdownList(Object.assign(this.productTypes));
    },
    measure_unit_list() {
      if (this.measureUnits) {
        return this.measureUnits.map((item) => ({
          value: item.id,
          name: `${item.name} (${item.symbol})`,
        }));
      }
      return [];
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses));
    },
    total() {
      if (this.data.product.quantity > 0 && this.data.product?.unit_price) {
        return (
          this.data.product.quantity * this.data.product.unit_price
        ).toFixed(2);
      }
      return (0).toFixed(2);
    },
  },
  methods: {
    ...mapActions({
      getProductTypes: "smProductTypes/searchSmProductTypes",
      get: "smProducts/getSmProduct",
      create: "smTransactions/createReceptionTransaction",
      update: "smStocks/updateSmStock",
      delete: "smStocks/deleteSmStock",
      getProducts: "smProducts/getSmProducts",
      getMeasureUnits: "measureUnits/searchMeasureUnits",
      getWarehouses: "smWarehouses/getSmWarehouses",
      getPartners: "smPartners/getSmPartners",
      checkReceptionShippingNumberAlreadyExists: "smTransactions/checkReceptionShippingNumberAlreadyExists",
      getGroupByCode: 'settings/getGroupByCode',
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(
        this,
        (data) => {
          vm.$set(vm.data, "product", {
            product_type_uuid: null,
            vat_code: null,
            measure_unit_id: null,
          });
          vm.$set(vm.data, "warehouse_uuid", null);
          vm.$set(vm.data, "vat_code", null);
          vm.$set(vm.data, "measure_unit_id", null);
          vm.$set(vm.data, "product_type_uuid", null);
        },
        (data) => {}
      );
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    checkShippingNumberAlreadyExist(callback){
      if(this.data.shipping_note_number && this.data.shipping_note_number.length > 0)
      {
            this.checkReceptionShippingNumberAlreadyExists({
                shipping_note_number: this.data.shipping_note_number
            }).then(response => {
                    if (response.is_exists && response.batch_uuid != this.data.batch_uuid) {
                        this.$dialog.confirm(this.$t("stock_management.reception.shipping_number_exist"), {
                            okText: this.$t("common.yes"),
                            cancelText: this.$t("common.no")
                      }).then((dialog) => {
                            dialog.close();
                            const responseDate = new Date(response.transaction_date);
                            const receptionDate = new Date(this.data?.reception_date);
                        if (response.batch_uuid && response.batch_uuid.length > 0 &&
                            responseDate.toDateString() === receptionDate.toDateString() &&
                            response.partner_uuid == this.data?.product?.partner?.uuid &&
                            response.warehouse_uuid == this.data?.warehouse_uuid) {
                              this.data.batch_uuid = response.batch_uuid;
                        }
                        callback();
                    }).catch(() => {
                        // Handle cancel
                    });
                } else {
                    callback();
                }
           });
      } else {
        callback();
      }
    },
    prepareSave(callback) {
      if (!this.data.product.product_type_uuid) {
        errorMessage(this, this.$t("stock_management.products.type_required"));
        return;
      }

      this.$v.data.$touch();

      let productNameValidate = this.$refs["productName"].validate();
      let partnerValidate = this.$refs["partner"].validate();
      this.data.product.total = this.total;

      let whs = this.warehouses.find(
        (w) => w.uuid === this.data.warehouse_uuid
      );

      if (whs) {
        this.data.department_uuid = whs.department_uuid;
      }

      if (productNameValidate && partnerValidate && !this.$v.data.$invalid) {
        this.checkShippingNumberAlreadyExist(() => {
          const reception_to_save = {
            batch_uuid: this.data.batch_uuid,
            department_uuid: this.data.department_uuid,
            reception_date: this.data.reception_date,
            shipping_note_number: this.data.shipping_note_number,
            warehouse_uuid: this.data.warehouse_uuid,
            partner: this.data.product.partner,
            products: [
              {
                uuid: this.data.product.uuid,
                name: this.data.product.name,
                code: this.data.product.code,
                barcode: this.data.product.barcode,
                unit_price: this.data.product.unit_price,
                quantity: this.data.product.quantity,
                measure_unit_id: this.data.product.measure_unit_id,
                product_type_uuid: this.data.product.product_type_uuid,
                vat_code: this.data.vat_code,
                notes: this.data.notes,
              },
            ],
          };

          callback(reception_to_save);
         });
      } else {
        errorMessage(this, this.$t('fill_out_error'));
      }
    },
    save() {
      this.prepareSave((reception_to_save) => {
        this.actions.save(this, this.config.list_url, reception_to_save);
      });
    },
    saveAndNew() {
        this.prepareSave((reception_to_save) => {
          this.actions.saveAndNew(this, undefined, null, reception_to_save).then(response => {
            this.product = {
              name: null
            };

            this.$set(this.data, "product", this.product);
            this.$set(this, "productBarcode", null);
            this.$set(this, "productCode", null);
            this.$set(this, "productName", null);
            this.$set(this.data, "vat_code", null);
            this.$set(this.data, "measure_unit_id", null);
            this.$set(this.data, "product_type_uuid", null);
            this.$set(this.data, "quantity", null);
            this.$set(this.data, "quantity_mu", null);
            this.$set(this.data, "notes", null);

            if(response && response.data && response.data.length > 0){
              this.onPartnerSelected(response.data[0].product.partner);
              this.$set(this.data, "batch_uuid", response.data[0].batch_uuid);
              this.$refs['partner'].$refs['search'].$refs.input.disabled = true;
            }
          }).catch((e) => {
            console.log(e);
            errorMessage(this,'Error on saving data');
          })
        });
    },
    newObject() {
      return (window.location.href = this.config.list_url + "/reception");
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onProductCodeSelected(data) {
      this.fillProductData(data);
    },
    onProductCodeChanged(data) {
      this.data.product.code = data?.name;
      this.data.product.uuid = data?.uuid;
    },
    onProductBarcodeSelected(data) {
      this.fillProductData(data);
    },
    onProductBarcodeChanged(data) {
      this.data.product.barcode = data?.name;
      this.data.product.uuid = data?.uuid;
    },
    onProductNameSelected(data) {
      this.fillProductData(data);
    },
    onProductNameChanged(data) {
      this.$set(this.data.product, 'name', data?.name);
      this.$set(this.data.product, 'uuid', data?.uuid);

      if (!data || !data.uuid) {
        this.$set(this.data.product, "barcode", null);
        this.$set(this.data.product, "code", null);
        this.$set(this, "productBarcode", null);
        this.$set(this, "productCode", null);
      }
    },
    getProduct(product_uuid) {
      return this.products.find((p) => p.uuid === product_uuid);
    },
    fillProductData(product, callback = undefined) {
      if (product && product.uuid) {
        let prod = this.getProduct(product.uuid);
        if (prod) {
          this.data.product = prod;
          if(this.use_default_0_unit_price){
            this.data.product.unit_price = 0;
          }
          this.productName = {
            uuid: product.uuid,
            name: product.name,
          };
          this.productBarcode = {
            uuid: product.uuid,
            name: product.barcode,
          };
          this.productCode = {
            uuid: product.uuid,
            name: product.code,
          };
          this.partnerName = prod?.partner;

          if (callback !== undefined) {
            callback(this.data);
          }
        }
      }
    },
    onPartnerSelected(data) {
      this.data.product.partner = data;
      this.addNewPartner = this.data.product.partner.uuid ?? "edit";
    },
    onPartnerChanged(data) {
      if (!this.data?.product?.partner) {
        this.data.product.partner = {};
      }

      if (data?.name !== this.data.product.partner?.name) {
        this.addNewPartner = "add";
        this.partnersModalData = data; //init with name
      }
    },
    showPartnerDialog() {
      this.showPartnersModal = true;

      if (this.addNewPartner === "add") {
      } else {
        if (this.partnersModalData.name !== this.data.product.partner?.name) {
          this.partnersModalData = { name: this.partnersModalData.name }; //if name has changed while editing
        } else {
          this.partnersModalData = this.data.product.partner;
        }
      }

      this.partnersModalData.action = this.addNewPartner;
    },
    onPartnerDialogClosed(data) {
      this.showPartnersModal = false;

      if (data && data.name) {
        this.addNewPartner = "edit";
        this.data.product.partner = data;
        this.partnerName = data;
      }
    },
    onReceptionDateChanged(date) {
    },
    onMeasureUnitSelected(data) {
      if (data) {
        this.data.product.measure_unit = {
          id: data.value,
          name: data.name,
        };
        this.data.product.measure_unit_id = data.value;
      } else {
        this.data.product.measure_unit = null;
        this.data.product.measure_unit_id = null;
      }
    },
  },
  mounted() {
    this.data.reception_date = moment().format(this.getDefaultDateFormat);
    this.getProductTypes({
      pagination: 0,
    });
    this.loadData();
    this.getMeasureUnits({
      enablePagination: 0,
    });
    this.getWarehouses({
      enablePagination: 0,
    });
    this.actions.handleParams(this);

    this.getGroupByCode({
      code: 'STOCK_MANAGEMENT'
    }).then(response => {
        let _setting = response.items.find(obj => obj.code == 'USE_DEFAULT_0_UNIT_PRICE_ON_RECEPTION');
        this.use_default_0_unit_price = false;
        if(_setting !== undefined){
          this.use_default_0_unit_price = _setting?.value == '1';
          if(this.use_default_0_unit_price){
            this.data.product.unit_price = 0;
          }
        }
    });
  },
  watch: {
    productTypes: {
      handler: function (newVal) {
        this.product_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    $route: {
      handler: function (newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function (newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    data: {
      handler: (data) => {},
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        product: {
          name: {
            required,
          },
          unit_price: {
            required,
          },
          quantity: {
            required,
          },
          measure_unit_id: {
            required,
          },
          product_type_uuid: {
            required,
          },
          code: {
            required: (value) => !!this.data.product.uuid || !!value,
          },
        },
        warehouse_uuid: {
          required,
        },
        reception_date: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
.textarea-2-lines {
  height: calc(1.5em * 2 + 0.75em + 2px);
}

.a-icon {
  position: relative;
  top: -26px;
  left: +40px;
}

.float-right {
  float: right;
  position: relative;
  top: -40px;
  left: 30px;
}
</style>

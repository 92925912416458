<template>
  <div>
    <div
      :class="showFilterText ? 'list_wrapper_with_filter' : 'list_wrapper'"
      v-infinite-scroll="onScroll"
      infinite-scroll-disabled="infinite_scroll_busy"
      infinite-scroll-distance="1000"
    >
      <div
        v-for="(workDate, d_index) in workDatesDistinct"
        :key="d_index"
        class="date_wrapper"
      >
        <div :class="['group_header', { top_radius: d_index == 0 }]">
          {{ workDate.date }}
        </div>
        <div class="date_input_wrapper">
          <div
            :class="[
              'no_input_wrapper',
              { top_radius: workDate.works.length > 0 },
              { bottom_radius: workDate.works.length == 0 },
            ]"
          >
            <div
              v-for="(workItem, w_index) in workDate.works"
              :key="workItem.uuid"
              :class="[
                { top_radius: w_index == 0 },
                { bottom_radius: workDate.works.length - 1 == w_index},
              ]"
            >
              <div
                :class="[
                  'grid_custom_row_white',
                  {
                    grid_custom_row_selected: workItem.selected,
                  },
                  {
                    grid_custom_row_selected_border:
                      workItem.selected &&
                      (workDate.works.length != w_index + 1),
                  },
                  { work_top_border: w_index > 0 },
                  { top_radius: w_index == 0 },
                  { bottom_radius: workDate.works.length - 1 == w_index },
                ]"
              >
                <div class="left_side">
                  <b-form-checkbox
                    class="select_row_checkbox"
                    size="sm"
                    v-show="start_mode == true"
                    v-model="workItem.selected"
                    @change="
                      work_selected_event($event, d_index, w_index, workItem)
                    "
                  >
                  </b-form-checkbox>
                  <img
                    class="has_input_icon"
                    v-if="workItem.products.length > 0"
                    src="@/assets/images/icons/droplet.svg"
                    @click="workNameClick(workItem)"
                    :class="[
                      {
                        input_link:
                          workItem.products.length > 0 && start_mode == false,
                      },
                      {
                        has_input_icon_selected: workItem.selected,
                      },
                    ]"
                  />
                  <img
                    @click="
                      work_select_by_icon(true, d_index, w_index, workItem)
                    "
                    :class="[
                      { has_input_icon: true },
                      {
                        has_input_icon_selected: workItem.selected,
                      },
                    ]"
                    v-else
                    src="@/assets/images/icons/work_plus.svg"
                  />
                  <div class="main_text">
                    <div
                      @click="workNameClick(workItem)"
                      :class="[
                        {
                          input_link:
                            workItem.products.length > 0 && start_mode == false,
                        },
                        {
                          no_input:
                            workItem.products.length <= 0 &&
                            start_mode == false,
                        },
                      ]"
                    >
                      {{ workItem.name }}
                    </div>
                  </div>
                </div>
                <div class="right_side">
                  <div class="tractor_holder">
                    <img v-if="workListFilters.group_by == 'culture'" src="@/assets/images/icons/cultura.svg" style="width: 20px; height: 20px;" />
                    <div v-else>
                      <img v-if="workItem.is_dataller_work == false" src="@/assets/images/icons/tractor.svg" />
                      <img v-if="workItem.is_dataller_work == true" src="@/assets/images/icons/oameni.svg" />
                    </div>

                    <span
                        v-if="workListFilters.group_by == 'culture'"
                        class="tractor_count"
                    >{{ getCulturesCount(workItem) }}</span
                    >
                    <span v-else
                      class="tractor_count"
                      >{{ workItem.is_dataller_work ? workItem.datallers_count : workItem.machines_count }}</span
                    >
                  </div>
                  <img
                    v-if="workItem.opened"
                    class="btn_expand"
                    :class="{ btn_expand_selected: workItem.selected }"
                    src="@/assets/images/icons/arrow_up.svg"
                    @click="expandWork(workItem, d_index, w_index)"
                  />
                  <img
                    v-else
                    class="btn_expand"
                    :class="{ btn_expand_selected: workItem.selected }"
                    src="@/assets/images/icons/arrow_down.svg"
                    @click="expandWork(workItem, d_index, w_index)"
                  />
                </div>
              </div>
              <div v-show="workItem.opened">
                <work-with-cultures v-if="workListFilters.group_by === 'culture'"
                  :parent_selected="workItem.selected"
                  :parent_expanded="workItem.opened"
                  @selectMachine="selectMachine"
                  @selectParcel="selectParcel"
                  @selectWorkByMachine="selectWorkByMachine"
                  :start_mode="start_mode"
                  :template-data="workItem"
                  :department_uuid="workItem.department_uuid"
                  :ref="'machines_' + workItem.uuid"
                  :last_work="workDate.works.length == w_index + 1"
                  :workListFilters="workListFilters"
                  @showMapForParcel="showMapForParcel"
                   :usage_mode="usage_mode"
                />
                <work-with-machines v-else
                    :parent_selected="workItem.selected"
                    :parent_expanded="workItem.opened"
                    @selectMachine="selectMachine"
                    @selectParcel="selectParcel"
                    @selectWorkByMachine="selectWorkByMachine"
                    :start_mode="start_mode"
                    :template-data="workItem"
                    :department_uuid="workItem.department_uuid"
                    :ref="'machines_' + workItem.uuid"
                    :last_work="workDate.works.length == w_index + 1"
                    :workListFilters="workListFilters"
                    @showMapForParcel="showMapForParcel"
                    :usage_mode="usage_mode"
                />
                <div class="details_btn_container" @click="showWorkDetails(workItem)">
                  <img
                    src="@/assets/images/icons/details_file.svg"
                  />
                  <span>Mai multe detalii</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="load text-center" v-if="works_loading">
        <loader :loading="works_loading" size="sm"/>
      </div>
    </div>
    <filters-dialog
      v-if="usage_mode == 'FULL_OPTION'"
      ref="filterDialog"
      @applyFilters="applyFilters"
      :showPopup="showFiltersDialog"
      @hidden="hideFilters"
    ></filters-dialog>
    <batch-product-list
      :showPopup="showBatchProductDialog"
      :templateData="parcelListTemplateData"
      @hidden="hideBatchProductDialog"
    ></batch-product-list>
    <add-article
      :form_mode="form_mode"
      :templateData="addArticleTemplateData"
      :batchTemplateData="productListTemplateData"
      :department_uuid="currentDepartment.department_uuid"
      :showPopup="showAddArticleDialog"
      @hidden="hideAddArticleDialogFunction"
      @hideAfterSave="hideAddArticleDialogAfterSave"
      @collectBatchHeaders="getBatchHeadersForAddProduct"
    ></add-article>
    <work-details
    :showPopup="workDetailsVisibility"
    @hidden="hideWorkDetails"
    :group_by="work_details_group_by"
    :work_uuid="work_details_work_uuid"
    :work_name="work_details_work_name"
    :work_date="work_details_work_date"
    :department_uuid="work_details_department_uuid"
    :parcel_group_parcel_uuids="parcel_group_parcel_uuids"
    :parcel_uuid="parcel_uuid"></work-details>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WorkWithMachines from "./workWithMachines";
import infiniteScroll from "vue-infinite-scroll";
import Vue from "vue";
Vue.use(infiniteScroll);
import FiltersDialog from "../dialogs/filters";
import moment from "moment";
import BatchProductList from "../dialogs/batchProductList.vue";
import WorkDetails from "../dialogs/workDetails.vue";
import AddArticle from "../dialogs/addArticle";
import Loader from "@/components/Loader.vue";
import WorkWithCultures from "@/pages/works/applyArticles/partials/workWithCultures.vue";

export default {
  name: "Works",
  data() {
    return {
      works_loading: false,
      form_mode: "",
      workDatesDistinct: [],
      showBatchProductDialog: false,
      showFiltersDialog: false,
      showAddArticleDialog: false,
      addArticleTemplateData: {
        selected_works: [],
        selected_parcels: [],
        batches: [],
        works_after_insert: []
      },
      parcelListTemplateData: {
        parcels: "",
        parcel_count: 0,
        products: "",
        unique_products: [],
        total_surface_worked: 0,
      },
      productListTemplateData: {
        spc_uuids: [],
        batches: [],
        headers: [],
      },
      localFilters: this.filters ? this.filters : {},
      workDetailsVisibility: false,
      insert_response_data: [],
      addProductAfterInsert: false,
      work_details_work_uuid: '',
      work_details_work_name: '',
      work_details_work_date: '',
      work_details_department_uuid: '',
      work_details_group_by: 'work',
    };
  },
  props: {
    showFilterText: Boolean,
    start_mode: Boolean,
    usage_mode: "",
    parcel_uuid: String,
    parcel_name: String,
    parcel_group_uuid: String,
    parcel_group_parcel_uuids: {
      type: Array,
      default: () => [],
    },
    season: Object|null, //due to work list on apply articles, else the map needs the season
    filters: Object|null,
  },
  computed: {
    ...mapGetters({
      company: "auth/domain",
      current_user: "auth/user",
      current_token: "auth/token",
      departments: "company/departments",
      spcWorks: "seasonOnParcelHasCulture/spcWorks",
    }),
    currentDepartment() {
      return this.$store.getters["auth/currentDepartment"] ?? {};
    }
  },
  components: {
    WorkWithCultures,
    Loader,
    WorkWithMachines,
    FiltersDialog,
    BatchProductList,
    AddArticle,
    WorkDetails
  },
  watch: {
    showAddArticleDialog: function (newVal) {
      this.$emit("setParentShowAddArticle", newVal);
      if(newVal == false){
        this.addArticleTemplateData =
        {
          selected_works: [],
          selected_parcels: [],
          batches: [],
          works_after_insert: []
        };
      }
    },
    filters: {
      handler: function(newVal) {
        this.localFilters = newVal;
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    ...mapActions({
      checkDomain: "auth/checkDomain",
      getDepartments: "company/getDepartments",
      getSpcWorks: "seasonOnParcelHasCulture/getSpcWorks",
      getSpcWorkSummary: "seasonOnParcelHasCulture/getSpcWorkSummary",
      nsGetSpcMachines: "seasonOnParcelHasCulture/nsGetSpcMachines",
      nsGetSpcParcels: "seasonOnParcelHasCulture/nsGetSpcParcels",
      getBatches: "batch/getBatches",
      getUsedProducts: "batch/getUsedProducts",
      changeDepartment: "auth/changeDepartment",
      getLayers: "layers/getLayers",
    }),
    showWorkDetails(workItem){
      this.work_details_work_uuid = workItem.work_uuid;
      this.work_details_work_name = workItem.name;
      this.work_details_work_date = workItem.date;
      this.work_details_group_by = this.workListFilters.group_by;
      //this.work_details_department_uuid = workItem.department_uuid;

      let localDepartmentFilter = [];
      if(this.workListFilters && this.workListFilters.departments){
        this.workListFilters.departments.forEach(d => {
          if(this.departments.find(dep => dep.uuid == d)){
            localDepartmentFilter.push(d);
          }
        });
      }
      this.work_details_department_uuid = localDepartmentFilter.join();

      this.workDetailsVisibility = true;
    },
    hideWorkDetails(){
      this.workDetailsVisibility = false;
    },
    onScroll() {
      if (
        this.spc_work_pagination &&
        this.spc_work_pagination.next_page_url != null
      ) {
        this.fillWorkList(this.spc_work_pagination.next_page_url);
      }
    },
    setParcelFilter(parcel_uuid) {
      this.workListFilters.parcels = [parcel_uuid];
    },

    fillWorkAndAddProductAfterInsert(response_data){
      this.insert_response_data = response_data;
      this.addProductAfterInsert = true;
    },

    fillWorkList(endpoint) {

      if(!this.works_loading){
        this.works_loading = true;
        let filters = this.workListFilters;
        this.works = [];

        let localDepartmentFilter = []
        if(filters && filters.departments){
          filters.departments.forEach(d => {
            if(this.departments.find(dep => dep.uuid == d)){
              localDepartmentFilter.push(d);
            }
          });
        }

        this.getSpcWorks({
          group_by: filters.group_by ?? 'work',
          department_uuid: localDepartmentFilter
            ? localDepartmentFilter.join()
            : this.currentDepartment.department_uuid,
          date_from: filters ? filters.date_from : null,
          date_to: filters ? filters.date_to : null,
          work_uuid: filters
            ? filters.works
              ? filters.works.join()
              : null
            : null,
          parcel_uuid: filters
            ? filters.parcels
              ? filters.parcels.join()
              : null
            : null,
          machine_uuid: filters
            ? filters.machines
              ? filters.machines.join()
              : null
            : null,
          product_uuid:
            filters && filters.products ? filters.products.join() : null,
          season_uuid:
            filters && filters.seasons ? filters.seasons.join() : (this.localFilters && this.localFilters.season ? this.localFilters.season.join() : this.season),
          endpoint: endpoint,
          src: this.localFilters?.src ? this.localFilters?.src : 'list',
        }).then((result) => {
          this.spc_work_pagination = result.pagination;
          this.works = [];

          result.data.forEach((obj, index) => {
            obj.selected = false;
            obj.opened = false;
            this.$set(this.works, index, obj);
          });
          if (endpoint == null) {
            this.workDatesDistinct = [];
          }

          let uniqueDates = [...new Set(this.works.map((item) => item.date))];

          uniqueDates.forEach((d) => {
            this.addWorksToDistinct(d, this.works);
          });
          this.works_loading = false;
          if(this.addProductAfterInsert){
            this.addProductAfterInsert = false;
            this.startProductAddAfterManualWork(this.insert_response_data);
          }
        });
      }
    },
    resetFilters() {
      if (this.usage_mode == "FULL_OPTION") {
        // this.$refs.filterDialog.setInitFilters({
        //   departments: [this.currentDepartment.department_uuid],
        //   date_from: "",
        //   date_to: "",
        //   works: [],
        //   machines: [],
        //   parcels: [],
        //   products: [],
        // });
      }
      this.applyFilters({
        //departments: [this.currentDepartment.department_uuid],
        departments: this.departments.map(d => d.uuid),
        date_from: "",
        date_to: "",
        works: [],
      });
    },
    applyFilters(filters) {

        this.$emit("setShowFilterText", this.filtersAreEmpty(filters) == false);

      let date_from = moment(filters.date_from).isValid()
        ? moment(filters.date_from).format("YYYY-MM-DD")
        : "";
      let date_to = moment(filters.date_to).isValid()
        ? moment(filters.date_to).format("YYYY-MM-DD")
        : "";

      this.workListFilters = {
        group_by: filters.group_by ?? 'work',
        departments: filters.departments,
        date_from: date_from,
        date_to: date_to,
        works: filters.works,
        machines: filters.machines,
        parcels: filters.parcels,
        products: filters.products,
        seasons: filters.seasons,
        src: filters.src,
      };

      this.hideFilters();
      this.fillWorkList(null);
    },
    hideFilters() {
      this.showFiltersDialog = false;
    },
    filtersAreEmpty(filters) {
      return (
        //filters.departments.length == 1 &&
        filters.departments.length == this.departments.length &&
        filters.departments[0] == this.currentDepartment.department_uuid &&
        filters.date_from == "" &&
        filters.date_to == "" &&
        filters.works.length == 0 &&
        (filters.machines === undefined || filters.machines.length == 0) &&
        (filters.parcels === undefined || filters.parcels.length == 0) &&
        (filters.products === undefined || filters.products.length == 0) &&
        (filters.seasons === undefined || filters.seasons.length == 0)
      );
    },
    work_select_by_icon(selected, d_index, w_index, obj) {
      if (this.start_mode == false && this.usage_mode != "LIGHT") {
        this.$emit("setStartMode", true);
        //this.start_mode = true;
        setTimeout(
          this.work_selected_event(selected, d_index, w_index, obj),
          500
        );
      }
    },
    addWorksToDistinct(date, works, after_add = false) {
      let checkObject = this.workDatesDistinct.find((w) => w.date == date);

      if (checkObject === undefined) {
        this.workDatesDistinct.push({
          date: date,
          works: works.filter(
            (w) => w.date == date //&& w.batch_fully_applied == false
          )
        });
      } else {
        if (after_add) {
          works
            .filter((w) => w.date == date)
            .forEach((w) => {
              let removeFromWorkIndex = -1;

              checkObject.works.forEach((checkWork, index) => {
                if (checkWork.work_uuid == w.work_uuid) {
                  removeFromWorkIndex = index;
                  w.opened = checkWork.opened;
                  w.selected = checkWork.selected;

                }
              });
              if (removeFromWorkIndex > -1) {
                this.$set(checkObject.works, removeFromWorkIndex, w);
              }

            });
        } else {
          works
            .filter((w) => w.date == date && w.batch_fully_applied == false)
            .forEach((w) => checkObject.works.push(w));

        }
      }
    },
    selectParcel(select, parcel) {
      if (select) {
        this.addArticleTemplateData.selected_parcels.push(parcel);
      } else {
        this.addArticleTemplateData.selected_parcels =
          this.addArticleTemplateData.selected_parcels.filter(
            (p) =>
              !(
                p.uuid == parcel.uuid &&
                p.machine_uuid == parcel.machine_uuid &&
                p.department_uuid == parcel.department_uuid &&
                p.work_uuid == parcel.work_uuid
              )
          );
      }
    },
    selectMachine(select, machine_uuid, department_uuid, templateData) {
      if (select) {
        this.collectSelectedParcelsByMachine(
          { uuid: machine_uuid },
          department_uuid,
          templateData.work_uuid,
          templateData.date,
          true
        );
        this.work_select_by_child(select, templateData);
      } else {
        this.removeSelectedParcelsByMachine(
          templateData.work_uuid,
          templateData.date,
          machine_uuid
        );
      }
      this.selectWorkByMachine(select, templateData);
    },
    selectWorkByMachine(select, templateData) {
      this.workDatesDistinct.forEach((workByDate) => {
        if (workByDate.date == templateData.date) {
          workByDate.works.forEach((w, index) => {
            if (w.work_uuid == templateData.work_uuid) {
              if (select) {
                w.selected = true;
                this.$set(workByDate.works, index, w);
                if (
                  this.addArticleTemplateData.selected_works.find(
                    (w) =>
                      w.date == templateData.date &&
                      w.work_uuid == templateData.work_uuid
                  ) == undefined
                ) {
                  this.addArticleTemplateData.selected_works.push(
                    templateData
                  );
                }
              } else {
                if (
                  this.checkIfHasSelectedParcels(
                    templateData.date,
                    templateData.work_uuid
                  ) == false
                ) {
                  w.selected = false;
                  this.$set(workByDate.works, index, w);
                  this.addArticleTemplateData.selected_works =
                    this.addArticleTemplateData.selected_works.filter(
                      (w) =>
                        w.date != templateData.date &&
                        w.work_uuid != templateData.work_uuid
                    );
                }
              }
            }
          });
        }
      });
    },
    showMapForParcel(parcel_uuid, season_uuid) {
      this.$emit("showCellLayerMaps", parcel_uuid, season_uuid);
    },
    expandWork(work_item, d_index, w_index) {
      work_item.opened = !work_item.opened;
      this.$set(this.workDatesDistinct[d_index].works, w_index, work_item);
    },
    workNameClick(work_item) {
      if (work_item.products.length > 0 && this.start_mode == false) {
        this.parcelListTemplateData.work_uuid = work_item.work_uuid;
        this.parcelListTemplateData.work_name = work_item.name;
        this.parcelListTemplateData.work_date = work_item.date;
        this.parcelListTemplateData.total_surface_worked =
          work_item.net_surface_worked;
        this.parcelListTemplateData.parcel_count = work_item.parcels_count;
        this.showBatchProductDialog = true;
      }
    },
    work_selected_event(selected, d_index, w_index, obj) {
      let checkObj = this.addArticleTemplateData.selected_works.find(
        (x) => x.uuid == obj.uuid
      );
      obj.selected = selected;
      if (selected == true && checkObj == undefined) {

        this.$set(this.workDatesDistinct[d_index].works, w_index, obj);

        this.addArticleTemplateData.selected_works.push(obj);
        if (
          this.$refs["machines_" + obj.uuid] &&
          this.$refs["machines_" + obj.uuid][0]
        ) {
          this.$refs["machines_" + obj.uuid][0].selectAllMachines();
        }
        this.collectSelectedParcelsByWork(obj);
      }
      if (selected == false && checkObj != undefined) {

        this.$set(this.workDatesDistinct[d_index].works, w_index, obj);

        this.addArticleTemplateData.selected_works =
          this.addArticleTemplateData.selected_works.filter(
            (x) => x.uuid != obj.uuid
          );
        if (
          this.$refs["machines_" + obj.uuid] &&
          this.$refs["machines_" + obj.uuid][0]
        ) {
          this.$refs["machines_" + obj.uuid][0].deselectAllMachines();
        }
        this.removeSelectedParcelsByWork(obj);
      }
    },
    collectSelectedParcelsByWork(obj, set_for_add = true) {
      let filtersForMachines = this.workListFilters;
      let localDepartmentFilterForMachines = []
        if(filtersForMachines && filtersForMachines.departments){
          filtersForMachines.departments.forEach(d => {
            if(this.departments.find(dep => dep.uuid == d)){
              localDepartmentFilterForMachines.push(d);
            }
          });
        }

      this.nsGetSpcMachines({
        department_uuid: localDepartmentFilterForMachines
            ? localDepartmentFilterForMachines.join()
            : this.currentDepartment.department_uuid,
        date_from: obj.date,
        date_to: obj.date,
        work_uuid: obj.work_uuid,
      }).then((machine_response) => {
        if (machine_response && machine_response.length > 0) {
          machine_response.forEach((machine) => {
            this.collectSelectedParcelsByMachine(
              machine,
              this.currentDepartment.department_uuid,
              obj.work_uuid,
              obj.date,
              set_for_add
            );
          });
        }
      });
    },
    collectSelectedParcelsByMachine(
      machine,
      department_uuid,
      work_uuid,
      date,
      set_for_add
    ) {

      let filtersForParcels = this.workListFilters;

      let localDepartmentFilterForParcels = []
        if(filtersForParcels && filtersForParcels.departments){
          filtersForParcels.departments.forEach(d => {
            if(this.departments.find(dep => dep.uuid == d)){
              localDepartmentFilterForParcels.push(d);
            }
          });
        }

        const payload = {
          department_uuid: localDepartmentFilterForParcels
              ? localDepartmentFilterForParcels.join()
              : this.currentDepartment.department_uuid,
          work_uuid: work_uuid,
          date_from: date,
          date_to: date,
          group_by: this.workListFilters.group_by,
        };

        if (this.workListFilters.group_by === 'culture') {
          payload.culture_uuid = machine.uuid;
        } else {
          payload.work_machine_uuid = machine.uuid;
        }

      this.nsGetSpcParcels(payload).then((parcel_response) => {
        if (parcel_response && parcel_response.length > 0) {
          parcel_response.forEach((parcel) => {
            if (
              this.addArticleTemplateData.selected_parcels.find(
                (p) => p.uuid == parcel.uuid && p.spc_items[0] == parcel.spc_items[0]
              ) === undefined
            ) {
              let iParcel = parcel;
              iParcel.department_uuid = department_uuid;
              iParcel.work_uuid = work_uuid;
              iParcel.machine_uuid = machine.uuid;
              iParcel.date = date;
              if (set_for_add) {
                this.$set(
                  this.addArticleTemplateData.selected_parcels,
                  this.addArticleTemplateData.selected_parcels.length,
                  iParcel
                );
              } else {
                if (iParcel.spc_items.length > 0) {
                  this.productListTemplateData.spc_uuids = [];
                  iParcel.spc_items.forEach((spc_uuid) =>
                    this.productListTemplateData.spc_uuids.push(spc_uuid)
                  );
                  this.getBatchListBySpcs(
                    this.productListTemplateData.spc_uuids.join(),
                    date
                  );
                }
              }
              //this.addArticleTemplateData.selected_parcels.push(iParcel);
            }
          });
        }
      });
    },
    removeSelectedParcelsByWork(work) {
      this.addArticleTemplateData.selected_parcels =
        this.addArticleTemplateData.selected_parcels.filter(
          (w) => !(w.work_uuid == work.work_uuid && w.date == work.date)
        );
    },
    removeSelectedParcelsByMachine(work_uuid, date, machine_uuid) {
      this.addArticleTemplateData.selected_parcels =
        this.addArticleTemplateData.selected_parcels.filter(
          (w) =>
            !(
              w.work_uuid == work_uuid &&
              w.date == date &&
              w.machine_uuid == machine_uuid
            )
        );
    },
    checkIfHasSelectedParcels(date, work_uuid) {
      return (
        this.addArticleTemplateData.selected_parcels.filter(
          (p) => p.date == date && p.work_uuid == work_uuid
        ).length > 0
      );
    },
    work_select_by_child(select, work_item) {
      let checkWorkItem = this.addArticleTemplateData.selected_works.find(
        (w) =>
          w.department_uuid == work_item.department_uuid &&
          w.work_uuid == work_item.work_uuid &&
          w.data == work_item.data
      );
      if (select) {
        if (checkWorkItem === undefined) {
          this.$set(
            this.addArticleTemplateData.selected_works,
            this.addArticleTemplateData.selected_works.length,
            work_item
          );
        }
      }
    },
    selectAll() {
      this.workDatesDistinct.forEach((d) => {
        d.works.forEach((w, index) => {
          if (w.selected == false) {
            w.selected = true;
            this.$set(d.works, index, w);
          }
        });
      });

      this.selectAllMachines(true);
    },
    deselectAll() {
      this.workDatesDistinct.forEach((d) => {
        d.works.forEach((w, index) => {
          if (w.selected) {
            w.selected = false;
            this.$set(d.works, index, w);
          }
        });
      });

      this.addArticleTemplateData.selected_works = [];
      this.addArticleTemplateData.selected_parcels = [];
      this.selectAllMachines(false);
    },
    selectAllMachines(select) {
      var filteredRefs = Object.keys(this.$refs)
        .filter((ref) => {
          if (ref.includes("machines_")) {
            return true;
          }
          return false;
        })
        .map((val) => {
          return this.$refs[val];
        });
      if (filteredRefs && filteredRefs.length > 0) {
        filteredRefs.forEach((ref_component) => {
          if (ref_component[0]) {
            if (select) {
              ref_component[0].selectAllMachines();
            } else {
              ref_component[0].deselectAllMachines();
            }
          }
        });
      }
    },
    hideBatchProductDialog() {
      this.showBatchProductDialog = false;
    },
    hideAddArticleDialogFunction() {
      this.showAddArticleDialog = false;
      this.$emit("setStartMode", false);
      //this.start_mode = false;
      this.deselectAll();
    },
    hideAddArticleDialogAfterSave(selected_works) {
      this.$emit(
        "showToastMessage",
        "success",
        "Articolul a fost adăguat cu secces!"
      );
      this.deselectAll();
      this.showAddArticleDialog = false;
      this.$emit("setStartMode", false);
      //this.start_mode = false;
      this.fillWorkListAfterAddArticle(selected_works);
    },
    fillWorkListAfterAddArticle(selected_works) {
      let processedWorkUUIDS = [];

      let filters = this.workListFilters;

      let localDepartmentFilter = []
      if(filters && filters.departments){
        filters.departments.forEach(d => {
          if(this.departments.find(dep => dep.uuid == d)){
            localDepartmentFilter.push(d);
          }
        });
      }

      selected_works.forEach((w) => {
        this.getSpcWorks({
          department_uuid: localDepartmentFilter
            ? localDepartmentFilter.join()
            : this.currentDepartment.department_uuid,
          date_from: w.work_date,
          date_to: w.work_date,
          work_uuid: w.work_uuid,
        }).then((response) => {
            response.data.filter((obj) => obj.date == w.work_date && obj.work_uuid == w.work_uuid).forEach((wt) => {
              if(processedWorkUUIDS.find(obj => obj == wt.work_uuid) === undefined){
                this.addWorksToDistinct(wt.date, [wt], true);
                processedWorkUUIDS.push(wt.work_uuid);
              }
          });
        });
      });
    },
    getBatchHeadersForAddProduct() {
      let spc_uuids = [];
      this.productListTemplateData.headers = [];
      this.addArticleTemplateData.selected_parcels.forEach((p) => {
        if (p.spc_items.length > 0) {
          p.spc_items.forEach((spc) => {
            if (spc_uuids.includes(spc) == false) {
              spc_uuids.push(spc);
            }
          });
        }
      });
      if (spc_uuids.length > 0) {
        this.getBatchListBySpcs(spc_uuids.join());
      }
    },
    showAddArticleDialogFunction() {
      if (this.addArticleTemplateData.selected_works.length > 0 || this.addArticleTemplateData.works_after_insert.length > 0) {
        this.form_mode = "new";
        this.showAddArticleDialog = true;
        this.getBatchHeadersForAddProduct();
      } else {
        this.$emit(
          "showToastMessage",
          "error",
          "Trebuie sâ selectați măcar o lucrare!"
        );
      }
    },
    getBatchListBySpcs(spc_items, date) {

      let filters = this.workListFilters;
        this.works = [];

        let localDepartmentFilter = []
        if(filters && filters.departments){
          filters.departments.forEach(d => {
            if(this.departments.find(dep => dep.uuid == d)){
              localDepartmentFilter.push(d);
            }
          });
        }

      this.getBatches({
        limit: 999999,
        //department_uuid: this.currentDepartment.department_uuid,
        department_uuid: localDepartmentFilter
            ? localDepartmentFilter.join()
            : this.currentDepartment.department_uuid,
        company_uuid: this.company.uuid,
        spc_items: spc_items,
        date_from: date,
        date_to: date,
      }).then((response) => {
        response.data.forEach((batch) => {
          batch.headers.forEach((header) => {
            if (
              this.productListTemplateData.headers.find(
                (h) => h.uuid == header.uuid
              ) == undefined
            ) {
              this.productListTemplateData.headers.push(header);
            }
          });
        });
      });
      //getUsedProducts
      this.getUsedProducts({
        limit: 999999,
        department_uuid: this.currentDepartment.department_uuid,
        company_uuid: this.company.uuid,
        spc_items: spc_items,
        date_from: date,
        date_to: date,
      }).then((response) => {});
    },
    startProductAddAfterManualWork(insert_response_data){
      let work_date = insert_response_data[0].work_date;
      let work_uuid = insert_response_data[0].work.uuid;
      this.addArticleTemplateData.works_after_insert = insert_response_data;
      this.showAddArticleDialogFunction();

      // this.workDatesDistinct.forEach((wd_obj, wd_index) => {
      //   if(wd_obj.date == work_date){
      //     wd_obj.works.forEach((w_obj, w_index) => {
      //       if(w_obj.work_uuid == work_uuid){
      //         this.work_selected_event(true, wd_index, w_index, w_obj);
      //         //this.showAddArticleDialogFunction();
      //         return;
      //       }
      //     });
      //     return;
      //   }
      // });
    },
    getCulturesCount(workItem) {
      return workItem.cultures_count;
    }
  },
  mounted() {
    if (this.usage_mode == "LIGHT") {
      this.applyFilters({
        //departments: [this.currentDepartment.department_uuid],
        departments: this.departments.map(d => d.uuid),
        date_from: "",
        date_to: "",
        works: [],
        machines: [],
        parcels: (this.parcel_group_parcel_uuids && this.parcel_group_parcel_uuids.length > 0) ? this.parcel_group_parcel_uuids : [this.parcel_uuid],
        products: [],
      });
    }
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/digitalAgenda/daGrid.scss";
</style>

<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="work-log-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")'>
        <s-c-work-log-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="unassigned-alert alert alert-warning mt-2"
         v-if="unassignedRows.length > 0"
    >
      <img src="~@/assets/images/desktop/icons/controls/alert.svg" class="icon-svg text-dark pr-2"/>
      <span v-html="$t('salary_calculator.work_log.you_have_unassigned_works_link', {
      count: unassignedRows.length,
      link: '/salary-calculator/sc-work-type'
    })"></span>
    </div>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SCWorkLogFilters from "@/pages/salary-calculator/sc-work-log/partials/SCWorkLogFilters";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SCWorkLogList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scWorkLogFilters ? this.scWorkLogFilters : {},
      items: this.scWorkLogs ? this.scWorkLogs : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    SCWorkLogFilters,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scWorkLogFilters: 'scWorkLog/scWorkLogFilters',
      scWorkLogs: 'scWorkLog/scWorkLogs',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    title() {
      return this.$t('salary_calculator.work_log.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK_GROUP,
        permission_type: permissionTypes.module,
        edit: {
          url: '/salary-calculator/sc-work-log/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          confirmDeleteMessage: this.$t('salary_calculator.work_log.confirm_delete')
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('salary_calculator.employee.name'), col: 'employee.name', width: '15%', sortable: true },
        { name: this.$t('salary_calculator.employee_role.name'), col: 'employee.employee_role.name', width: '10%' },
        { name: this.$t('salary_calculator.work.name'), subtitle: this.workNameSubtitle, col: 'work_name', width: '15%' },
        { name: this.$t('salary_calculator.work_group.title'), col: 'work_pivot.work_group.name_label', width: '12%' },
        { name: this.$t('salary_calculator.work_log.work_hours'), col: 'work_hours', suffix: 'h', width: '9%', align: 'center' },
        { name: this.$t('salary_calculator.work_log.shift'), col: 'shift.name', width: '8%' },
        { name: this.$t('salary_calculator.work_log.work_date'), col: 'work_date', width: '11%', sortable: true },
        { name: this.$t('common.created_at'), col: 'created_at', width: '14%', sortable: true },
      ];
    },
    unassignedRows() {
      if(this.items) {
        return this.items.filter((item => {
            return (item && item.work_pivot && !item.work_pivot.work_group)
          }
        ))
      }
      return [];
    }
  },
  methods: {
    ...mapActions({
      deleteObject: 'scWorkLog/deleteSCWorkLog',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scWorkLog/"+type.SET_SC_WORK_LOG_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: '/salary-calculator/sc-work-log/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      console.log('onItemDeleted', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onSortBy(data) {
      this.scWorkLogFilters.sortBy = data.sortBy;
      this.scWorkLogFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    workNameSubtitle(item) {
      if(item.has_unassigned_works) {
        return {
          title: this.$t('salary_calculator.work_type.assign_to_group'),
          link: '/salary-calculator/sc-work/add?work_uuid='+(item.work ? item.work.uuid : '')
            +'&work_name='+(item.work ? item.work.name : '')+'&redirect=/salary-calculator/sc-work-log',
        };
      } else {
        return '';
      }
    }
  },
  watch: {
    scWorkLogs: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

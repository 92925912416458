<template>
     <DxTabPanel
        :element-attr="tabPanelAttributes"
        :data-source="salesSteps"
        :loop="false"
        :selected-item="currentItem"
        :selected-index="currentStep"
        :animation-enabled="true"
        :swipe-enabled="false"
        :show-nav-buttons="false"
        @selection-changed="tabSelectionChanged($event)"
    >
        <template #item="{ data: step }">
            <div>
                <DxScrollView ref="salesInputScroll" v-show="step == 1" class="sales-input-scroll" :use-native="true">
                    <div class="sales_input_holder"
                            :class="[{input_holder_error: $v.salesData.sales_date.$error}]">
                        <div @click="showCalendarSelector">
                            <div class="input_label">Data</div>
                            <div class="input_value">
                                <span :style="{ color: salesData.batch_uuid ? 'gray' : 'inherit' }">{{ salesData.sales_date }}</span>
                            </div>
                        </div>
                        <div v-if="is_date_selector_visible">
                            <FunctionalCalendar
                                :date-format="getDefaultDateFormat.toLowerCase()"
                                :is-date-range="false"
                                v-model="calendarData"
                                :change-month-function="true"
                                :change-year-function="true"
                                :is-date-picker="true"
                                @choseDay="calendarDayChosen"
                                @input="onDateSelected"
                            ></FunctionalCalendar>
                        </div>
                    </div>
                    <div class="sales_input_holder"
                        :class="[{input_holder_error: $v.salesData.product.uuid.$error}]">
                        <div class="input_label">Produs</div>
                        <div class="input_value" >
                            <DxSelectBox
                                v-model="salesData.product.uuid"
                                :search-enabled="true"
                                :remote-operations="true"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți produs"
                                :data-source="productsDataSource"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                @selection-changed="productChanged"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!salesData.product.uuid || salesData.product.uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="salesData.product.uuid && salesData.product.uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.code}} - {{data.name}}</div>
                                </template>
                            </DxSelectBox>
                        </div>
                        <div v-show="availableQuantity > 0" class="sales_input_second_value">{{ availableQuantity | getFormattedQuantity }}{{ ' ' + selected_mu_symbol}}</div>
                    </div>
                    <div class="sales_input_holder"
                        :class="[{input_holder_error: $v.salesData.warehouse.uuid.$error}]">
                        <div class="input_label">Depozit </div>
                        <div class="input_value">
                            <DxSelectBox
                                v-model="salesData.warehouse.uuid"
                                :search-enabled="false"
                                search-mode="contains"
                                search-expr="name"
                                class="input_value dx_input_value"
                                placeholder="Alegeți depozit"
                                :data-source="warehouses"
                                display-expr="name"
                                value-expr="uuid"
                                :show-clear-button="false"
                                drop-down-button-template="imageIcon"
                                @selection-changed="warehouseChanged"
                                :disabled="!!salesData.batch_uuid"
                                >
                                    <template #imageIcon>
                                    <div>
                                        <div class="dx_combo_open_button" v-show="!salesData.warehouse.uuid || salesData.warehouse.uuid == ''">
                                            <img src="@/assets/images/icons/plus_green.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                        <div class="dx_combo_open_button" v-show="salesData.warehouse.uuid && salesData.warehouse.uuid.length > 0">
                                            <img src="@/assets/images/icons/edit.svg"
                                            class="custom-selector-icon"
                                        ></div>
                                    </div>
                                </template>
                                <template #item="{data}">
                                    <div class="dx_custom_combo_item">{{data.name}} - {{ data.quantity | getFormattedQuantity }}{{ ' ' + data.mu }}</div>
                                </template>
                                </DxSelectBox>
                        </div>
                    </div>
                    <div class="sales_input_holder"
                        :class="[{input_holder_error: $v.salesData.unitPrice.$error}]">
                        <div class="input_label">Preț unitar</div>
                        <div class="input_value">
                            <DxNumberBox
                                :min="0"
                                placeholder="Introduceți prețul unitar"
                                format="#,##0.00 RON"
                                v-model="salesData.unitPrice"
                                class="global_dx_number_input"
                                :show-clear-button="false"
                            /></div>
                    </div>
                    <div class="sales_input_holder"
                        :class="[{input_holder_error: $v.salesData.quantity.$error}]">
                        <div class="input_label">Cantitate</div>
                        <div class="input_value">
                            <DxNumberBox
                                placeholder="Introduceți cantitatea"
                                :min="0"
                                :format="quantityFormatter + ' ' + selected_mu_symbol"
                                v-model="salesData.quantity"
                                class="global_dx_number_input"
                                :show-clear-button="false"
                            /></div>
                    </div>

                    <div class="sales_input_holder"
                        :class="[{input_holder_error: $v.salesData.partner.uuid.$error}]">
                        <div class="input_label">Furnizor</div>
                        <div><dx-combo-with-add
                            ref="partnerComboWithAdd"
                            @addNewItemInstant="addNewPartnerInstant"
                            @selectionChanged="partnerSelected"
                            addNewText="Furnizorul nu există"
                            :comboDataset="partners"
                            placeholder="Alegeți furnizor"
                            :isComboDisabled="!!salesData.batch_uuid">
                        </dx-combo-with-add></div>
                    </div>
                    <div class="sales_input_holder"
                        v-show="showCUIForPartner"
                        :class="[{input_holder_error: $v.salesData.partner.cui.$error}]">
                        <div class="input_label">Cod unic de înregistrare</div>
                        <div class="input_value" >
                            <DxTextBox placeholder="Introduceți CUI" v-model="salesData.partner.cui" class="global_dx_number_input"/>
                        </div>
                    </div>


                     <div class="sales_input_holder">
                        <div class="input_label">Observații</div>
                        <div class="input_value">
                             <DxTextArea v-model="salesData.notes" class="global_dx_number_input" />
                        </div>
                     </div>
                    <div class="input-bottom-spacer"></div>
                </DxScrollView>
                <div v-if="false" v-show="step == 2" class="scanner_area">
                    <div>Funcția este în curs de dezvoltare!</div><br>
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader><br>
                    <div>Codul scanat: </div><b></b>
                    <div>{{scannedCode}}</div>
                </div>
                 <div class="sales_bottom_sticky_two_line" v-show="step == 1 && showSaveButtons">
                    <div class="sticky_action_button" @click="saveSales(true)">Salvează și vânzare nouă</div>
                    <div class="sticky_action_button_second" @click="saveSales(false)">Salvează</div>
                </div>
                <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>

     </DxTabPanel>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import DxTabPanel from "devextreme-vue/tab-panel";
import { DetailCodes, InputDetailCodes } from "./reception.js"
import { required, helpers } from "vuelidate/src/validators";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextBox from 'devextreme-vue/text-box';
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import { v4 } from 'uuid';
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxSelectBox from "devextreme-vue/select-box";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import DxTextArea from 'devextreme-vue/text-area';
import { StreamBarcodeReader } from "vue-barcode-reader";
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { getQuantityFormatter } from '~/helpers/stockHelper';

const greaterThanZero = (value) => {
   return helpers.req(value) && value > 0;
};

const greaterOrEqualsThanZero = (value) => {
   return helpers.req(value) && value >= 0;
};

const requiredStrByParam = (param) => (value) => {
  if(param){
    return helpers.req(value) && value.length > 0;
  }
  return true;
};

const requiredNumByParam = (param) => (value) => {
  if(param){
    return helpers.req(value) && value > 0;
  }
  return true;
};

export default {
    name: "Sales",
    data(){
        return {
            preselectDate: true,
            calendarData: {},
            salesSteps: [1,2],
            currentStep: 0,
            currentItem: 1,
            tabPanelAttributes: {
                class: 'sales_tabs'
            },
            detailStepCode: '',
            detailInputStepCode: '',
            showMeasureUnitForProduct: false,
            showCUIForPartner: false,
            newPartner: {
                uuid: '',
                name: '',
                cui: '',
                partner_type: ''
            },
            salesData: {
                product: {
                    uuid: '',
                    name: '',
                    mu_id: ''
                },
                partner: {
                    uuid: '',
                    partner_type: '',
                    name: '',
                    cui: '',
                },
                quantity: null,
                unitPrice: null,
                warehouse: {
                    uuid: '',
                    name: ''
                },
                notes: '',
                sales_date: null,
                batch_uuid: null
            },
            products: [],
            partners: [],
            warehouses: [],
            selected_mu_symbol: '',
            availableQuantity: 0.00,
            scannedCode: null,
            is_date_selector_visible: false
        }
    },
    computed: {
        ...mapGetters({
            getDefaultDateFormat: 'settings/getDefaultDateFormat',
        }),
        quantityFormatter(){
            return getQuantityFormatter();
        },
        showSaveButtons(){
            return (this.salesData.product.uuid && this.salesData.product.uuid.length > 0) ||
            (this.salesData.partner.uuid && this.salesData.partner.uuid.length > 0) ||
            (this.salesData.quantity && this.salesData.quantity > 0) ||
            (this.salesData.unitPrice && this.salesData.unitPrice > 0) ||
            (this.salesData.warehouse.uuid && this.salesData.warehouse.uuid.length > 0) ||
            (this.salesData.notes && this.salesData.notes.length > 0);
        },
        detailCodes(){
            return DetailCodes;
        },
        inputDetailCodes(){
            return InputDetailCodes;
        },
        productsDataSource() {
          return new DataSource({
            store: new CustomStore({
              key: 'uuid',
              load: this.loadProducts,
              byKey: async function (key) {
                return new Promise(resolve => resolve("success"))
              }
            })
          });
        }
    },
    validations() {
        return {
            salesData:{
                sales_date: { required },
                product:{
                    uuid: { required },
                    mu_id: {requiredStrByParam: requiredStrByParam(this.showMeasureUnitForProduct)}
                },
                partner: {
                    uuid: { required },
                    cui: {requiredStrByParam: requiredStrByParam(this.showCUIForPartner)}
                },
                quantity: {requiredNumByParam:requiredNumByParam(this.availableQuantity)},
                unitPrice: {greaterOrEqualsThanZero},
                warehouse: {
                    uuid: { required }
                },
                sales_date: { required }
            }
        };
    },
    components: {
        DxTabPanel,
        DxNumberBox,
        DxTextBox,
        DxComboWithAdd,
        DxScrollView,
        DxSelectBox,
        ToastMessage,
        DxTextArea,
        StreamBarcodeReader,
        FunctionalCalendar
    },
    methods: {
        ...mapActions({
            getPartners: 'smPartners/getSmPartners',
            getProducts: 'smProducts/getSmProducts',
            getProductTypes: 'smProductTypes/searchSmProductTypes',
            getPartnerTypes: 'smPartners/getSmPartnerTypes',
            getWarehouses: 'smWarehouses/getSmWarehouses',
            createSmProduct: 'smProducts/createSmProduct',
            createSales: 'smTransactions/createSalesTransaction',
            getGroupByCode: 'settings/getGroupByCode',
        }),

        onLoaded(){},
        onDecode(result){
            this.scannedCode = result;
            console.log('barcode result ', result);
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.salesSteps.indexOf(this.currentItem);
          this.changeAddButtonVisibility();
        },
        goBack(){
            if(this.currentStep > 0){
                this.currentStep = this.currentStep - 1;
                this.setTitle();
            }
            else{
                this.$emit('goBackParent');
            }
        },
        setTitle(){
            if(this.currentStep == 0) {
                this.$emit('setTitle', 'Vânzare produs', true);
            }
        },
        changeAddButtonVisibility(){
            //this.$emit('changeAddButtonVisibility', this.currentStep == 1 && this.detailStepCode != this.detailCodes.Warehouse);
            this.$emit('changeAddButtonVisibility', false);
        },
        saveSales(saveAndNew){
            let saveWithNewPartner = false;
            this.$v.salesData.$touch();
            if (!this.$v.salesData.$invalid) {
                let salesForSave = {
                    department_uuid: this.getWarehouseDepartmentByWarehouseUuid(this.salesData.warehouse.uuid),
                    warehouse_uuid: this.salesData.warehouse.uuid,
                    notes: this.salesData.notes,
                    product: {},
                    sales_date: this.salesData.sales_date,
                    batch_uuid: this.salesData.batch_uuid
                };
                let productForSave = {
                    uuid: '',
                    barcode: null,
                    name: this.salesData.product.name,
                    measure_unit_id:  this.salesData.product.mu_id,
                    quantity: this.salesData.quantity,
                    unit_price: this.salesData.unitPrice,
                    partner: {},
                    notes: this.salesData.notes
                };
                if(!this.salesData.product.uuid.startsWith('NEW_')){
                    productForSave.uuid = this.salesData.product.uuid;
                }

                let partnerForSave = {
                    uuid: '',
                    partner_type: 'SRL',
                    name: this.salesData.partner.name,
                    id_number: this.salesData.partner.cui,
                };
                if(!this.salesData.partner.uuid.startsWith('NEW_')){
                    partnerForSave.uuid = this.salesData.partner.uuid;
                    partnerForSave.partner_type = this.salesData.partner.partner_type;
                }
                else{
                    saveWithNewPartner = true;
                }
                productForSave.partner = partnerForSave;
                salesForSave.product = productForSave;
                this.createSales(salesForSave).then(response => {
                    this.$refs.toastMessage.showSuccess('Vănzarea a fost salvată cu succes!');
                    if(saveAndNew){
                        if(saveWithNewPartner){
                            this.reloadPartners();
                        }

                        this.resetForm();
                        this.salesData.batch_uuid = response.data.batch_uuid ?? null;
                    }
                    else{
                        this.salesData.batch_uuid = null;
                        this.closeTransactions('Vănzarea a fost salvată cu succes!');
                    }
                }).catch((e) => {
                    console.log('sales error ', e)
                    this.$refs.toastMessage.showError('A apărut o erroare la salvarea vânzării. Contactați administratorul de sistem!');
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrormMssages());
            }
        },
        createErrormMssages(){
            let errorMessages = [];

            if(this.$v.salesData.sales_date.$invalid){
                errorMessages.push("Data vanzarii este obligatorie!");
            }
            if(this.$v.salesData.product.uuid.$invalid){
                errorMessages.push("Produsul este obligatoriu!");
            }
            if(this.$v.salesData.warehouse.uuid.$invalid){
                errorMessages.push("Depozitul este obligatoriu!");
            }
            if(this.$v.salesData.unitPrice.$invalid){
                errorMessages.push("Prețul unitar trebuie să fie egal sau mai mare de 0!");
            }
            if(this.$v.salesData.quantity.$invalid){
                if(this.availableQuantity > 0){
                    errorMessages.push("Cantitatea trebuie să fie intre 0 și " + this.availableQuantity + "!");
                }
                else{
                    errorMessages.push("Cantitatea trebuie să fie mai mare de 0!");
                }
            }
            if(this.$v.salesData.partner.uuid.$invalid){
                errorMessages.push("Furnizorul este obligatoriu!");
            }
            if(this.$v.salesData.partner.cui.$invalid){
                errorMessages.push("Codul unic de înregisrare este obligatoriu!");
            }

            return errorMessages;
        },
        goForward(){
            if(this.currentStep < 1){
                this.currentStep = this.currentStep + 1;
                this.setTitle();
            }
        },
        addNewPartnerInstant(partnerName){
            let _newPartner = {
                uuid:"NEW_" + v4(),
                name: partnerName
            };
            this.partners.push(_newPartner);
            this.$refs.partnerComboWithAdd.updateWithNewItem(_newPartner);
        },
        productSelected(selectedProduct){
            this.$refs['salesInputScroll'].instance.release(false);
            if(selectedProduct && selectedProduct.length > 0 && selectedProduct[0]){
                this.showMeasureUnitForProduct = selectedProduct[0].uuid.startsWith('NEW_');
                this.salesData.product.uuid = selectedProduct[0].uuid;
                this.salesData.product.name = selectedProduct[0].name;
                if(selectedProduct[0].measure_unit){
                    this.selected_mu_symbol = selectedProduct[0].measure_unit.symbol;
                    this.salesData.product.mu_id = selectedProduct[0].measure_unit.id;
                }
            }
            this.$refs['salesInputScroll'].instance.update();
            this.$refs['salesInputScroll'].instance.release(true);
        },
        partnerSelected(selectedPartner){
            if(selectedPartner && selectedPartner.length > 0 && selectedPartner[0]){
                this.showCUIForPartner = selectedPartner[0].uuid.startsWith('NEW_');
                this.salesData.partner.uuid = selectedPartner[0].uuid;
                this.salesData.partner.name = selectedPartner[0].name;
                this.salesData.partner.cui = selectedPartner[0].id_number ?? '';
                this.salesData.partner.partner_type = selectedPartner[0].partner_type ?? 'SRL';
            }
        },
        resetForm(){
            this.$v.$reset();
            //this.$refs.producComboWithAdd.resetControl();
            //this.$refs.partnerComboWithAdd.resetControl();
            this.availableQuantity = 0.00;
            this.salesData.product.uuid = '';
            this.showCUIForPartner = false;
            this.salesData.product.uuid = '';
            this.salesData.product.name = '';
            this.salesData.product.mu_id = '';

            this.salesData.quantity = null;
            this.salesData.unitPrice = null;
            this.salesData.notes = '';
            //this.salesData.warehouse.uuid = '';
            //this.salesData.warehouse.name = '';
            //this.selectWarehouse();

            this.$refs['salesInputScroll'].instance.scrollTo(0)
        },
        selectWarehouse(){
            if(this.warehouses.length == 1){
                this.salesData.warehouse.uuid = this.warehouses[0].uuid;
                this.salesData.warehouse.name = this.warehouses[0].name;
            }
        },
        closeTransactions(message){
            this.$emit('closeTransactions', message);
        },
        productChanged(e){
            this.selected_mu_symbol = e.selectedItem ? e.selectedItem.measure_unit.symbol : '';
            this.salesData.product.name = e.selectedItem ? e.selectedItem.name : '';
            this.salesData.product.uuid = e.selectedItem ? e.selectedItem.uuid : '';
            if(e.selectedItem){
                this.selected_mu_symbol = e.selectedItem.measure_unit.symbol;
                this.warehouses = [];
                if(e.selectedItem.stock_list && e.selectedItem.stock_list.length > 0){
                    e.selectedItem.stock_list.forEach(s => {
                        if(s.quantity > 0){
                            this.warehouses.push({
                                uuid: s.warehouse_uuid,
                                name: s.warehouse_name,
                                quantity: s.quantity,
                                mu: s.measure_unit
                            });
                        }
                    });
                }
            }
        },
        warehouseChanged(e){
            if(e.selectedItem){
                this.availableQuantity = e.selectedItem.quantity;
            }
        },
        reloadPartners(){
            this.getPartners({enablePagination: false}).then(response => {
                this.partners = response.data;
            });
        },
        showCalendarSelector(){
            if(this.salesData.batch_uuid && !!this.salesData.sales_date){
                this.is_date_selector_visible = false;
                return;
            }
            this.is_date_selector_visible = !this.is_date_selector_visible;
        },
        calendarDayChosen(data){
            this.is_date_selector_visible = false;
        },
        onDateSelected(date) {
            this.salesData.sales_date = date.selectedDate;
        },
        getWarehouseDepartmentByWarehouseUuid(warehouseUuid){
            let warehouse = this.warehouses.find(w => w.uuid == warehouseUuid);
            if(warehouse){
                return warehouse.department_uuid;
            }
            return null;
        },
        async loadProducts(data){
          return await this.getProducts({
            has_stock: 1,
            name: data?.searchValue,
          });
        },
    },
    mounted(){
        this.getGroupByCode({
            code: 'STOCK_MANAGEMENT'
        }).then(response => {
            let _setting = response.items.find(obj => obj.code == 'USE_CURRENT_DATE_ON_CREATION');
            this.preselectDate = _setting?.value == '1';
            if(this.preselectDate){
                this.salesData.sales_date = moment().format(this.getDefaultDateFormat);
            }
        });
        this.setTitle();
        this.getWarehouses({}).then(response => {
            this.warehouses = response.data;
            this.selectWarehouse();
        });
        this.reloadPartners();
    }

}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/sales.scss";
</style>

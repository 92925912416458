<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('stock_management.partners.title')" id="bonus-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new")'>
        <s-m-partner-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import SMPartnerFilters from "@/pages/stock-management/sm-partners/partials/smPartnerFilters";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SMPartnerList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.partnerFilters ? this.partnerFilters : {},
      items: this.partners ? this.partners : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    SMPartnerFilters,
  },
  computed: {
    ...mapGetters({
        user: 'auth/user',
        partnerFilters: 'smPartners/smPartnerFilters',
        partners: 'smPartners/smPartners',
        pagination: 'common/pagination',
        users: 'user/users',
    }),
    config() {
        return {
            permission: permissions.SM_PARTNERS,
            permission_type: permissionTypes.module,
            edit: {
              url: '/stock-management/sm-partners/{uuid}',
              callback: this.onItemEdited,
            },
            delete: {
                method: this.deleteObject,
                callback: this.onItemDeleted,
            }
        };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
        return  [
            { name: this.$t('common.name'), col: 'name', width: '23%', sortable: true, },
            { name: this.$t('stock_management.partners.type'), col: 'partner_type_name', width: '14%', sortable: true },
            { name: this.$t('stock_management.partners.id_number'), col: 'id_number', width: '10%'},
            { name: this.$t('stock_management.partners.nr_reg'), col: 'nr_reg', width: '10%'},
            {
              name: this.$t('common.active'),
              col: 'is_active_text',
              key: 'is_active',
              width: '8%',
              align: 'center',
              sortable: true
            },
            { name: this.$t('common.created_at'), col: 'created_at', align:'right', width: '13%', sortable: true, colType: 'date'},
            { name: this.$t('common.last_update'), col: 'updated_at', align:'right', width: '12%', sortable: true, colType: 'dateTime'},
        ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'smPartners/deleteSmPartner',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("smPartners/"+type.SET_SM_PARTNER_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sm-partners/add'
      });
    },
    onItemEdited(item) {
        console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
        this.items.splice(
          this.items.indexOf(
            this.items.find(it => it.uuid === item.uuid)
          ), 1
        );
    },
    onFiltersApplied(filters) {
        this.loading = true;
    },
    onSortBy(data) {
      this.partnerFilters.sortBy = data.sortBy;
      this.partnerFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    }
  },
  watch: {
    partners: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    partnerFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="main_filter_row" @click="swipeFilter(swipeOption)">
    <div class="first_row">
      <div class="main_filter_title">{{title}}</div>
      <div class="main_filter_count">
        {{ filterOptions ? filterOptions.length : 0 }}
      </div>
      <img
        class="main_filter_right_arrow"
        src="@/assets/images/icons/right_arrow_dg.svg"
      />
    </div>
    <div
      v-if="selectedOptions && selectedOptions.length > 0"
      class="main_filter_selected"
    >
      {{ filterText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterHeader",
  data() {
    return {};
  },
  props:{
    filterOptions: Array|String,
    selectedOptions: Array|String,
    filterTextTemplate1: String,
    filterTextTemplate2: String,
    swipeOption: String,
    title: String,
    displaySelectedAttribute: String
  },
  computed:{
    filterText(){
      if (this.displaySelectedAttribute && this.filterOptions.length > 0 && this.selectedOptions.length > 0) {
        return this.filterOptions[0][this.displaySelectedAttribute] || '-';
      }

      if (this.selectedOptions && this.selectedOptions.length == 1) {
        return this.filterTextTemplate1;
      } else if (
        this.selectedOptions &&
        this.selectedOptions.length > 1
      ) {
        return this.selectedOptions.length + this.filterTextTemplate2;
      } else {
        return "";
      }
    }
  },
  methods:{
    swipeFilter(option){
        this.$emit("swipeFilter", option);
    }
  }
};
</script>
